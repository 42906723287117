import React, { useCallback, useEffect, useState } from 'react'
import './staff.css'
import Table from '../../components/layout/table/Table'

import { useGlobalContext } from '../../store/context/context'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'

const Staff = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError, renderFeedback } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const history = useHistory()
    const location = useLocation()
    
    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('users/staff/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])
    
    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users/staff', breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Добавить сотрудника',
            icon: 'plus',
            url: 'users/staff/new/',
            popup_title: 'Добавить сотрудника',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        try {
            await axios.get(`/users/staff/${request_id}/`)
            history.push({
                pathname: `/users/staff/${request_id}/permissions`,
            })
        } catch (error) {
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }

    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <div><LoaderComponent /></div>
    if (error) return <div>{error.message}</div>

    return (<>
        <div className="staff page">
        </div>

        <TableV 
            table={table} 
            buttons={buttons.reverse()} 
            handleRowClick={handleRowClick} 
            refreshData={getTable}
            href={{
                from: '/users/staff',
                to: 'permissions'
            }}
        />
        </>
    )
}

export default Staff


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }