import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { LoaderComponent } from '../../components/layout/loader/Loader';

import Table from '../../components/layout/table/Table';
import { TableV } from '../../components/layout/table/TableV';
import { useGlobalContext } from '../../store/context/context';
import './platform.css'
// import { useAxios } from '../../utils/js/useAxios';


export const Platform = () => {
    const { setTopbarData, setActiveTopbarLink, setBreadcrumbs, setActiveSidebarLink, setPopup, renderFeedback, setShowFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const { id } = useParams()
    const location = useLocation();
    const history = useHistory()
    

    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`${location.pathname}/`)
            setClient(response_client.data) 
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
        } finally {
            setLoadingClient(false)
        }
    }, [location.pathname, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/data/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])
    
    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }  
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])

    
    useEffect(() => {
        const parentURL = getUrl('root')
        // console.log(parentURL);  // clients
        setActiveSidebarLink(parentURL)
    }, [setActiveSidebarLink, getUrl, location.pathname])
    
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])    

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/clients', breadcrumb: 'Клиенты' },
            // { path: `/clients/${id}`, breadcrumb: `${client.name}` },  // in case we nadd page 'client'
            { path: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`, breadcrumb: `Аккаунты` },  // links[getURL()] WAS HERE PREVIOUSLY
            { path: `${location.pathname}`, breadcrumb: `${table.title || ''}` },  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, getUrl, table.title])
   
    const buttons = [
        {
            text: `Добавить доп. расход`,
            icon: 'plus',
            url: `${location.pathname}/data/new/`,
            popup_title: `Добавить доп. расход`,
            popup_callback() {
                getClient()
                getTable()
            }
        },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        const url = `${location.pathname}/data/${request_id}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать дополнительные расходы аккаунта',
                callback() {
                    getClient()
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        try {
            setLoadingClient(true)
            const response = await axios.get(`${location.pathname}/delete/`)
            renderFeedback('success', response.data.message)
            history.replace(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`)
        } catch (error) {
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        } finally {
            setLoadingClient(false)
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    const clientButton = {
        text: `Удалить`,
        icon: 'delete',
    }

    return (<>
        <div className='account page'>
            {loadingClient ? <LoaderComponent /> : errorClient ? <div>{errorClient.message}</div> : <ClientInfo inline client={client} editClient={editClient} button={clientButton}/>}
            {/* <ClientInfo client={client} editClient={editClient} button={clientButton}/> */}
        </div>

        {loadingTable 
            ? <LoaderComponent /> 
            : errorTable 
                ? <div>{errorTable.message}</div> 
                : <TableV 
                    table={table}
                    buttons={buttons.reverse()} 
                    handleRowClick={handleRowClick} 
                    refreshData={getTable}
                    // href={{
                    //     from: '',
                    //     to: ''
                    // }}    
                />}
        
        </>
    )
}