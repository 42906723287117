import React, { useEffect, useState } from 'react'
import './number.css'
import NumberFormat from 'react-number-format';
import { useGlobalContext } from '../../../store/context/context';

const displayValueProperly = (sign, value) => !!sign ? Number((value * 100).toFixed(2)) : value  // IF SIGN="%" THEN MULTIPLY VALUE BY 100 TO CORRECTLY DISPLAY VALUE && WHEN SENDING VALUE DELETE BY 100

const NumberField = ({value='', onChange, label, name, editable=true, required, rowClickable=false, sign, onFocus, parentComponent='DefaultComponent'}) => {
    const { showFeedback, popup } = useGlobalContext()

    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    const [numberValue, setNumberValue] = useState(displayValueProperly(sign, value))  // BEFORE THERE WASN'T THIS LINE#11 AND LINE#13 AND LINE#37 WAS LIKE THIS => value={value}

    useEffect(() => {
        if (!editable) setFocused(value === 0 || !!value?.toString().length ? 'focused filled' : '')
        
        // parentComponent === 'PopupFillBilling' && setNumberValue(value)  // IF CALLED INSIDE TZ IN EDIT MODE THIS CAUSES RERENDER BUT WE NEED IT TO CORRECTLY SHOW VALUE OF EDITABLE NUMBER FIELD WHEN CHANGED BY FORCE IN POPUP_FILL_BILLING THUS WE CHECK HERE
        !sign && setNumberValue(value)  // ___PREVIOUSLY___ THE ABOVE LINE WAS RELEVANT BUT WHEN DOING КУРС ВАЛЮТ BUTTON THIS ISSUE SOMEHOW CEASED
    }, [value, editable, parentComponent, sign])
    
    const handleOnChange = values => {
        // console.log(values.floatValue, values.floatValue / 100, !!sign);
        setNumberValue(values.floatValue)
        // console.log(values);
        const event = {
            target: {
                name, 
                value: values.floatValue !== undefined ? !!sign ? values.floatValue / 100 : values.floatValue : ''  // OTHERWISE IT IS EQUAL TO undefined AND !!String(undefined) = true AND IT PASSES THE IF IN POPUP FUNCTION PRIOR TO SENDING REQUEST
            }
        }
        onChange && editable && onChange(event);  // DUE SORT AND FILTER CAUSING INFINITE RERENDER
    }
    const handleFormFieldClick = event => rowClickable && event.stopPropagation()

    const handleBlur = (event) => setFocused(!!event.target.value.toString().length ? 'focused filled' : '')  // IF value = 0 THEN THE LABEL GOES DOWN AND COVERS THE NUMBER FIELD

    return (
        <div className={`number form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick}>
            <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label>
            <NumberFormat
                id={name}
                name={name}
                // value={value}
                value={!!editable ? numberValue : displayValueProperly(sign, value)}
                // value={editable ? numberValue : value === '' ? null : value}
                className="text__input"
                thousandSeparator={' '}  // CUSTOM THOUSAND SEPARATOR
                onValueChange={handleOnChange}
                onFocus={() => {setFocused('focused'); onFocus && onFocus()}} 
                // onBlur={() => setFocused(!!value ? 'focused filled' : '')}  // IF value = 0 THEN THE LABEL GOES DOWN AND COVERS THE NUMBER FIELD
                onBlur={handleBlur}
                suffix={sign ? ` ${sign}` : ''}
                disabled={!editable}
                max={10}
                style={{borderColor: required && !value && showFeedback && popup.visible ? 'red' : ''}}
                autoComplete="off"
                // allowNegative={false}  // TRUE BY DEFAULT
                // prefix={'$'}
                // hintText="Some placeholder"
                decimalSeparator=","  // DEFAULT VALUE
                // displayType={'text'}  // renders it as a normal text in a span, ONLY readable  
                // renderText={(value, props) => <div {...props}>{value}</div>}
                // thousandsGroupStyle="thousand"  // DEFAULT VALUE
            />
        </div>
    )
}

export default NumberField