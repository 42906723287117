import React, { useCallback, useEffect, useState } from 'react'
import './team.css'
import Table from '../../components/layout/table/Table'

import { GoPrimitiveDot } from 'react-icons/go'

import {ReactComponent as Filter} from '../../assets/sidebar-icons/filter.svg'
import {ReactComponent as Plus} from '../../assets/sidebar-icons/plus.svg'
import {ReactComponent as Pencil} from '../../assets/sidebar-icons/pencil.svg'

import { useGlobalContext } from '../../store/context/context'
import { useAxios } from '../../utils/js/useAxios'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import Popup from '../../components/layout/popup/Popup'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'

const Team = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setPopup, setShowFeedback, setServerError, renderFeedback } = useGlobalContext()
    
    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    // const history = useHistory()
    const location = useLocation()

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('users/team/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])
    
    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }  
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])
    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: 'users/team/', breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title])

    const buttons = [
        {
            text: 'Добавить команду продаж',
            icon: 'plus',
            url: 'users/team/new/',
            popup_title: 'Добавить команду продаж',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        // console.log('CLIENTS: rowclick');
        try {
            const url = `users/team/${request_id}/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать команду продаж',
                callback() {
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <div><LoaderComponent /></div>
    if (error) return <div>{error.message}</div>

    return (<>
        <div className="team page">
        </div>

        <TableV 
            table={table} 
            buttons={buttons.reverse()} 
            handleRowClick={handleRowClick} 
            refreshData={getTable}
        />
        </>
    )
}

export default Team


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }