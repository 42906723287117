import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { LoaderComponent } from '../../components/layout/loader/Loader';
import { TableV } from '../../components/layout/table/TableV';
import { useGlobalContext } from '../../store/context/context';
import './client.css'
import {useCancelToken} from "../../utils/js/useCancelToken";
// import { useAxios } from '../../utils/js/useAxios';



const Client = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        setShowFeedback,
        renderFeedback,
        setServerError,
        me
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id, client_id} = useParams()
    const location = useLocation();
    const history = useHistory()

    const [table, setTable] = useState({})
    const [report, setReport] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // currency: {
        //     label: 'валюта',
        //     value: response.data.currency || 'KZT',
        //     type: 'select_field',
        //     options: ['KZT', 'RUB', 'USD']
        // },
    })

    const [error, setError] = useState(null)


    const urlEndPart = useMemo(() => location.pathname.substring(location.pathname.lastIndexOf('/')), [location.pathname])  // /contracts OR /deductions
    const {urlMidPart, activeSidebarLink, breadcrumbs} = useMemo(() => {
        let urlMidPart = []
        let activeSidebarLink = ''
        let breadcrumbs = []

        if (location.pathname.split('/')[2] === 'clients') {
            urlMidPart = `clients/${client_id}`
            activeSidebarLink = 'users/clients'
            breadcrumbs = [
                {path: '/users/clients', breadcrumb: 'Клиенты'},
                // { path: `/clients/${id}`, breadcrumb: `${client.name}` },  // in case we nadd page 'client'
                {path: `${location.pathname}`, breadcrumb: table.title || ''},  // links[getURL()] WAS HERE PREVIOUSLY
            ]
        } else {
            urlMidPart = `${id}/clients/${client_id}`
            activeSidebarLink = 'users'
            breadcrumbs = [
                {path: '/users', breadcrumb: 'Пользователи'},
                {path: `/users/${id}/clients`, breadcrumb: 'Клиенты пользователя'},
                {path: `/users/${id}/report`, breadcrumb: 'Клиенты пользователя'},
                {path: location.pathname, breadcrumb: table.title || ''},
            ]
        }

        return {urlMidPart, activeSidebarLink, breadcrumbs}
    }, [location.pathname, id, client_id, table.title])  // /contracts OR /deductions
    const getText = useCallback(() => {
        switch (urlEndPart) {
            case '/placements':
                return ['площадку', 'площадка']
            case '/contracts':
                return ['договор', 'договор']
            case '/platforms':
                return ['платформу', 'платформа']
            case '/networks':
                return ['аккаунт', 'аккаунт']
            case '/contacts':
                return ['контакт', 'контакт']
            case '/reports':
                return ['доход', 'доход']
            case '/pay':
                return ['системы выплат', 'системы выплат']
            default:
                return ['', '']
        }
    }, [urlEndPart])


    const [loading, setLoading] = useState(true)
    const {newCancelToken, isCancel} = useCancelToken();

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/', {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            setReport(response.data)

            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}&currency=${response.data.currency || 'KZT'}`)

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsManager');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status >= 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, newCancelToken, isCancel])

    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)


            const response = await axios.get(location.pathname + `/?month=${dateFields.month.value}&year=${dateFields.year.value}&currency=${dateFields.currency.value}`)
            setTable(response.data)
            setReport(response.data)

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}&currency=${dateFields.currency.value}`)

        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }


    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/' + location.search, {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            setReport(response.data)
            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }
        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsManager');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, setServerError, newCancelToken, isCancel])

    useEffect(() => {
        // console.log(location, history.action)
        if (history.action === 'REPLACE') return
        if (!location.search) return getInitialDataAndReplace()
        getInitialData()
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`users/clients/${client_id}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [client_id, setServerError])

    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            // const response_table = await axios.get(`${location.pathname}/`)
            const response_table = await axios.get(`users/clients/${client_id}${urlEndPart}/`)
            setTable(response_table.data)
            setReport(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data|| 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [setServerError, client_id, urlEndPart])


    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])

    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        let links = []

        if (me?.is_staff) {
            links = [
                {value: 'Площадки', url: `/users/${urlMidPart}/placements`},
                {value: 'Deductions', url: `/users/${urlMidPart}/deductions`},
                // {value: 'Платформы', url: `/users/${urlMidPart}/platforms`},
                {value: 'Договоры', url: `/users/${urlMidPart}/contracts`},
                {value: 'Контакты', url: `/users/${urlMidPart}/contacts`},
                {value: 'Системы выплат', url: `/users/${urlMidPart}/pay`},
                {value: 'Networks', url: `/users/${urlMidPart}/networks`},
                {value: 'Доходы', url: `/users/${urlMidPart}/report`}
            ]
        } else {
            links = [
                {value: 'Площадки', url: `/users/${urlMidPart}/placements`},
                {value: 'Deductions', url: `/users/${urlMidPart}/deductions`},
                // {value: 'Платформы', url: `/users/${urlMidPart}/platforms`},
                {value: 'Договоры', url: `/users/${urlMidPart}/contracts`},
                {value: 'Доходы', url: `/users/${urlMidPart}/report`}
            ]
        }

        if (me?.is_staff) {
            links.splice(2, 0, {value: 'Платформы', url: `/users/${urlMidPart}/platforms`})
        }
        setTopbarData({
            links,
            sublinks: null
        })
    }, [setTopbarData, urlMidPart, me])

    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])


    const downloadData = async () => {

        getInitialData()
            const response_data = await axios.get(location.pathname + `/?month=${dateFields.month.value}&year=${dateFields.year.value}&currency=${dateFields.currency.value}`)
            console.log(response_data);

        }


        const buttons = useMemo(() => {
            if (urlEndPart === '/report' && !me?.is_staff) {
                    return [
                        {
                            text: `Отклонить`,
                            // url: `${location.pathname}/new/`, ,
                            url: `users/clients/${client_id}${urlEndPart}/confirm/?confirm=Нет`,
                            feedback: true
                        },
                        {
                            text: `Принять`,
                            // url: `${location.pathname}/new/`, ,
                            url: `users/clients/${client_id}${urlEndPart}/confirm/?confirm=Да`,
                            feedback: true
                        }
                    ]
            }

            if (urlEndPart === '/placements' || urlEndPart === '/deductions') {
                return []
            }
            return [
                {
                    text: `Добавить ${getText()[0]}`,
                    icon: 'plus',
                    // url: `${location.pathname}/new/`, ,
                    url: `users/clients/${client_id}${urlEndPart}/new/`,
                    popup_title: getText()[1],
                    popup_callback() {
                        getTable()
                    }
                },
            ]
        }, [getTable, getText, client_id, urlEndPart])

        useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })
        }, [location.pathname, setPopup])
        // ----------------- ROW CLICK START ---------------------
        const handleRowClick = async row => {
            const request_id = row['request_id'].value

            if (urlEndPart === '/placements') {
                console.log(`${location.pathname}/${request_id}/tz/view`);
                // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/view`);
                history.push({
                    pathname: `${location.pathname}/${request_id}/tz/view`,
                })
                return
            }

            if (urlEndPart === '/contracts') {
                console.log(`${location.pathname}/${request_id}/additional`);
                // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/view`);
                history.push({
                    pathname: `${location.pathname}/${request_id}/additional`,
                })
                return
            }

            if (urlEndPart === '/networks') {
                console.log(`${location.pathname}/${request_id}/deductions`);
                // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/view`);
                history.push({
                    pathname: `${location.pathname}/${request_id}/deductions`,
                })
                return
            }


            // IF Платформы/DEDUCTIONS TABLE ROW CLICKED, THEN OPEN A POPUP
            try {
                const urlEndPart = location.pathname.substring(location.pathname.lastIndexOf('/'))  // /contracts OR /deductions
                const url = `users/clients/${client_id}${urlEndPart}/${request_id}/edit/`
                const response = await axios.get(url)
                setPopup({
                    visible: true,
                    data: response.data,
                    url,
                    title: `Редактировать ${getText()[0]}`,
                    callback() {
                        getTable()
                    }
                })
            } catch (error) {
                setPopup({
                    visible: false,
                    data: {},
                    url: null,
                    title: ''
                })
            }

            // else { // ELSE GO INSIDE ROLE
            //     // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/`);
            //     history.push({
            //         pathname: `${location.pathname}/${request_id}`,
            //     })
            // }
        }
        // ----------------- ROW CLICK STOP ---------------------


        // ----------------- EDIT CLIENT START ---------------------
        const editClient = async () => {
            try {
                const url = `users/clients/${client_id}/edit/`
                const response = await axios.get(url)
                setPopup({
                    visible: true,
                    data: response.data,
                    url,
                    title: 'Редактировать клиента',
                    callback() {
                        getClient()
                    }
                })
            } catch (error) {
                setPopup({
                    visible: false,
                    data: {},
                    url: null,
                    title: ''
                })

                if (error.response.status === 500) return setServerError(true)
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        }
        // ----------------- EDIT CLIENT STOP ---------------------


        return (<>
                <div className='client page'>
                    {loadingClient
                        ? <LoaderComponent/>
                        : errorClient
                            ? <div>{errorClient.message}</div>
                            : <ClientInfo inline client={client} editClient={editClient}/>}
                    {/* <ClientInfo client={client}/> */}
                </div>


                <>

                    {loadingTable || loadingOnDateSubmit
                        ? <LoaderComponent text={loadingOnDateSubmit ? 'Загрузка...' : 'Загрузка...'}/>
                        : errorTable
                            ? <div>{errorTable.message}</div>
                            : <TableV
                                table={report}
                                date_fields={dateFields}
                                buttons={buttons.reverse()}
                                yerkin_debil={urlEndPart === "/report"}
                                handleRowClick={handleRowClick}
                                onDateFieldsChange={handleFieldChange}
                                handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                                refreshData={getInitialData}

                                rowClickableByClient={(() => {
                                    switch (table.title) {
                                        case 'Договоры':
                                            return true
                                        case 'Площадки':
                                            return true
                                        default:
                                            return false
                                    }
                                })()}
                                href={(() => {
                                    let href
                                    switch (table.title) {
                                        case 'Договоры':
                                            href = null
                                            break
                                        case 'АВР':
                                            href = {
                                                from: location.pathname,
                                                to: 'view'
                                            }
                                            break
                                        default:
                                            href = {
                                                from: location.pathname,
                                                to: ''
                                            }
                                    }
                                    return href
                                })()
                                }
                            />}
                </>

            </>
        )
    }


export default Client;

// const buttons = [
//     {
//         text: 'Новый АВР',
//         icon: 'plus',
//         url: ''
//     },
//     {
//         text: 'Edit',
//         icon: 'edit',
//         url: ''
//     },
//     {
//         text: 'История изменений ТЗ',
//         icon: 'book',
//         url: ''
//     },
// ]

// const client = {
    //     name: "Асем Ай",
    //     data: [
    //         {
    //             key: 'Клиент',
    //             value: 'Dizzy TOO'
    //         },
    //         {
    //             key: 'БИИ/ИНН/ИИН',
    //             value: '"1234567890"'
    //         },
    //         {
    //             key: 'Тип бренда',
    //             value: 'KPI'
    //         },
    //     ]
    // }



     // useEffect(() => {
    //    console.log(location.pathname); // result: '/secondpage'
    //    console.log(location.state); // result: 'some_value'
    // }, [location]);

    // const { response: table, error: tableError, loading: tableLoading } = useAxios(`${location.pathname}/`)
    // const { response: client, error: clientError, loading: clientLoading } = useAxios(`clients/${id}/`)
