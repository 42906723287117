import React, { useCallback, useEffect, useState } from 'react'
import './users.css'
import Table from '../../components/layout/table/Table'

import { useGlobalContext } from '../../store/context/context'

import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'

export const Users = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const history = useHistory()
    const location = useLocation()
    
    // const { response: table, error, loading } = useAxios('clients/')

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('users/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])
   
    useEffect(() => {
        setActiveSidebarLink('users')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users', breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Добавить пользователя',
            icon: 'plus',
            url: 'users/new/',
            popup_title: 'Пользователь',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `users/${request_id}/clients`,
        })
        // try {
        //     const urlEndPart = location.pathname.substring(location.pathname.lastIndexOf('/'))  // /contracts OR /deductions
        //     const url = `users/clients/${id}${urlEndPart}/${request_id}/edit/`
        //     const response = await axios.get(url)
        //     setPopup({
        //         visible: true,
        //         data: response.data,
        //         url,
        //         title: `Редактировать пользователя`,
        //         callback() {
        //             getTable()
        //         }
        //     })
        // } catch (error) {
        //     setPopup({
        //         visible: false,
        //         data: {},
        //         url: null,
        //         title: ''
        //     })
        // }
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
        <div className="clients page">
        </div>

        <TableV 
            table={table} 
            buttons={buttons.reverse()} 
            handleRowClick={handleRowClick} 
            refreshData={getTable} 
            href={{
                from: '/users/clients',
                to: 'placements'
            }}    
        />
        </>
    )
}
