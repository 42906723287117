import React, {useState} from 'react';
import {
    Chart as ChartJS,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
};




export const StolbBar = ({graph}) => {
    const data_values = []
    const labels = []

    for(let i = 0; i < graph.rows.length-1; i++){
        if(graph.rows[i].report_revenue.value > 1){
            data_values.push(Math.round(graph.rows[i].report_revenue.value) ,)
            labels.push(graph.rows[i].report_system.value,)
        }
    }
    console.log(data_values)


    const [dataf, setDataf] = useState(
        {
            labels: labels,
            datasets: [
                {
                    label: "диаграмма",
                    data: data_values,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    );

    return (
        <>
            <div style={{width:'50%', float:"right"}}>
                <Bar data={dataf} options={options} height={230}/>
            </div>
        </>

    );
}
