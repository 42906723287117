import React, { useCallback, useState, useRef, useEffect } from 'react';
import moment from 'moment'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './date.css'
import Text from '../text/Text';
import {ReactComponent as CalendarIcon} from '../../../assets/sidebar-icons/carbon_calendar.svg'
import { toggleFormFieldBottom } from '../../../utils/js/toggleFormFieldBottom';



export default function DateFieldForPopup({label, name, value='', onChange, onDoubleClick, parent, editable, required}) {
    // var dateString = "23.10.2015"; // Oct 23   // WITH SLASH ALSO WORKS ==>> 23/10/2015
    const dateMomentObject = moment(value, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
    // console.log(dateObject);

    const [dateValue, setValue] = useState(value ? dateObject : ''); // sting.replace(/./g, '/') DOSE THE JOB TOO 
    const calendarRef = useRef()
    const dateRef = useRef()

    const handleCalendarChange = (dateValue) => {
        const label = dateRef.current.querySelector('label')
        label.classList.add('filled') // DATE IS CHOSED, THUS APPLY NECESSARY STYLES (p.s.: 'focused' is already applied)

        setValue(dateValue)
      
        const event = {
            target: {
                name, 
                value: moment(dateValue).format('DD.MM.YYYY')
            }
        }
        onChange && onChange(event)
    }

    // 'en-GB': British English uses day-month-year order → "20/12/2012"
    // 'en-US:  US English uses month-day-year order → "12/19/2012"
    return (
        <div className={`date form-field ${editable ? 'editable' : 'uneditable'}`} ref={dateRef}>  
            <div className="form-field__top" >
                <Text type="text" label={label} name={name} value={dateValue && new Date(dateValue).toLocaleDateString('en-GB')} parentForm="date" editable={editable} required={required}/>
                <button className={`svg-container form-field__open-button ${editable ? '' : 'disabled'}`} type="button"><CalendarIcon /></button>
            </div>                                              
            <Calendar
                onChange={handleCalendarChange}
                value={dateValue}
                locale="ru-Ru"
                inputRef={calendarRef}
                className="date__react-calendar-for-popup"
            />
        </div>
    );
}




// import 'date-fns';
// import React from 'react';
// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

// export default function Picker() {
//   // The first commit of Material-UI
//   const [selectedDate, setSelectedDate] = React.useState(new Date());

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   return (
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <Grid container justifyContent="space-around">
//         <KeyboardDatePicker
//           disableToolbar
//           variant="inline"
//           format="MM/dd/yyyy"
//           margin="normal"
//           id="date-picker-inline"
//           label="Date picker inline"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change date',
//           }}
//         />
//         {/* <KeyboardDatePicker
//           margin="normal"
//           id="date-picker-dialog"
//           label="Date picker dialog"
//           format="MM/dd/yyyy"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change date',
//           }}
//         /> */}
//         {/* <KeyboardTimePicker
//           margin="normal"
//           id="time-picker"
//           label="Time picker"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change time',
//           }}
//         /> */}
//       </Grid>
//     </MuiPickersUtilsProvider>
//   );
// }

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));

// export default function DatePickers() {
//   const classes = useStyles();

//   return (
//     <form className={classes.container} noValidate>
//       <TextField
//         id="date"
//         label="Birthday"
//         type="date"
//         defaultValue="2017-05-24"
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }