import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <p className="footer__text">Copyright &copy; {new Date().getFullYear()} TDS Media. All rights reserved.</p>
            {/* <p className="footer__text">Designed by Tahir</p> */}
        </div>
    )
}

export default Footer
