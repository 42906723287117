import React, { useCallback, useEffect, useRef, useState } from 'react'
import Text from '../text/Text'
import './popup_field.css'
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../assets/sidebar-icons/arrow-close.svg'
import { useGlobalContext } from '../../../store/context/context'
import CloseButton from '../../buttons/close/CloseButton'
import { SelectFieldForPopup } from '../select/Select_For_Popup'
import DateFieldForPopup from '../date/Date_For_Popup'
import { MdSelectAll } from 'react-icons/md'
import {ReactComponent as CalendarIcon} from '../../../utils/icons/carbon_calendar.svg'


export const PopupField = ({ children, value, value_id, label, name, onChange, rowClickable, editable, required, parent, getOptions, popupField={ type: 'Default Popup Field Type', title: 'Default Popup Field Title'} }) => {
    const { renderFeedback } = useGlobalContext()
    const [showOptionsList, setShowOptionsList] = useState(false)
    const [options, setOptions] = useState([])
    
    const [fieldsForPopup] = useState({
        'select_field': SelectFieldForPopup,
        'select_field2': SelectFieldForPopup,
        'date_field': DateFieldForPopup,
        
        'status_icon_field': SelectFieldForPopup,
    }) 
 
    const toggleFormFieldBottom = async () => {
        if (!editable) return
        if (getOptions && !showOptionsList && popupField.type !== 'date_field') {
            try {
                const options = await getOptions()
                console.log(options);
                setOptions(options)
                setShowOptionsList(true)
            } catch (error) {
                console.log(error.response.data.message);
                renderFeedback('danger', error?.response.data.message || 'Server Error')
            }
        } else {
            setShowOptionsList(true)
        } 
    }

    const inputRef = useRef(null)
    const saveSelectedOption = event => {
        console.log(inputRef.current);
        inputRef.current.focus()  // NEEDED TO CHECK FOR document.activeELement.name 
        onChange && onChange(event)
        setShowOptionsList(false)
    }

    const removeSelectedOption = () => {
        const event = {
            target: {
                name, 
                value: '',
                value_id: -999  // WAS REQUESTED BY NURA FOR UNSETTING SELECT FIELD
            }
        }
        inputRef.current.focus()
        onChange && onChange(event)
        setShowOptionsList(false)
    }

    const FieldForPopup = fieldsForPopup[popupField.type]
    return (<> 
        {popupField.type === 'status_icon_field'  // LITTLE FOCUS WITH INPUT AND CHILDREN(<svg></svg> element) IN ORDER TO BE ABLE TO CONTROL FOCUS AND LATER CHECK FOR document.activeELement.name 
        ? <span className="svg-container" onClick={toggleFormFieldBottom} style={{position: 'relative'}}>
            <input type="text" ref={inputRef} name={name} style={{width: 'inherit', cursor: 'pointer', border: 'none', height: 'inherit', borderRadius: '4px'}}/>
            {children}
          </span>
        : <div className={`form-field ${editable ? 'editable' : 'uneditable'}`}>
            <div onClick={toggleFormFieldBottom} className="form-field__top" >
                {/* <Text type="text" label={label} name={name} value={value} rowClickable={rowClickable} editable={editable} required={required}/> */}
                <input ref={inputRef} autoComplete="off" name={name} required id={name} disabled={!editable} readOnly className="text__input" value={value} onChange={onChange} style={{borderColor: editable && required && !value ? 'red' : '', paddingRight: editable ? '23px' : ''}} />

                {!!editable && <button className="svg-container form-field__open-button" type="button">{popupField.type === 'date_field' ? <CalendarIcon /> :  showOptionsList ? <ArrowClose /> : <ArrowOpen />}</button>}
            </div>
        </div>}
        {showOptionsList && 
        <div className="popup-container background" onDoubleClick={() => setShowOptionsList(false)}>
            {/* <div className="popup w500 popup-form-field" onDoubleClick={event => event.stopPropagation()}> */}
            <div className="popup popup-form-field" onDoubleClick={event => event.stopPropagation()}>
                <h2 className="popup__title" style={{paddingBottom: '0'}}>{popupField.title}</h2>
                <div className="popup__close-btn-container" onClick={() => setShowOptionsList(false)}>
                    <CloseButton />
                </div>
                {/* <SelectFieldForPopup name={name} value={value} editable={editable} rowClickable={rowClickable} options={options} onChange={handleSelectFieldChange} />   */}
                {/* <DateFieldForPopup name={name} value={value} editable={editable} rowClickable={rowClickable} onChange={handleSelectFieldChange} />   */}
                <FieldForPopup name={name} value={value} editable={editable} rowClickable={rowClickable} options={options} onChange={saveSelectedOption} />  

                {/* <button className="popup__form_field_button" onClick={saveSelectedOption}>Сохранить</button> */}
                <button className="popup__form_field_button" onClick={removeSelectedOption}>Удалить</button>
            </div>
        </div>
        }
        </>
    )
}

            // if (lowerCaseString(inputValue) !== lowerCaseString(selectedOption)) {
            //     // inputValue DOES NOT MATCH selectedOption, then clear the input field AND remove additional styles from LABEL
            //     setInputValue('')
            //     label.classList.remove('focused', 'filled')
            // } else if (!lowerCaseString(inputValue)) {
            //     // they match, but input field IS empty (i.e. erased with backspace completely), then remove additional styles from LABEL
            //     label.classList.remove('focused', 'filled')
            // } else {
            //     // they match, and input field IS NOT empty, then add additional styles to LABEL
            //     label.classList.add('focused', 'filled')
            // }