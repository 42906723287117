import React, { useState, useCallback, useEffect, useMemo, useRef, forwardRef } from 'react'
import './table.css'

import {ReactComponent as Plus} from '../../../utils/icons/plus.svg'
import {ReactComponent as Pencil} from '../../../utils/icons/pencil.svg'
import {ReactComponent as Tools} from '../../../utils/icons/tools.svg'
import {ReactComponent as List1} from '../../../utils/icons/list_1.svg'
import {ReactComponent as PlayButton1} from '../../../utils/icons/play-button_1.svg'
import {ReactComponent as Wait} from '../../../utils/icons/wait.svg'
import {ReactComponent as Pause1} from '../../../utils/icons/pause_1.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as PlayButton2} from '../../../utils/icons/play-button_2.svg'
import {ReactComponent as TZ_add} from '../../../utils/icons/tz_add.svg'
import {ReactComponent as Target1} from '../../../utils/icons/target_1.svg'
import {ReactComponent as Fill} from '../../../utils/icons/fill.svg'
import {ReactComponent as Coin1} from '../../../utils/icons/coin_1.svg'
import {ReactComponent as Tick1} from '../../../utils/icons/tick_1.svg'


import { useGlobalContext } from '../../../store/context/context'

import DateField from '../../forms/date/Date'
import TextField from '../../forms/text/Text'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import useDebounce from '../../../utils/js/useDebounce'
import { PopupField } from '../../forms/popup_field/PopupField'
import { LoaderComponent } from '../loader/Loader'
import { CheckboxField2 } from '../../forms/checkbox/Checkbox2'
import SwipeUp from '../swipe-up/SwipeUp'
import DateMUIField from '../../forms/date/DateMUI'
import DateNumberField from '../../forms/date/DateNumber'
import { Hint, HintHeader, HintTable } from '../hint/Hint'
import { SelectPositionedField } from '../../forms/select/SelectPositioned'
import { formatValue } from './formatValue'
import { BsCurrencyExchange } from 'react-icons/bs'
import DateRangeField from '../../forms/date/DateRange'
import { TableDateFields } from './assets/TableDateFields'
import { SendButton } from '../../buttons/send/SendButton'


const SPECIAL_NUMBER = -999999  // WAS INVENTED TO DETERMINE IF THE MINUTES CELL NEEDS TO BE EDITABLE OR NOT WHEN THE VALUE OF THE CELL IS EQUAL TO NOTHING(0), i.e., IS TO BE FILLED WHITIN 24HRS OR IT'S ALREADY LATE TO EDIT AND THE VALUE IS NOTHING

const CurrencyIcon = forwardRef((props, ref) => {
    return <span ref={ref}><BsCurrencyExchange />:</span>;
});
const DateRangeFieldFwdRef = forwardRef((props, ref) => {
    const { name, value, onChange } = props
    return <div ref={ref}>
            <DateRangeField label="" name={name} value={value} onChange={onChange} editable/>
        </div> 
});

export const TableWithChildren = ({ 
    table, 
    setTable,
    date_fields={}, onDateFieldsChange,
    handleRowClick, handleDateSubmitButtonClick,
    buttons=[], 
    tzType, 
    refreshTableBottomData, 
    parentComponent, 
    tableType, 
    updateTableHead, 
    parentElement, 
}) => {
    const { setShowFeedback, setFeedbackInfo, setPopup, setPopupFill, renderFeedback, tableIcons, SPECIAL_VALUES, buttonIcons, setAlert, mainCenterTopBottomMargin, me } = useGlobalContext()
    const [didMount, setDidMount] = useState(false); 

    useEffect(() => {
       setDidMount(true);
       return () => setDidMount(false);
    }, [])

    const history = useHistory()
    const location = useLocation()

    const [columnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
   
    const [showSwipeUp, setShowSwipeUp] = useState(false)
    const [swipeUpData, setSwipeUpData] = useState(false)
  
    const tableContentRef = useRef(null)
    const tableCaptionContainerRef = useRef(null)
    const [tbodyDisplay, setTBodyDisplay] = useState(JSON.parse(localStorage.getItem('tbodyDisplayObject'))?.[table.request_id] || 'table-row-group')  // OR 'none'

    
    const [loading, setLoading] = useState(false)

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: PopupField,
        select_field: SelectPositionedField,
        select_field2: PopupField,
        // date_field: PopupField,
        // date_field: DateMUIField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })

    const resizeTable = useCallback(() => {
        if (!didMount) return
        console.log('efefef');
        // resizeCells()
        const topbar = document.querySelector('.topbar')
        // const windowHeight = window.innerHeight
        const windowHeight = parentElement ? document.querySelector(`.${parentElement}`).getBoundingClientRect().height : window.innerHeight
        const topbarHeight = topbar.getBoundingClientRect().height
        const topBottomMargins = mainCenterTopBottomMargin * 2
        const tableCaptionContainerHeight = tableCaptionContainerRef.current.getBoundingClientRect().height  // table-caption-container
        const newHeight = windowHeight - topbarHeight - topBottomMargins - tableCaptionContainerHeight - 1  // -1 because otherwise appears horizontal scroll bar 
        
        if (tableContentRef.current.firstElementChild.getBoundingClientRect().height > newHeight) {
            tableContentRef.current.style.height = `${newHeight}px`
        } else if(tableContentRef.current.firstElementChild.getBoundingClientRect().height < newHeight) {
            tableContentRef.current.style.height = 'auto'
        }

    }, [mainCenterTopBottomMargin, parentElement, didMount])
    useEffect(() => {
        resizeTable() // ALSO RESIZES CELLS
        window.addEventListener('resize', resizeTable)
        return () => window.removeEventListener('resize', resizeTable)
    }, [resizeTable, didMount, columnAccessors, tbodyDisplay])
     // ----------------- BUTTON CLICK START ---------------------
    const handleButtonClick = async ({url, popup_title='', popup_callback, feedback=false, onClick, alert}) => {
        if (alert) {
            setAlert({
                visible: true,
                title: alert.title,
                body: alert.body,
                onSuccessFunction: onClick
            })

            return
        }

        if (onClick) return onClick(table.rows, table, columnAccessors)  // IDEA CAME DURING THE PROCESS  // EXISTS IN BRAND.JSX && CLIENT.JSX && AVRS.JSX  &&&& AvrNew.jsx + ClientAvr.jsx
                        // rows ARE SEND FOR AvrNew.jsx + ClientAvr.jsx COMPONENTS FOR ADD BTN && table, columnAccessors FOR COPY BTN 
      
        try {
            const response = await axios.get(url)
            // console.log(response.data);
            if (feedback) {  // IT MEANS THAT WE DO NOT NEED TO SHOW THE POPUP INSTEAD WE SHOW FEEDBACK
                renderFeedback('success', response.data.message || 'Success')
                history.goBack()
                // setTimeout(() => {
                // }, 2000)
                return
            }
            console.log(popup_title);
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: popup_title[0].toUpperCase() + popup_title.substring(1),
                callback: popup_callback
            })
        } catch (error) {
            console.log(error.message);
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })
           
            renderFeedback('danger', error.response?.data.message || 'Error!')
        }
    }
    // ----------------- BUTTON CLICK END ---------------------

    const [sendRequest, setSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [sendRequestImmediately, setSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
        isOutsideChildrenClicked: null,
        row_index: null,
        child_index: null,
    })
    
    useDebounce(() => {
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, isOutsideChildrenClicked, row_index, child_index } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                const { additional_info, ...updated_child } = response.data
                
                console.log(additional_info, updated_child);
                
                
                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row)
                }

                setTable(oldTableDate => {
                    return { ...oldTableDate, body: oldTableDate.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, rows: updatedRows, headers: {...singleTable.headers, children: {...singleTable.headers.children, subtask_time: {...singleTable.headers.children.subtask_time, label: additional_info.sum_table}}}} : singleTable)}
                })

                
                // setLoading(false)
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 500, [sendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        console.log('Send Request Immediately...');
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, isOutsideChildrenClicked, row_index, child_index } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                const { additional_info, ...updated_child } = response.data
                
                console.log(additional_info, updated_child);

                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row)
                }

                setTable(oldTableDate => {
                    return { ...oldTableDate, body: oldTableDate.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, rows: updatedRows, headers: {...singleTable.headers, children: {...singleTable.headers.children, subtask_time: {...singleTable.headers.children.subtask_time, label: additional_info.sum_table}}}} : singleTable)}
                })

               
                // setLoading(false)
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 0, [sendRequestImmediately])  // SEND REQUEST IMMEDIATELY
    // console.log(rows);

    const getOptions = (accessor, dataToSend, isOutsideChildrenClicked=false) => {
        // console.log(dataToSend);
        return new Promise(async (resolve, reject) => {
            try {
                const { get_url } = isOutsideChildrenClicked ? table.headers[accessor] : table.headers['children'][accessor]
                // console.log(get_url);
                const response = await axios.post(`${location.pathname}/${get_url}`, dataToSend)
                // console.log(response.data);
                const options = response.data
                resolve(options)
                // resolve([1,3,4,5,6,7])
            } catch (error) {
                console.log(error.message);
                // renderFeedback('danger', error.response.data.message)
                reject(error)
            }
        })
    }
    
    const handleFieldChange = (event, row_index, parent_request_id, isOutsideChildrenClicked=false, child_index) => {
        console.log(document.activeElement);

        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT

        let dataToSend
        let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        if (isOutsideChildrenClicked) {
            dataToSend = rowsCopy[row_index]

            if (dataToSend[event.target.name].is_task_name) {
                const row = dataToSend
                    const newRow = {
                        ...row,
                        [event.target.name]: {  // [event.target.name] === [accessor]
                            ...row[event.target.name],  
                            value: event.target.value
                        }
                    }
                    return setTable(oldTableDate => {
                        return { ...oldTableDate, body: oldTableDate.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, rows: singleTable.rows.map(row => row.request_id.value === parent_request_id ? newRow : row)} : singleTable)}
                    })

            }
            delete dataToSend.children
        } else {
            dataToSend = rowsCopy[row_index].children[child_index]
        }
        dataToSend[event.target.name].value = event.target.value
        if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2
        
        // dataToSend['additional_info'] = {
        //     'task_name': rowsCopy[row_index]['task_name'],
        //     'sum_table': table.headers.children['subtask_time'].label,
            
        // }


        let url = isOutsideChildrenClicked // NOW WE ARE DEALING WITH "TZ KPI"
            ? `${location.pathname}/${table.headers[event.target.name].set_url}` 
            : `${location.pathname}/${table.headers['children'][event.target.name].set_url}`
        

        useDebounceInfo.current = {
            url,
            dataToSend,
            isOutsideChildrenClicked,
            row_index,
            child_index,
            accessor: event.target.name
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setSendRequestImmediately(!sendRequestImmediately)
        } else {
            setSendRequest(!sendRequest)
        }
    }

    const handleIconButtonClick = async (icon_accessor, child_index, child, row_index, row, isEditable) => {
        if (!Boolean(isEditable)) return // NOT EDITABLE
        //    console.log(icon_accessor);
        if (icon_accessor === 'add') {
            try {
                setLoading(true)
                let url
                if (parentComponent === 'avr') {
                    const parent_request_id = row.request_id.value
                    url = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                } else {
                    url = `tzs/${tzType}/add/inst/`
                }
                const response = await axios.post(url)
                const newChild = response.data
                // setRows(rows.map((row, i) => i === row_index ? {...row, children: [...row.children, newChild]} : row))
                setTable({ ...table, rows: table.rows.map((row, i) => i === row_index ? {...row, children: [...row.children, newChild]} : row)})
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'fill') {
            // console.log(icon_accessor);
            try {
                if (tzType === 'kpi') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?tz_set_id=${row.request_id.value}`)
                    console.log(response.data);
                    setPopupFill({
                        type: 'kpi',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?tz_set_id=${row.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                } else if (tzType === 'billing') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?inst_id=${child.request_id.value}`)
                    console.log(response.data);
                    setPopupFill({
                        type: 'billing',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?inst_id=${child.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                }
                
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'remove') {
            // console.log(icon_accessor);
            try {
                setLoading(true)
                // const responseParent = await axios.get(`${location.pathname}/new/?task_id=${table.request_id}`)
                const response = await axios.post(`${location.pathname}/data/delete/`, child)
                
                let rowsCopy = JSON.parse(JSON.stringify(table.rows))
                // if (rowsCopy[row_index].children.length === 1) rowsCopy = rowsCopy.filter((_, i) => i !== row_index)
                if (rowsCopy[row_index].children.length === 1) throw { response: { data: { message: 'Подзадача должна быть выполнена как минимум 1 раз!' } } } // У этой подзадачи должно быть как минимум
                else rowsCopy[row_index].children = rowsCopy[row_index].children.filter((_, i) => i !== child_index)
                // setRows(rowsCopy)
                // setTasksTable({ ...table, rows: rowsCopy })
                setTable(oldTableDate => {
                    return { ...oldTableDate, body: oldTableDate.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, rows: singleTable.rows.map((row, i) => i === row_index ? rowsCopy[0] : row)} : singleTable)}
                })
                // if (response.data.hasOwnProperty('updated_values')) {
                //     updateTableHead(response.data.updated_values.avr_sum_vat)
                // }
                
                renderFeedback('success', response.data.message || 'Success')
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')

            } finally {
                setLoading(false)
            }

        } else if (icon_accessor === 'open') {
            console.log(icon_accessor);
            const url = `tzs/kpi/set/open/?inst_id=${child.request_id.value}&inst_type=${child.inst_type.value}`
            
            try {
                // setLoading(true)
                const response = await axios.post(`tzs/kpi/get/open/`, child)
                // console.log(response.data);
                let { data } = response
                if (location.pathname.includes('view')) {
                    for (let accessor in data) {
                        if (accessor === 'targetings') {
                            for (let tAccessor in data['targetings']) {
                                data['targetings'][tAccessor].editable = true
                            }
                        } else {
                            data[accessor].editable = true
                        }
                    }
                }
                // console.log(data);  // IF VIEW EDITABLE IS SET TO FALSE IN FRONT
                setPopup({
                    type: 'popup2',
                    visible: true,
                    data,
                    url,
                    title: 'Targetings',
                    // callback: refreshTableBottomData // WAS REMOVED INTENTIALLY AS WE ONLY NEED TO CLOSE THE POPUP NOT TO UPDATE THE TABLE
                    button: {
                        visible: !location.pathname.includes('view'),
                        text: 'Отправить'
                    },
                    targetingsEditable: !location.pathname.includes('view')
                })     
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'edit') {
            try {
                if (parentComponent === 'avr' && tableType === 'body') {
                    const urlForEditBtn = `avrs/body/get/edit/`  // BOTH FOR BODY AND FOOT
                    const response = await axios.post(urlForEditBtn, row)
                    setSwipeUpData({
                        table: response.data,
                        buttons: [{ text: 'Изменить', 
                                    icon: 'plus', 
                                    async onClick(newRows) {  // THERE ARE SECOND AND THIRD PARAMETERS AS WELL "table, columnAccessors"
                                        try {
                                            const parent_request_id = row.request_id.value
                                            const response = await axios.post(`avrs/body/set/edit/?avr_set_id=${parent_request_id}`, newRows)
                                            console.log(response.data);
                                            
                                            setSwipeUpData({ table: null, buttons: []})
                                            setShowSwipeUp(false)
                                            refreshTableBottomData()
                                            renderFeedback('success', response.data.message || 'Success')   
                                            
                                            if (response.data.hasOwnProperty('updated_values')) {
                                                updateTableHead(response.data.updated_values.avr_sum_vat)
                                            }
                                        } catch (error) {
                                            renderFeedback('danger', error.response?.data?.message || 'Server Error')    
                                        }
                                    } 
                                }]
                    })
                    
                    setShowSwipeUp(true)
                   
                    // else { // AVR FOOT
                    //     const parent_request_id = response.data.request_id.value
                    //     const urlForAddChildBtn = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                    //     const response_child = await axios.post(urlForAddChildBtn) 
                    //     setRows([...rows, {...response.data, children: [{...response_child.data}]}])
                    // }
                }
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || error.message || 'Server Error')
            } 
        }
    }

    const getCellSpan = (value, type, sign, bold) => (
        <span 
            style={{ fontWeight: bold && '600' }} 
            // dangerouslySetInnerHTML={{ __html: formatValue(value, type, sign, table.is_round)}}
            dangerouslySetInnerHTML={{ __html: value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? '' : formatValue(value, type, sign, table.is_round)}}
            >   
        </span>)

    const getChildPart = (child_idx, child, row_idx, row) => {
        return Object.keys(table.headers['children']).map(child_accessor => {
            if (!table.headers['children'][child_accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
            
            const { value, type, editable, sign, color, hint, bold } = child[child_accessor]
            if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                // const isCellClickable = headers['children'][child_accessor].label === 'icon'
                const Icon = tableIcons[value]
                return (
                    <td key={child_accessor} className='table__td' style={{cursor: editable ? 'pointer' : 'default'}}>
                        <div className={`table__td__center ${child_accessor}`} style={{justifyContent: 'center'}} onClick={() => handleIconButtonClick(child_accessor, child_idx, child, row_idx, row, child[child_accessor].editable)}>
                            {child_accessor === 'inst_status' 
                                ? <PopupField name={child_accessor} value={value} editable={editable} rowClickable={table.clickable} sign={sign} popupField={{ type: 'status_icon_field', title: table.headers['children'][child_accessor].label}} getOptions={() => getOptions(child_accessor, child)} onChange={(event) => handleFieldChange(event, row_idx, row.request_id?.value, false, child_idx)} >
                                    <Icon style={{position: 'absolute'}}/>
                                  </PopupField> 
                                : <span className={`svg-container`} data-editable={editable}><Icon /></span>}
                        </div>
                    </td>
                )
            }

            if (value === null) return  <td key={child_accessor} className={`table__td ${child_accessor}`} style={{backgroundColor: color || '#fff'}}><div>----</div></td>

            if (editable) {
                const FormField = formFields[type]  // MEANS THAT THIS IS EDITABLE, THUS RETURN FORM-FIELD
                return (
                    <td key={child_accessor} className={`table__td ${child_accessor}`} style={{backgroundColor: color || '#fff'}}>
                        <div className={`table__td__center ${child_accessor}`} >
                            <div style={{flex: '1'}}>
                                <FormField parentComponent='PopupFillBilling' name={child_accessor} value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value} editable={editable} rowClickable={table.clickable} sign={sign} popupField={{ type, title: table.headers['children'][child_accessor].label}} getOptions={() => getOptions(child_accessor, child)} onChange={(event) => child_accessor === 'request_id' ? {} : handleFieldChange(event, row_idx, row.request_id?.value, false, child_idx)} />  
                            </div>
                        </div>
                    </td>
                )
            }
            return (  // IF THE CELL IS NOT EDITABLE, THEN RETURN DIV
                <td key={child_accessor} className={`table__td ${child_accessor}`} style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                    <div 
                        style={{flex: '1'}} 
                        className={`table__td__center ${child_accessor} ${table.clickable ? 'clickable' : 'unclickable'} pointer-events-none`} 
                        onClick={(event) => event.preventDefault()}
                    > 
                        {hint ? <HintHeader hint={hint}>{getCellSpan(value, type, sign, bold)}</HintHeader> : getCellSpan(value, type, sign, bold)}
                    </div>
                </td>
            )

            

        })
    }
    


    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        return (<>
            {table.rows.map((row, idx) => {
                return row['children'].map((child, index) => {
                    if (index === 0) {  // IF THIS IS THE FIRST ROW THEN ADD OTHER TDs WITH ROWSPAN = CHILDREN.LENGTH
                        return  (  // RETURN THE FIRST CHILD && EVERYTHING OUTSIDE THE CHILDREN OBJECT
                            // <tr key={idx} className={`table__row ${table.clickable && 'clickable'}`} onClick={() => table.clickable ? handleRowClick(row) : {}}> 
                            <tr key={index} className={`table__row ${table.clickable && 'clickable'}`} > 
                                {columnAccessors.map(accessor => {
                                    if (accessor === 'children') return getChildPart(index, child, idx, row)

                                    // -------- EVERYTHING OUTSIDE THE CHILDREN OBJECT  // TDs WITH ROWSPAN START --------
                                    if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                    const {value, type, editable, sign, color, hint, bold } = row[accessor]
                                    if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                                        // const isCellClickable = headers[accessor].label === 'icon'
                                        const Icon = tableIcons[value]
                                        return (  // THIS IS WHERE add BUTTON IS 
                                            <td key={accessor} rowSpan={row['children'].length} className='table__td' style={{cursor: editable ? 'pointer' : 'default'}} onClick={() => handleIconButtonClick(accessor, index, child, idx, row, row[accessor].editable)}>
                                                <div className={`table__td__center ${accessor}`} style={{justifyContent: 'center'}} >
                                                    <span className="svg-container"><Icon /></span>
                                                </div>
                                            </td>
                                        )
                                    }

                                    if (value === null) return  <td key={accessor} className={`table__td ${accessor}`} style={{backgroundColor: color || '#fff'}}><div>----</div></td>

                                    if (editable) {
                                        // console.log(accessor);
                                        const FormField = formFields[type]
                                        return (
                                            <td key={accessor} rowSpan={row['children'].length} className={`table__td ${accessor}`} style={{backgroundColor: color || '#fff'}}>
                                                <div className={`table__td__center ${accessor}`} >
                                                    
                                                    <div style={{flex: '1'}}>
                                                        <FormField name={accessor} value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value} editable={editable} rowClickable={table.clickable} sign={sign} popupField={{ type, title: table.headers[accessor].label}}  getOptions={() => getOptions(accessor, row, 'outsideChildrenClicked')} onChange={(event) => handleFieldChange(event, idx, row.request_id.value, 'outsideChildrenClicked')}/>  
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td key={accessor} rowSpan={row['children'].length} className={`table__td ${accessor}`} style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                                            <div 
                                                style={{flex: '1'}} 
                                                className={`table__td__center ${accessor} ${table.clickable ? 'clickable' : 'unclickable'} pointer-events-none`} 
                                                onClick={(event) => event.preventDefault()}
                                            > 
                                                {hint ? <HintHeader hint={hint}>{getCellSpan(value, type, sign, bold)}</HintHeader> : getCellSpan(value, type, sign, bold)}
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    }
                    
                    // NOW RETURN THE OTHER ROWS WITH THE REST CHILDREN ELEMENTS, WITHOUT TDs WITH ROWSPAN 
                    // CHILDREN FROM INDEX 1 THROUGH END: children[1, last]
                    return  (
                        <tr key={index} className={`table__row ${table.clickable && 'clickable'}`} > 
                        {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                            {getChildPart(index, child, idx, row)}
                        </tr>
                    )
                })
            })}
        </>)
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------
    
    
    // ----------------- RENDER TABLE HEADER START ---------------------
    if(!didMount) {
        return null;
    }

    if (loading) return <LoaderComponent />

    return ( <>
        <div className="table-block">
        <div className="table-caption-container" ref={tableCaptionContainerRef}>
            <span className="table-caption">{table.title}</span>
            {!!Object.keys(date_fields).length && <>
            <TableDateFields date_fields={date_fields} onDateFieldsChange={onDateFieldsChange} />
            <SendButton onClick={handleDateSubmitButtonClick} />
            {/* <button className="button button-2" onClick={handleDateSubmitButtonClick}>
                <span>Отправить</span>
            </button> */}
            </>}
            {buttons && me.is_stuff && <div className="btns-container">
            {buttons.map((button, index) => {
                return <button key={index} className="button button-2" onClick={() => handleButtonClick(button)}>
                    <span className="svg-container">{buttonIcons[button.icon]}</span> 
                    <span>{button.text}</span>
                </button>
            })}
            </div>}
        </div>
        <div className="table-container" ref={tableContentRef}>
        {/* <div className="table-container" style={{height: `${tableContainerHeight}px`}} ref={tableContentRef}> */}
        <table className="table">
            <thead className="table__thead" >
                <tr>
                    {columnAccessors.map(accessor => {
                        if (accessor === 'children') {
                            const children = table.headers[accessor]
                            return Object.keys(children).map(accessor => {
                                if (!children[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                
                                const { label, hint, width } = children[accessor]

                                if (label === 'icon') {
                                    let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                    return (
                                        // <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                        <th key={accessor} className="table__th" style={{width: '.1%'}}>
                                             <div className={`table__th__center ${accessor}`} style={{width: width || '24px', justifyContent: 'center'}}>
                                                <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                                {hint && <HintTable hint={hint}/>}
                                            </div>
                                        </th>
                                    )
                                }

                                return (
                                    // <th key={accessor} className="table__th" style={{width: '1%', minWidth: accessor !== 'inst_status' && width}}>
                                    <th key={accessor} className="table__th" style={{width: width === 'auto' ? '100%' : '1%'}} >
                                        <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                            <span className="table__th__value" >{label}</span>
                                            {hint && <HintTable hint={hint}/>}
                                        </div>
                                    </th>
                                )
                            })
                        }

                        if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        
                        const { label, hint, width } = table.headers[accessor]

                        if (label === 'icon') {
                            let Icon = accessor === 'add' ? tableIcons['tools'] : tableIcons[accessor]
                            
                            return (
                                <th key={accessor} className="table__th" style={{width: '.1%'}} >
                                     <div className={`table__th__center ${accessor}`} style={{width: width || '24px', justifyContent: 'center'}}>
                                        <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                        {hint && <HintTable hint={hint}/>}
                                    </div>
                                </th>
                            )
                        }
                        return (
                            // <th key={idx} className={idx === sortedColumnIndex ? `table__th ${sortOrder}` : "table__th"} onClick={() => sort(idx, header.type)}>
                            <th key={accessor} className="table__th" style={{width: width === 'auto' ? '100%' : '1%'}}>
                                <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                    <span className="table__th__value" >{label}</span>
                                    {hint && <HintTable hint={hint}></HintTable>}
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody style={{display: tbodyDisplay}}>
                {getTableBody()}
            </tbody>
        </table>
        </div>
        </div>
    
        {/* {tableType === 'body' && <SwipeUp showSwipeUp={showSwipeUp} setShowSwipeUp={setShowSwipeUp} swipeUpData={swipeUpData} setSwipeUpData={setSwipeUpData}/>} */}
                    
    </>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}