import React, { useCallback, useEffect, useState } from 'react'
import './reports.css'

import { useGlobalContext } from '../../store/context/context'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableWithChildren } from '../../components/layout/table/TableWithChildren'
import { useCancelToken } from '../../utils/js/useCancelToken'

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

export const ReportsBlocks = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, renderFeedback, setShowFeedback, setServerError, table: tableWithChildren } = useGlobalContext()
    const { newCancelToken, isCancel } = useCancelToken();

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
    }, [])

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()
    // console.log(location);

    // const query = useQuery()
    // console.log(query);

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
        // currency: {
        //     label: 'валюта',
        //     value: 'KZT',
        //     type: 'select_field',
        //     options: ['KZT', 'RUB', 'USD']
        // },
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})
    // const [table, setTable] = useState(tableWithChildren)

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/', {
                cancelToken: newCancelToken()
            })
            setTable(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}&currency=${response.data.currency || 'KZT'}`)

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsBlocks');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, isCancel, newCancelToken])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/' + location.search, {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }
        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsBlocks');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, setServerError, isCancel, newCancelToken])

    // useEffect(() => {
    //     setLoading(false)
    //     setTable(tableWithChildren)
    // }, [])
    useEffect(() => {
        // console.log(location, history.action)
        if (history.action === 'REPLACE') return
        if (!location.search) return getInitialDataAndReplace()
        getInitialData()
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)
            const response = await axios.get(location.pathname + `/?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)
            setTable(response.data)
            history.replace(location.pathname + `?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)

        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }




    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/tzs/fills', breadcrumb: table?.title || '', clickable: false},
        ])
    }, [setBreadcrumbs, table?.title])

    const buttons = [
        {
            text: 'Скачать',
            icon: 'download',
            onClick() {
                try {
                    const headersValue = table.order.map(accessor => table.headers[accessor].label)
                    const rowsValue = table.rows.map(row => table.order.map(accessor => {
                        if (row[accessor].type === 'number_field') {
                            return String(row[accessor].value).replace('.', ',')
                        } else if (row[accessor].type === 'text_field') {
                            return (row[accessor].value).trim().replaceAll('\n', '')
                        }
                            return row[accessor].value
                        }
                    ))

                    const buildCSV = (headers, data) => {
                        if (!headers && !data) return;

                        let csvContent = "";

                        if (headers) {
                          csvContent += headers.join(";") + "\r\n";
                        }

                        if (data) {
                          data.forEach(row => {
                            csvContent += row.join(";") + "\r\n";
                          });
                        }
                        return csvContent;
                    }

                    function downloadFile() {
                        function download(data, fileName) {
                            let a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            let blob = new Blob([data], {type: "text/csv"})
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = fileName;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        };

                        let data = buildCSV(headersValue, rowsValue),
                        fileName = "Report.csv";

                        download(data, fileName);
                    }

                    downloadFile()
                } catch (error) {
                    console.error(error);
                    renderFeedback('danger', error.message || 'Unknown error!')
                }
            }
        }
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
    }
    // ----------------- ROW CLICK START ---------------------

    if(!didMount) return null;

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
        <div className="reports page">
        </div>

        {loadingOnDateSubmit
        ? <LoaderComponent text='Загрузка...'/>
        :
        // <TableTzBottom
        //     table={table}
        //     date_fields={dateFields}
        //     buttons={buttons.reverse()}
        //     handleRowClick={handleRowClick}
        //     onDateFieldsChange={handleFieldChange}
        //     handleDateSubmitButtonClick={handleDateSubmitButtonClick}
        //     refreshData={getInitialData}

        //     // table={tableBody}
        //     // setTable={setTableBody}
        //     // head_request_id={tableHead.rows[0].request_id.value}
        //     // parentComponent="avr"
        //     // tableType="body"
        //     // refreshTableBottomData={getBody}
        //     // updateTableHead={updateTableHead}
        //     // getHead={getHead}
        //     // getBody={getBody}
        // />

        // !!table.body.length && table.body.map(singleTable => (
            <TableWithChildren
                table={table}
                setTable={setTable}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                date_fields={dateFields}
                onDateFieldsChange={handleFieldChange}
                handleDateSubmitButtonClick={handleDateSubmitButtonClick}
            />
        // ))
        }
        </>
    )
}
