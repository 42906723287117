import React, { useState, useRef, useEffect, useCallback } from 'react'
import './table_tz.css'

import {ReactComponent as Plus} from '../../../utils/icons/plus.svg'
import {ReactComponent as Pencil} from '../../../utils/icons/pencil.svg'
import {ReactComponent as Book} from '../../../utils/icons/book.svg'
import {ReactComponent as Tools} from '../../../utils/icons/tools.svg'
import {ReactComponent as Confirm} from '../../../utils/icons/confirm.svg'
import {ReactComponent as List1} from '../../../utils/icons/list_1.svg'
import {ReactComponent as PlayButton1} from '../../../utils/icons/play-button_1.svg'
import {ReactComponent as Wait} from '../../../utils/icons/wait.svg'
import {ReactComponent as Pause1} from '../../../utils/icons/pause_1.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as PlayButton2} from '../../../utils/icons/play-button_2.svg'
import {ReactComponent as TZ_add} from '../../../utils/icons/tz_add.svg'
import {ReactComponent as Target1} from '../../../utils/icons/target_1.svg'
import {ReactComponent as Fill} from '../../../utils/icons/fill.svg'
import {ReactComponent as Copy} from '../../../utils/icons/copy.svg'
import {ReactComponent as Coin1} from '../../../utils/icons/coin_1.svg'
import { FcCancel } from 'react-icons/fc'
import { TiCancel } from 'react-icons/ti'

import { useGlobalContext } from '../../../store/context/context'

import DateField from '../../forms/date/Date'
import TextField from '../../forms/text/Text'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import useDebounce from '../../../utils/js/useDebounce'
import { PopupField } from '../../forms/popup_field/PopupField'
import { LoaderComponent } from '../loader/Loader'
import { CheckboxField2 } from '../../forms/checkbox/Checkbox2'
import SwipeUp from '../swipe-up/SwipeUp'
import DateMUIField from '../../forms/date/DateMUI'
import DateNumberField from '../../forms/date/DateNumber'
import { HintTable } from '../hint/Hint'
import { SelectPositionedField } from '../../forms/select/SelectPositioned'
import { formatValue } from '../table/formatValue'


const TableTzBottom = ({ table, setTable, buttons=[], head_request_id, tzType, refreshTableBottomData, parentComponent, tableType, updateTableHead, getBody}) => {
    const { setShowFeedback, setFeedbackInfo, setPopup, setPopupFill, renderFeedback, buttonIcons, mainCenterTopBottomMargin } = useGlobalContext()

    const history = useHistory()
    const location = useLocation()

    const [columnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    // const [rows, setRows] = useState(table.rows)
    const [headers] = useState(table.headers)
    // const memoizedColSpanValue = useMemo(() => Object.keys(table.headers).length - 1 + Object.keys(table.headers.children).length, [table.headers])

    const [showSwipeUp, setShowSwipeUp] = useState(false)
    const [swipeUpData, setSwipeUpData] = useState(false)
  
    const tableContentRef = useRef(null)
    const tableCaptionContainerRef = useRef(null)
    
    const [loading, setLoading] = useState(false)

    const [tableIcons] = useState({
        'open': List1,
        'remove': Trash1,
        'Активен': PlayButton1,
        'Ожидает': Wait,
        'Приостановлен': Pause1,
        'Завершен': PlayButton2,
        'Не запускали': FcCancel,
        'tools': Tools,
        'add': TZ_add,
        'target1': Target1,
        'edit': Pencil,
        'fill': Fill,
    })

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: PopupField,
        // select_field2: PopupField,
        select_field: SelectPositionedField,
        select_field2: SelectPositionedField,
        // date_field: PopupField,
        // date_field: DateMUIField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })

    const resizeTable = useCallback(() => {
        // if (!didMount) return
        // resizeCells()
        const topbar = document.querySelector('.topbar')
        // const windowHeight = window.innerHeight
        const windowHeight = window.innerHeight
        const topbarHeight = topbar.getBoundingClientRect().height
        // const topBottomMargins = mainCenterTopBottomMargin * 2
        const tableCaptionContainerHeight = tableCaptionContainerRef.current.getBoundingClientRect().height  // table-caption-container
        // const newHeight = windowHeight - topbarHeight - topBottomMargins - tableCaptionContainerHeight - 1  // -1 because otherwise appears horizontal scroll bar 
        const newHeight = windowHeight - topbarHeight - tableCaptionContainerHeight - 30 - 1  // -1 because otherwise appears horizontal scroll bar 
        
        if (tableContentRef.current.firstElementChild.getBoundingClientRect().height > newHeight) {
            tableContentRef.current.style.height = `${newHeight}px`
        } else if(tableContentRef.current.firstElementChild.getBoundingClientRect().height < newHeight) {
            tableContentRef.current.style.height = 'auto'
        }

    }, [])

    useEffect(() => {
        resizeTable() // ALSO RESIZES CELLS
        window.addEventListener('resize', resizeTable)
        return () => window.removeEventListener('resize', resizeTable)
    }, [resizeTable])

    // const displayHint = accessor => {
    //     // console.log(event.target);
    //     // const $hintText = document.querySelector(`.table__th__center.${accessor} .table__th__hint .hint-text`)
    //     // console.log($hintText);
    //     // $hintText.style.display = 'block'
    // }
    // const hideHint = accessor => {
    //     // console.log(event.target);
    //     // const $hintText = document.querySelector(`.table__th__center.${accessor} .table__th__hint .hint-text`)

    //     // console.log($hintText);
    //     // $hintText.style.display = 'none'
    // }

    // ----------------- BUTTON CLICK START ---------------------
    const handleButtonClick = async ({url, popup_title, feedback}) => {
        // console.log(url, popup_title);
        try {
            const response = await axios.get(url)
            // console.log(response.data);
            if (feedback) {  // IT MEANS THAT WE DO NOT NEED TO SHOW THE POPUP INSTEAD WE SHOW FEEDBACK
                setFeedbackInfo({ type: 'success', message: response.data.message || 'Success' })
                setShowFeedback(true)
                history.goBack()
                // setTimeout(() => {
                // }, 2000)
                return
            }
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: popup_title[0].toUpperCase() + popup_title.substring(1),
            })
        } catch (error) {
            console.log(error.message);
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })
            if (feedback) {
                setFeedbackInfo({ type: 'danger', message: error.message || 'Error!' })
                setShowFeedback(true)
            }
        }
    }

    const [sendRequest, setSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [sendRequestImmediately, setSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
        isOutsideChildrenClicked: null,
        row_index: null,
        child_index: null,
    })
    
    useDebounce(() => {
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, isOutsideChildrenClicked, row_index, child_index, accessor } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                if (tableType === 'body' && ['inst_campaign', 'inst_date_from', 'inst_date_to', 'inst_pay_type', 'inst_cost_api_contract'].includes(accessor)) {
                    getBody && getBody()
                    response.data.hasOwnProperty('updated_values') && updateTableHead(response.data.updated_values.avr_sum_vat)
                    return
                } 
                // console.log(response.data);
                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                    // setRows(rows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row))
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row)
                    // setRows(rows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row))
                }
                // UPDATED VALUES CHECK
                if (response.data.hasOwnProperty('updated_values')) {
                    updateTableHead(response.data.updated_values.avr_sum_vat)
                    if (response.data.updated_values.hasOwnProperty('avr_set_sum_vat')) updatedRows[row_index].avr_set_sum_vat.value = response.data.updated_values.avr_set_sum_vat 
                    if (response.data.updated_values.hasOwnProperty('avr_set_price')) updatedRows[row_index].avr_set_price.value = response.data.updated_values.avr_set_price                 
                }
                // console.log(updatedRows);
                // setRows(updatedRows)
                setTable({ ...table, rows: updatedRows})
                // setLoading(false)
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 500, [sendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        console.log('Send Request Immediately...');
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, isOutsideChildrenClicked, row_index, child_index, accessor } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                if (tableType === 'body' && ['inst_campaign', 'inst_date_from', 'inst_date_to', 'inst_pay_type', 'inst_cost_api_contract'].includes(accessor)) {
                    getBody && getBody()
                    response.data.hasOwnProperty('updated_values') && updateTableHead(response.data.updated_values.avr_sum_vat)
                    return
                } 
                // console.log(response.data);
                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                    // setRows(rows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row))
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row)
                    // setRows(rows.map((row, i) => i === row_index ? {...row, children: row.children.map((child, i) => i === child_index ? {...response.data} : child)} : row))
                }
                // UPDATED VALUES CHECK
                if (response.data.hasOwnProperty('updated_values')) {
                    updateTableHead(response.data.updated_values.avr_sum_vat)
                    if (response.data.updated_values.hasOwnProperty('avr_set_sum_vat')) updatedRows[row_index].avr_set_sum_vat.value = response.data.updated_values.avr_set_sum_vat 
                    if (response.data.updated_values.hasOwnProperty('avr_set_price')) updatedRows[row_index].avr_set_price.value = response.data.updated_values.avr_set_price                 
                }
                // console.log(updatedRows);
                // setRows(updatedRows)
                setTable({ ...table, rows: updatedRows})
                // setLoading(false)
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 0, [sendRequestImmediately])  // SEND REQUEST IMMEDIATELY
    // console.log(rows);

    const getOptions = (accessor, dataToSend, isOutsideChildrenClicked=false) => {
        // console.log(dataToSend);
        return new Promise(async (resolve, reject) => {
            try {
                const { get_url } = isOutsideChildrenClicked ? headers[accessor] : headers['children'][accessor]
                // console.log(get_url);
                const response = await axios.post(get_url, dataToSend)
                // console.log(response.data);
                const options = response.data
                resolve(options)
                // resolve([1,3,4,5,6,7])
            } catch (error) {
                console.log(error.message);
                // renderFeedback('danger', error.response.data.message)
                reject(error)
            }
        })
    }
    
    const handleFieldChange = (event, row_index, isOutsideChildrenClicked=false, child_index) => {
        console.log(document.activeElement);
        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT
        let dataToSend
        let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        if (isOutsideChildrenClicked) {
            dataToSend = rowsCopy[row_index]
            delete dataToSend.children
        } else {
            dataToSend = rowsCopy[row_index].children[child_index]
        }
        dataToSend[event.target.name].value = event.target.value
        if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2
        
        let url = tzType === 'billing' 
            ? `${headers['children'][event.target.name].set_url}`  // FOR BILLING NO NEED TO SEND "?tz_set_id=${parent_request_id}"
            :  isOutsideChildrenClicked // NOW WE ARE DEALING WITH "TZ KPI"
                ? `${headers[event.target.name].set_url}` 
                : `${headers['children'][event.target.name].set_url}`
    

        useDebounceInfo.current = {
            url,
            dataToSend,
            isOutsideChildrenClicked,
            row_index,
            child_index,
            accessor: event.target.name
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setSendRequestImmediately(!sendRequestImmediately)
        } else {
            setSendRequest(!sendRequest)
        }
    }

    const handleRateIconClick = (child_index, child, row_index, row, isEditableRateCell=true) => {
        if (location.pathname.includes('view') || !isEditableRateCell) return
        useDebounceInfo.current = {
            url: parentComponent === 'avr' ? `avrs/${tableType}/set/inst_rate_default/${head_request_id}/` : 'tzs/kpi/set/inst_rate_default/' ,
            dataToSend: child,
            isOutsideChildrenClicked: false,
            row_index,
            child_index,
        }

        setSendRequestImmediately(!sendRequestImmediately)
    }

    const handleIconButtonClick = async (icon_accessor, child_index, child, row_index, row, isEditable) => {
        if (!Boolean(isEditable)) return // NOT EDITABLE
        //    console.log(icon_accessor);
        if (icon_accessor === 'add') {
            try {
                setLoading(true)
                let url
                if (parentComponent === 'avr') {
                    const parent_request_id = row.request_id.value
                    url = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                } else {
                    url = `tzs/${tzType}/add/inst/`
                }
                const response = await axios.post(url)
                const newChild = response.data
                // setRows(rows.map((row, i) => i === row_index ? {...row, children: [...row.children, newChild]} : row))
                setTable({ ...table, rows: table.rows.map((row, i) => i === row_index ? {...row, children: [...row.children, newChild]} : row)})
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'fill') {
            // console.log(icon_accessor);
            try {
                if (tzType === 'kpi') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?tz_set_id=${row.request_id.value}`)
                    console.log(response.data);
                    setPopupFill({
                        type: 'kpi',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?tz_set_id=${row.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                } else if (tzType === 'billing') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?inst_id=${child.request_id.value}`)
                    console.log(response.data);
                    setPopupFill({
                        type: 'billing',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?inst_id=${child.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                }
                
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'remove') {
            // console.log(icon_accessor);
            try {
                setLoading(true)
                let url
                if (parentComponent === 'avr') {
                    const parent_request_id = row.request_id.value
                    url = `avrs/${tableType}/remove/row/?avr_set_id=${parent_request_id}`  // FOR FOOT
                } else {
                    url = tzType === 'kpi' ? `tzs/kpi/remove/inst/?tz_set_id=${row.request_id.value}&length=${table.rows[row_index].children.length}` : `tzs/billing/remove/inst/`  // FOR BILLING NO NEED TO SEND "?tz_set_id=${row.request_id.value}"
                }

                const response = await axios.post(url, child)
                let rowsCopy = JSON.parse(JSON.stringify(table.rows))
                if (rowsCopy[row_index].children.length === 1) rowsCopy = rowsCopy.filter((_, i) => i !== row_index)
                else rowsCopy[row_index].children = rowsCopy[row_index].children.filter((_, i) => i !== child_index)
                // setRows(rowsCopy)
                setTable({ ...table, rows: rowsCopy })
                if (response.data.hasOwnProperty('updated_values')) {
                    updateTableHead(response.data.updated_values.avr_sum_vat)
                }
                
                renderFeedback('success', response.data.message || 'Success')
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')

            } finally {
                setLoading(false)
            }

        } else if (icon_accessor === 'open') {
            console.log(icon_accessor);
            const url = `tzs/kpi/set/open/?inst_id=${child.request_id.value}&inst_type=${child.inst_type.value}`
            
            try {
                // setLoading(true)
                const response = await axios.post(`tzs/kpi/get/open/`, child)
                // console.log(response.data);
                let { data } = response
                if (location.pathname.includes('view')) {
                    for (let accessor in data) {
                        if (accessor === 'targetings') {
                            for (let tAccessor in data['targetings']) {
                                data['targetings'][tAccessor].editable = true
                            }
                        } else {
                            data[accessor].editable = true
                        }
                    }
                }
                // console.log(data);  // IF VIEW EDITABLE IS SET TO FALSE IN FRONT
                setPopup({
                    type: 'popup2',
                    visible: true,
                    data,
                    url,
                    title: 'Targetings',
                    // callback: refreshTableBottomData // WAS REMOVED INTENTIALLY AS WE ONLY NEED TO CLOSE THE POPUP NOT TO UPDATE THE TABLE
                    button: {
                        visible: !location.pathname.includes('view'),
                        text: 'Отправить'
                    },
                    targetingsEditable: !location.pathname.includes('view')
                })     
            } catch (error) {
                // console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'edit') {
            try {
                if (parentComponent === 'avr' && tableType === 'body') {
                    const urlForEditBtn = `avrs/body/get/edit/`  // BOTH FOR BODY AND FOOT
                    const response = await axios.post(urlForEditBtn, row)
                    setSwipeUpData({
                        table: response.data,
                        buttons: [{ text: 'Изменить', 
                                    icon: 'plus', 
                                    async onClick(newRows) {  // THERE ARE SECOND AND THIRD PARAMETERS AS WELL "table, columnAccessors"
                                        try {
                                            const parent_request_id = row.request_id.value
                                            const response = await axios.post(`avrs/body/set/edit/?avr_set_id=${parent_request_id}`, newRows)
                                            console.log(response.data);
                                            
                                            setSwipeUpData({ table: null, buttons: []})
                                            setShowSwipeUp(false)
                                            refreshTableBottomData()
                                            renderFeedback('success', response.data.message || 'Success')   
                                            
                                            if (response.data.hasOwnProperty('updated_values')) {
                                                updateTableHead(response.data.updated_values.avr_sum_vat)
                                            }
                                        } catch (error) {
                                            renderFeedback('danger', error.response?.data?.message || 'Server Error')    
                                        }
                                    } 
                                }]
                    })
                    
                    setShowSwipeUp(true)
                   
                    // else { // AVR FOOT
                    //     const parent_request_id = response.data.request_id.value
                    //     const urlForAddChildBtn = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                    //     const response_child = await axios.post(urlForAddChildBtn) 
                    //     setRows([...rows, {...response.data, children: [{...response_child.data}]}])
                    // }
                }
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || error.message || 'Server Error')
            } 
        }
    }
    // console.log('TABLE TZ BOTTOM ROWS: ', rows);

    const addTzSet = async () => {
        // console.log(head_request_id);
        try {
            if (parentComponent === 'avr') {
                const urlForAddSet = `avrs/${tableType}/add/?avr_id=${head_request_id}`  // BOTH FOR BODY AND FOOT
                const response = await axios.get(urlForAddSet)
                
                if (tableType === 'body') {  // AVR BODY
                    setSwipeUpData({
                        table: response.data,
                        buttons: [{ 
                            text: 'Создать', 
                            icon: 'plus', 
                            async onClick(newRows) {  // THERE ARE SECOND AND THIRD PARAMETERS AS WELL "table, columnAccessors"
                                try {
                                    const response = await axios.post(`avrs/${tableType}/add/?avr_id=${head_request_id}`, newRows)
                                    console.log(response.data);
                                    
                                    setSwipeUpData({ table: null, buttons: []})
                                    setShowSwipeUp(false)
                                    refreshTableBottomData()
                                    renderFeedback('success', response.data.message || 'Success')    

                                    if (response.data.hasOwnProperty('updated_values')) {
                                        updateTableHead(response.data.updated_values.avr_sum_vat)
                                    }
                                } catch (error) {
                                    console.log(error.message);
                                    renderFeedback('danger', error.response?.data?.message || 'Server Error')    
                                }
                            } 
                        }]
                    })
                    
                    setShowSwipeUp(true)
                } else { // AVR FOOT
                    const parent_request_id = response.data.request_id.value
                    const urlForAddChildBtn = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                    const response_child = await axios.post(urlForAddChildBtn) 
                    // setRows([...rows, {...response.data, children: [{...response_child.data}]}])
                    setTable({ ...table, rows: [...table.rows, {...response.data, children: [{...response_child.data}]}] })
                }
            } else if (tzType === 'kpi') { // KPI NEEDS DIFFERENT URL
                const urlForTzSet = `tzs/${tzType}/add/tz_set/?tz_id=${head_request_id}`
                const urlForAddBtn = `tzs/${tzType}/add/inst/`
                
                const request_tz_set = axios.post(urlForTzSet)
                const request_add_btn = axios.post(urlForAddBtn)
                const [response_tz_set, response_add_btn] = await axios.all([request_tz_set, request_add_btn])
                // console.log(response_tz_set.data, response_add_btn.data);
                // const child = response.data
                // setRows([...rows, {...response_tz_set.data, children: [{...response_add_btn.data}]}])
                setTable({ ...table, rows: [...table.rows, {...response_tz_set.data, children: [{...response_add_btn.data}]}] })

                // console.log(headers);
                // console.log();
            } else {  // BILLING NEEDS DIFFERENT URL
                const urlForAddBtn = `tzs/${tzType}/add/inst/?tz_id=${head_request_id}`
                const response_add_btn = await axios.post(urlForAddBtn)
                // console.log(response_add_btn.data);
                // const child = response.data
                // setRows([...rows, { children: [{...response_add_btn.data}] } ])
                setTable({ ...table, rows: [...table.rows, { children: [{...response_add_btn.data}] } ] })
            }
            
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data?.message || error.message || 'Server Error')
        } 
    }

    const getChildPart = (child_idx, child, row_idx, row) => {
        return Object.keys(headers['children']).map(child_accessor => {
            if (!headers['children'][child_accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
            
            const {value, type, editable, sign, color} = child[child_accessor]
            if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                // const isCellClickable = headers['children'][child_accessor].label === 'icon'
                const Icon = tableIcons[value]
                return (
                    <td key={child_accessor} className='table__td' style={{cursor: editable ? 'pointer' : 'default'}}>
                        <div className={`table__td__center ${child_accessor}`} style={{justifyContent: 'center'}} onClick={() => handleIconButtonClick(child_accessor, child_idx, child, row_idx, row, editable)}>
                            {child_accessor === 'inst_status' 
                                ? <PopupField name={child_accessor} value={value} editable={editable} rowClickable={table.clickable} sign={sign} popupField={{ type: 'status_icon_field', title: headers['children'][child_accessor].label}} getOptions={() => getOptions(child_accessor, child)} onChange={(event) => handleFieldChange(event, row_idx, false, child_idx)} >
                                    <Icon style={{position: 'absolute', width: '24px'}}/>
                                  </PopupField> 
                                : <span className={`svg-container`} data-editable={editable}><Icon /></span>}
                        </div>
                    </td>
                )
            }

            if (editable) {
                const FormField = formFields[type]  // MEANS THAT THIS IS NOT OF ICON TYPE, THUS RETURN FORM-FIELD
                return (
                    <td key={child_accessor} className="table__td" style={{ background: color }}>
                        <div className={`table__td__center ${child_accessor}`} >
                            {type === 'boolean_field' ? value === 0 ? 'Нет' : 'Да' : <>
                            <FormField 
                                parentComponent='PopupFillBilling' 
                                name={child_accessor} 
                                value={value} 
                                editable={editable} 
                                rowClickable={table.clickable} 
                                sign={sign} 
                                popupField={{ type, title: headers['children'][child_accessor].label}} 
                                getOptions={() => getOptions(child_accessor, child)} 
                                onChange={(event) => child_accessor === 'request_id' ? {} : handleFieldChange(event, row_idx, false, child_idx)} 
                            />  
                            {child_accessor === 'inst_rate' && <button style={{marginLeft: '0.3rem', cursor: !editable && 'default'}} onClick={() => handleRateIconClick(child_idx, child, row_idx, row, editable)}><Coin1 /></button>}
                            </>
                            }
                        </div>
                    </td>
                )
            }

            return (
                <td key={child_accessor} className="table__td" style={{ background: color }}>
                    <div className={`table__td__center ${child_accessor}`} dangerouslySetInnerHTML={{ __html: formatValue(value, type, sign)}}>
                        {/* {formatValue(value, type, sign)} */}
                    </div>
                </td>
            )
        })
    }


    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        return (<>
            {table.rows.map((row, idx) => {
                return row['children'].map((child, index) => {
                    if (index === 0) {  // IF THIS IS THE FIRST ROW THEN ADD OTHER TDs WITH ROWSPAN = CHILDREN.LENGTH
                        return  (  // RETURN THE FIRST CHILD && EVERYTHING OUTSIDE THE CHILDREN OBJECT
                            // <tr key={idx} className={`table__row ${table.clickable && 'clickable'}`} onClick={() => table.clickable ? handleRowClick(row) : {}}> 
                            <tr key={index} className={`table__row ${table.clickable && 'clickable'}`} > 
                                {columnAccessors.map(accessor => {
                                    if (accessor === 'children') return getChildPart(index, child, idx, row)

                                    // -------- EVERYTHING OUTSIDE THE CHILDREN OBJECT  // TDs WITH ROWSPAN START --------
                                    if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                    
                                    const {value, type, editable, sign, color} = row[accessor]
                                    if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                                        // const isCellClickable = headers[accessor].label === 'icon'
                                        const Icon = tableIcons[value]
                                        return (  // THIS IS WHERE add BUTTON IS 
                                            <td key={accessor} rowSpan={row['children'].length} className='table__td' style={{cursor: editable ? 'pointer' : 'default'}} onClick={() => handleIconButtonClick(accessor, index, child, idx, row, editable)}>
                                                <div className={`table__td__center ${accessor}`} style={{justifyContent: 'center'}} >
                                                    <span className="svg-container"><Icon /></span>
                                                </div>
                                            </td>
                                        )
                                    }

                                    if (editable) {
                                        const FormField = formFields[type]
                                        return (
                                            <td key={accessor} className="table__td" rowSpan={row['children'].length} style={{ background: color }}>
                                                <div className={`table__td__center ${accessor}`} >
                                                    {type === 'boolean_field' 
                                                    ?
                                                    value === 0 ? 'Нет' : 'Да'
                                                    :
                                                    <FormField 
                                                        name={accessor} 
                                                        value={value} 
                                                        editable={editable} 
                                                        rowClickable={table.clickable} 
                                                        sign={sign} 
                                                        popupField={{ type, title: headers[accessor].label}}  
                                                        getOptions={() => getOptions(accessor, row, 'outsideChildrenClicked')} 
                                                        onChange={(event) => handleFieldChange(event, idx, 'outsideChildrenClicked')} 
                                                    />  
                                                    }
                                                {/* helperText="Выберите все необходимые варианты */}
                                                </div>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td key={accessor} className="table__td" rowSpan={row['children'].length} style={{ background: color }}>
                                            {/* <div className={`table__td__center ${accessor}`} >
                                                {formatValue(value, type, sign)}
                                            </div> */}
                                            <div style={{flex: '1'}} dangerouslySetInnerHTML={{ __html: formatValue(value, type, sign, table.is_round) }}>
                                                {/* {value === SPECIAL_NUMBER ? '' : formatValue(value, type, sign)} */}
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    }
                    
                    // NOW RETURN THE OTHER ROWS WITH THE REST CHILDREN ELEMENTS, WITHOUT TDs WITH ROWSPAN 
                    // CHILDREN FROM INDEX 1 THROUGH END: children[1, last]
                    return  (
                        <tr key={index} className={`table__row ${table.clickable && 'clickable'}`} > 
                        {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                            {getChildPart(index, child, idx, row)}
                        </tr>
                    )
                })
            })}
            {/* WHEN CREATING NEW TZ, RE */}
            {/* {table.add_set && 
            <tr className={`table__row ${table.clickable && 'clickable'}`} > 
                <td className="table__td table__td__add-tz-set-btn" style={{cursor: editable ? 'pointer' : 'default'}} colSpan={memoizedColSpanValue} onClick={addTzSet}>
                    <div className={`table__td__center`} style={{justifyContent: 'center'}}>
                        {tzType === 'kpi' ? 'Добавить набор инструментов' : 'Добавить инструмент'}  
                    </div>
                </td>
            </tr>
            } */}
        </>)
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------
    
    
    // ----------------- RENDER TABLE HEADER START ---------------------

    if (loading) return <LoaderComponent />

    return ( <>
        <div className="table-block table-tz-block">
        <div className="table-caption-container" ref={tableCaptionContainerRef}>
            <span className="table-caption">{table.title}</span>
            {buttons && <div className="btns-container">
            {buttons.map((button, index) => {
                return <button key={index} className="button button-2" onClick={() => handleButtonClick(button)}>
                    <span className="svg-container">{buttonIcons[button.icon]}</span> 
                    <span>{button.text}</span>
                </button>
            })}
            </div>}
            {table.add_set && <div className="btns-container">
            <button className="button button-2" onClick={addTzSet}>
                <span className="svg-container">{buttonIcons['plus']}</span> 
                <span>{tzType === 'billing' ? 'Добавить инструмент' : 'Добавить набор инструментов'}</span>
            </button>
            </div>}
        </div>
        {/* <div className="table-container" ref={tableContentRef} style={{ maxHeight: '500px' }}> */}
        <div className="table-container" ref={tableContentRef} >
        {/* <div className="table-container" style={{height: `${tableContainerHeight}px`}} ref={tableContentRef}> */}
        <table className="table">
            <thead className="table__thead">
                <tr>
                    {columnAccessors.map(accessor => {
                        if (accessor === 'children') {
                            const children = headers[accessor]
                            return Object.keys(children).map(accessor => {
                                if (!children[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                
                                const { label, hint, width } = children[accessor]

                                if (label === 'icon') {
                                    let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                    return (
                                        <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                             <div className={`table__th__center ${accessor}`} style={{ justifyContent: 'center' }}>
                                                <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                                <HintTable hint={hint}/>
                                            </div>
                                        </th>
                                    )
                                }

                                return (
                                    <th key={accessor} className="table__th" style={{width: '1%', minWidth: accessor !== 'inst_status' && width}}>
                                        <div className={`table__th__center ${accessor}`} style={{ padding: accessor !== 'inst_status' && '0 8px' }}>
                                            <span className="table__th__value" >{label}</span>
                                            {/* <div className="table__th__hint" onMouseOver={() => displayHint(accessor)} onMouseLeave={() => hideHint(accessor)}> */}
                                                {/* <span><BsQuestionSquare style={{display: 'block', marginLeft: 'auto'}} /></span> */}
                                                {/* <div className="hint-text" style={{width: width - 6 || '100px'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, fugit?</div> */}
                                            {/* </div> */}
                                            {/* {!!hint && <div className="table__th__hint"> */}
                                            <HintTable hint={hint}/>
                                        </div>
                                    </th>
                                )
                            })
                        }
                        
                        if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        
                        const { label, hint, width } = headers[accessor]

                        if (label === 'icon') {
                            let Icon = accessor === 'add' ? tableIcons['tools'] :  tableIcons[accessor]
                            
                            return (
                                <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                     <div className={`table__th__center ${accessor}`} style={{ justifyContent: 'center' }}>
                                        <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                        <HintTable hint={hint}/>
                                    </div>
                                </th>
                            )
                        }
                        return (
                            // <th key={idx} className={idx === sortedColumnIndex ? `table__th ${sortOrder}` : "table__th"} onClick={() => sort(idx, header.type)}>
                            <th key={accessor} className="table__th" style={{ width: '1%', minWidth: width || '200px'}}>
                                <div className={`table__th__center ${accessor}`}>
                                    <span className="table__th__value" >{label}</span>
                                    {/* <button className="svg-container table__th__unfold" onClick={(event) => lengthenColumn(event, idx)}>{unfolded[idx] ? <BsArrowLeft /> : <BsArrowRight />}</button> */}
                                    <HintTable hint={hint}/>
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {getTableBody()}
            </tbody>
        </table>
        </div>
        </div>
    
        {tableType === 'body' && <SwipeUp showSwipeUp={showSwipeUp} setShowSwipeUp={setShowSwipeUp} swipeUpData={swipeUpData} setSwipeUpData={setSwipeUpData}/>}
                    
    </>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}

export default TableTzBottom
