import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import {ReactComponent as ArrowToLeft} from '../../../../assets/sidebar-icons/arrow.svg'
import {ReactComponent as ArrowOpen} from '../../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../../assets/sidebar-icons/arrow-close.svg'
import { useGlobalContext } from '../../../../store/context/context'
import './topbar.css'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router';

// const topbarLinks = [
//     'Ожидает НОК',
//     'Отклонено НОК',
//     'В работе',
//     'Активно (все)',
//     'Активно (частично)',
//     'Приостановлено',
// ]


const Topbar = () => {
    // const location = useLocation()
    const history = useHistory()        //  ------------- YOU CAN USE history.go(n) FOR BREADCRUMBS -------------
    // console.log(location.pathname);
    const { topbarData: {links, sublinks}, activeTopbarLink, breadcrumbs, activeTopbarSubLink } = useGlobalContext()  

    const handleLinkClick = async (value, url) => {
        // setActiveTopbarLink(value)
        console.log('TOPBAR: ', url);
        history.push(url)
    }

    const handleNavLinkClick = (event, isLastLink=false) => {  // OTHERWISE BROWESER ROUTE WILL END WITH /undefined (i.e. localhost:4000/undefined) AS PARENT LINK HAS NO LINK KEY
        if (isLastLink) return event.preventDefault()
    }

    return (
        <div className="topbar">
            <div className="topbar__top">
                <div className="topbarCenter">
                    <div>
                        <ul className="topbar__top__links">
                            {links.map((link, index) => {
                                const { value, url, badge } = link
                                return <li key={index} className={url.includes(activeTopbarLink) ? "topbar__top__link active" : "topbar__top__link"} onClick={() => handleLinkClick(value, url)}>
                                    {value}
                                    {!!badge && <p className='topbar__top__link__badge'>{badge}</p>}
                                </li>
                            })}
                        </ul>
                        {sublinks !== null ? 
                        sublinks.length ? <ul className="topbar__top__links">
                            {sublinks.map((link, index) => {
                                const { value, url } = link
                                // return <li key={index} className={url.includes(activeTopbarSubLink) ? "topbar__top__link active" : "topbar__top__link"} onClick={() => handleLinkClick(value, url)}>
                                return <li key={index} className={activeTopbarSubLink.includes(url) ? "topbar__top__link active" : "topbar__top__link"} onClick={() => handleLinkClick(value, url)}>
                                    {value}
                                </li>
                            })}
                        </ul> : <li className={"topbar__top__link"}>Статусы отсутствуют </li>
                        : null}
                    </div>
                    {/* {client && <>
                        <div className="topbar__top__client">
                            <span className="topbar__top__client__name">{client.name}</span>
                            <button className="svg-container">{showPopup ? <ArrowClose className="yellow"/> : <ArrowOpen className="yellow"/>}</button>
                        </div>
                        {showPopup && 
                        <div className="topbar__popup-background">
                            <div className="topbar__popup-center">
                                    {client.data?.map((row, index) => {
                                        return <div className="topbar__popup-row">
                                            <p className="topbar__popup-row__key">{row.key}</p>
                                            <p className="topbar__popup-row__value">{row.value}</p>
                                        </div>
                                    })}
                                    <button className="button button-2 topbar__popup-btn">Редактировать</button>
                            </div>
                        </div>
                        }
                    </>} */}
                    <Link to='' className="topbar__top__logo">
                        <span className="topbar__top__logo__yellow">TDS</span>
                        <span className="topbar__top__logo__white">Bid</span>
                    </Link>
                </div>
            </div>
            <div className="topbar__bottom">
                <div className="topbarCenter">
                    <ul className="topbar__bottom__browser-history">
                        {breadcrumbs.map((breadcrumbObj, index) => {
                            const {path, breadcrumb} = breadcrumbObj
                            return (
                            <li key={index} className="topbar__bottom__history">
                                <span className="topbar__bottom__history__text">
                                    <NavLink to={path} onClick={(event) => handleNavLinkClick(event, index === breadcrumbs.length - 1)}>{breadcrumb}</NavLink>
                                </span>
                                {index !== breadcrumbs.length - 1 && <span className="topbar__bottom__history__arrow svg-container"><ArrowToLeft className="grey"/></span>}
                            </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {/* <div className="topbar__bottomm">

            </div> */}
        </div>
    )
}

export default Topbar
