import React, { useState } from 'react'
import './text.css'
import {ReactComponent as Search} from '../../../assets/sidebar-icons/search.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useGlobalContext } from '../../../store/context/context'
import { TextareaAutosize } from '@material-ui/core'


const TextFieldTextArea = ({value='', onChange, type="text", label, name, parentForm, editable=true, required, rowClickable=false, handleValueRemoveButtonClick}) => {
    const { showFeedback, popup } = useGlobalContext()
    const [passwordOrText, setPasswordOrText] = useState(type)
    
    // console.log(value);
    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    const handleFormFieldClick = event => {
        // event.preventDefault()
        rowClickable && event.stopPropagation()
        // console.log('inside', event.target);
    }
    return (
        <div className={`text form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label>
            {parentForm === 'multi_select' ? <>
            {/* <input name={name} required type={type} id={name} disabled={!editable} className="text__input" value={value} onChange={onChange} style={{paddingRight: "40px"}} /> */}
            <div className="multi-select__values">{value?.map(singleOption => (
            // HERE value AN ARRAY OF SELECTED OPTIONS
                <div className="multi-select__value-container">
                    <div className="multi-select__value-text">{singleOption}</div>
                    <div className="multi-select__value-remove-button" onClick={(event) => {event.stopPropagation(); handleValueRemoveButtonClick(singleOption)}}>X</div>
                </div>
            ))}
            </div>  
            </>
            : parentForm === 'select' ? 
            <input autoComplete="off" name={name} required type={type} id={name} disabled={!editable} className="text__input" value={value} onChange={onChange} style={{borderColor: editable && required && !value && showFeedback && popup.visible ? 'red' : '', paddingRight: editable ? '40px' : ''}} />
            : parentForm === 'date' ?
            <input autoComplete="off" name={name} required type={type} id={name} disabled={!editable} className="text__input" value={value} style={{borderColor: editable && required && !value && showFeedback && popup.visible? 'red' : ''}} readOnly/>
            :  // DEFAULT
            <>
            <TextareaAutosize
                aria-label="empty textarea"
                //   placeholder="Empty"
                // value={value}
                value={value || ''}  // TO PREVENT: Warning: `value` prop on `textarea` should not be null  // 
                // onChange={onChange}
                onChange={onChange}
                style={{ resize: 'none', borderColor: editable && required && !value && showFeedback && popup.visible ? 'red' : '' }}
                className="text__input"
                disabled={!editable}
                name={name}
                id={name} 
                onFocus={() => setFocused('focused')} 
                onBlur={() => setFocused(!!value ? 'focused filled' : '')} 
                autoComplete="off"
                type={passwordOrText}
            />
            {type === 'password' && 
            <span className="svg-container password__eye" tabIndex={-1} onClick={(e) => {e.preventDefault(); setPasswordOrText(passwordOrText === 'text' ? 'password' : 'text')}}>{passwordOrText === 'text' ? <AiOutlineEye /> :<AiOutlineEyeInvisible />}</span>}
        </>
            }
        </div>
    )
}

export default TextFieldTextArea
