import React, { useCallback, useEffect, useRef, useState } from 'react'
import Text from '../text/Text'
import './select.css'
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../assets/sidebar-icons/arrow-close.svg'
import { useGlobalContext } from '../../../store/context/context'
import SelectOptions from './SelectOptions'

export const SelectPositionedField = ({ 
        options: defaultOptions=[
            // {value: '', value_id: ''}
        ], 
        value='', 
        value_id, 
        label, 
        name, 
        onChange, 
        rowClickable, 
        editable=true, 
        required, 
        parent, 
        getOptions=null, 
        showOnlyUnselectedOptions=false, 
        deleteOptionEnabledInSelect=false 
    }) => {
    const [showOptionsList, setShowOptionsList] = useState(false)
    const [inputValue, setInputValue] = useState(value || '')
    const [selectedOption, setSelectedOption] = useState(value || '')
    const [searchText, setSearchText] = useState('')
    const selectRef = useRef(null)
    const formFieldTopRef = useRef(null)

    const [options, setOptions] = useState(defaultOptions)
    const [selectedOptions, setSelectedOptions] = useState([value])

    const isFirstRender = useRef(true)

    useEffect(() => {  // ADDED WHEN DOING TARGETINGS SELECT FIELD IN POPUP2   // BECAUSE, IN THERE WE NEED TO REMOVE THE SELECTED OPTIONS FROM THE OPTIONS LIST 
        // if (isFirstRender.current) return isFirstRender.current = false
        if (getOptions) return  // IN CASE WE ARE GETTING OPTIONS FROM THE SERVER WE UPDATE THE PARENT STATE WHICH UPDATES THE defaultOptions PROPS AND THIS LEADS TO INFINITE RE-RENDER. THUS IF THERE IS A getOptions WE KNOW FOR SURE THAT WE DON'T NEED TO UPDATE THE OPTIONS
        setOptions(defaultOptions)
    }, [defaultOptions, getOptions])


    const lowerCaseString = input => input.toString().toLowerCase()

    const { renderFeedback, feedbackInfo, showFeedback, SPECIAL_VALUES } = useGlobalContext()

    const handleClickOutside = useCallback(event => {
        if (!selectRef.current.contains(event.target)) {
            // console.log('Clicked outside select');
            // CLICKED OUTSIDE THE SELECT
            // THUS CLOSE OPTIONS DIV AND CLEAR SEARCH TEXT (so all options could be seen next time select gets opened)
            setShowOptionsList(false)
            setSearchText('')
            
            // // AND HANDLE INPUT FIELD VALUE, SEARCH TEXT VALUE AND LABEL STYLES
            const label = selectRef.current.querySelector('label')

            if (lowerCaseString(selectedOption)) {
                label.classList.add('focused', 'filled')
                setInputValue(selectedOption)
            } else {
                label.classList.remove('focused', 'filled')
                setInputValue('')
            }
        }
      }, [selectedOption]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])

    const selectOptionsRef = useRef(null)
    
    const transformFormFieldBottom = useCallback(() => {
        const { bottom, left, top, right, width } = formFieldTopRef.current.getBoundingClientRect()
        
        selectOptionsRef.current.style.display = 'block'
        selectOptionsRef.current.style.top = bottom + 'px'
        selectOptionsRef.current.style.left = left + 'px'
        selectOptionsRef.current.style.position = 'fixed'
        selectOptionsRef.current.style.minWidth = width + 'px';

        const heightOfOptionsUL = options.length * 26 > 280 ? 280 : options.length * 26  // 26px IS THE HEIGHT OF ONE <li></li>
        const widthOptions = selectOptionsRef.current.offsetWidth  // OFFSET_WIDTH RETURNS THE ACTUAL WIDTH OF THE UL THAN GETBOUNDINGCLIENTRECT
        const rightOptions = left + widthOptions
        
        const selecOptionsBottom = bottom + heightOfOptionsUL
     
        let windowBottom, windowRight
        windowBottom = window.innerHeight
        windowRight = window.innerWidth            
        if (windowRight < rightOptions) {
            selectOptionsRef.current.style.left = `${left - (rightOptions - windowRight) - 20}px`
        }
        
        if (windowBottom < selecOptionsBottom) {
            selectOptionsRef.current.style.bottom = `${windowBottom - top + 10}px`
            selectOptionsRef.current.style.top = `unset`
        }
    }, [options.length])
    useEffect(() => {
        showOptionsList ? transformFormFieldBottom() : selectOptionsRef.current.style.display = 'none'
    }, [showOptionsList, transformFormFieldBottom])

    const handleOptionClick = async option => {
        let event
        if (option.hasOwnProperty('value_id')) {
            event = {
                target: {
                    name, 
                    value: option.value,
                    value_id: option.value_id
                }
            }
        } else {
            event = {
                target: {
                    name, 
                    value: option
                }
            }
        }

        document.activeElement.name = name

        if (parent === "table-container") {  // WHEN CHANGING FIELDS INSIDE TABLE CELLS WITHOUT CHANGING REQUEST RIGHT AWAY, ADDING EMPLOYER'S BONUS year, month, bonus TABLE
            try {
                const { message } = await onChange(event)
                console.log(message);
                
                renderFeedback('success', message || 'Success')
               
                setShowOptionsList(false)
                setInputValue(event.target.value)
                setSelectedOption(event.target.value)
                setSearchText('')
        
                const label = selectRef.current.querySelector('label')
                label.classList.add('focused', 'filled')
            } catch (error) {
                console.log(error);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')

            }
        } else { // WHEN CHANGING IN POPUP COMPONENT
            onChange && onChange(event)
            setShowOptionsList(false)
            setInputValue(event.target.value)
            setSelectedOption(event.target.value)
            setSearchText('')
    
            const label = selectRef.current.querySelector('label')
            label.classList.add('focused', 'filled')
        }
    }

    const handleTextChange = event => {
        setInputValue(event.target.value)
        setSearchText(event.target.value)
    }

    const toggle = () => {
        const label = selectRef.current.querySelector('label')
        const input = selectRef.current.querySelector('input')

        if (!showOptionsList) {
            input.focus()
            label.classList.remove('focused', 'filled')
            label.classList.add('focused')
        } else {
            input.blur()
            
            setSearchText('')

            if (lowerCaseString(selectedOption)) {
                label.classList.add('focused', 'filled')
                setInputValue(selectedOption)
            } else {
                label.classList.remove('focused', 'filled')
                setInputValue('')
            }
        }

        setShowOptionsList(!showOptionsList)
    }

    const toggleFormFieldBottom = async event => {
        if (!editable) return

        formFieldTopRef.current = event.target.parentElement

        
        // toggleTableContainerOverflow()
        console.log(options.length);
        if (!options.length && getOptions && !showOptionsList) {
            try {
                // const options = await getOptions(name)
                const options = await getOptions()
                console.log(options);
                setOptions(options)
                toggle()
            } catch (error) {
                console.log(error.message);
                // renderFeedback('danger', error.response.data.message || 'Server Error')
            }
        } else {
            toggle()
        }

    }

    const removeSelectedOption = () => {
        const event = {
            target: {
                name, 
                value: '',
                value_id: SPECIAL_VALUES.DELETE_SELECTED_OPTION  // WAS REQUESTED BY NURA FOR UNSETTING SELECT FIELD
            }
        }
        document.activeElement.name = name

        const updateState = () => {  // THIS IS A CALLBACK, IF onChange() IS SUCCESSFULL, THIS GETS CALLED
            setInputValue(event.target.value)
            setSelectedOption(event.target.value)
            setSearchText('')
            setShowOptionsList(false)
        }
        onChange && onChange(event, updateState)
        // console.log(showFeedback, feedbackInfo.type);
        // if (showFeedback && feedbackInfo.type === 'danger') return

    }

    return (
        <div className={`select form-field ${editable ? 'editable' : 'uneditable'}`} ref={selectRef}>
            <div onClick={toggleFormFieldBottom} className="form-field__top" >
                <Text type="text" label={label} name={name} value={inputValue} onChange={handleTextChange} parentForm="select" rowClickable={rowClickable} editable={editable} required={required} />
                {!!editable && <button className="svg-container form-field__open-button" type="button">{showOptionsList ? <ArrowClose /> : <ArrowOpen />}</button>}
            </div>
            {
                <SelectOptions 
                    ref={selectOptionsRef} 
                    options={options || []} 
                    searchText={searchText} 
                    selectedOption={selectedOption} 
                    handleOptionClick={handleOptionClick} 
                    className="form-field__bottom form-field__bottom__fixed"
                    removeSelectedOption={removeSelectedOption}
                    deleteOptionEnabledInSelect={deleteOptionEnabledInSelect}
                />
            }
        </div>
    )
}