import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { LoaderComponent } from '../../components/layout/loader/Loader';
import Popup from '../../components/layout/popup/Popup';
import Table from '../../components/layout/table/Table';
import { TableV } from '../../components/layout/table/TableV';
import { useGlobalContext } from '../../store/context/context';
import './users-clients.css'

// import { useAxios } from '../../utils/js/useAxios';



export const UsersClients = () => {
    const { setTopbarData, setActiveTopbarLink, setBreadcrumbs, setActiveSidebarLink, setPopup, setShowFeedback, renderFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const { id } = useParams()
    const location = useLocation();
    const history = useHistory()

    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)
    
    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`users/${id}/`)
            setClient(response_client.data) 
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [id, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/`)  // `users/${id}/clients/`
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setErrorTable(error.response?.data|| 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])
    
    useEffect(() => {
        setActiveSidebarLink('users')
    }, [setActiveSidebarLink])
    
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])    

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users', breadcrumb: 'Пользователи' },
            { path: location.pathname, breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Добавить клиента',
            icon: 'plus',
            url: 'users/clients/new/',
            popup_title: 'Клиент',
            popup_callback() {
                getTable()
            }
        },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
    console.log(`users/clients/${request_id}/placements`);
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `/users/${id}/clients/${request_id}/placements`,

        })
    }
    // ----------------- ROW CLICK START ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        try {
            const url = `users/${id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать клиента',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    return (<>
        <div className='client page'>
            {loadingClient 
                ? <LoaderComponent /> 
                : errorClient 
                    ? <div>{errorClient.message}</div> 
                    : <ClientInfo inline client={client} editClient={editClient}/>}
                        {/* <ClientInfo client={client}/> */}
        </div>

        {loadingTable 
            ? <LoaderComponent /> 
            : errorTable 
                ? <div>{errorTable.message}</div> 
                : <TableV 
                    table={table} 
                    buttons={buttons.reverse()} 
                    handleRowClick={handleRowClick} 
                    refreshData={getTable}
                    href={(() => {
                        let href
                        switch (table.title) {
                            case 'Договоры':
                                href = null
                                break
                            case 'АВР':
                                href = {
                                    from: location.pathname,
                                    to: 'view'
                                } 
                                break
                            default:
                                href = {
                                    from: location.pathname,
                                    to: ''
                                } 
                        }
                        return href
                    })()
                    }
                />}
        </>
    )
}