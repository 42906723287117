import React, { useState } from 'react'
import './text.css'
import {ReactComponent as Search} from '../../../assets/sidebar-icons/search.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useGlobalContext } from '../../../store/context/context'


const TextField = ({value='', onChange, type="text", label, placeholder='', name, parentForm, editable=true, required=true, rowClickable=false, handleValueRemoveButtonClick}) => {
    const { showFeedback, popup } = useGlobalContext()
    const [passwordOrText, setPasswordOrText] = useState(type)
    
    // console.log(value);
    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    
    const handleFormFieldClick = event => rowClickable && event.stopPropagation()
    
    return (
        <div className={`text form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label>
            {parentForm === 'multi_select' ? <>
            <div className="multi-select__values">{value?.map(singleOption => (
            // HERE value AN ARRAY OF SELECTED OPTIONS
                <div className="multi-select__value-container">
                    <div className="multi-select__value-text">{singleOption}</div>
                    <div className="multi-select__value-remove-button" onClick={(event) => {event.stopPropagation(); handleValueRemoveButtonClick(singleOption)}}>X</div>
                </div>
            ))}
            </div>  
            </>
            : parentForm === 'select' ? 
            <input autoComplete="off" name={name} required={required} type={type} id={name} disabled={!editable} className="text__input" value={value} onChange={onChange} placeholder={placeholder} style={{borderColor: editable && required && !value && showFeedback && popup.visible ? 'red' : '', paddingRight: editable ? '40px' : ''}} />
            : parentForm === 'date' ?
            <input autoComplete="off" name={name} required={required} type={type} id={name} disabled={!editable} className="text__input" value={value} placeholder={placeholder} style={{borderColor: editable && required && !value && showFeedback && popup.visible? 'red' : ''}} readOnly/>
            :  // DEFAULT
            <>
            <input autoComplete="off" name={name} required={required} type={passwordOrText} id={name} disabled={!editable} className="text__input" value={value} onChange={onChange} onFocus={() => setFocused('focused')} onBlur={() => setFocused(!!value ? 'focused filled' : '')} placeholder={placeholder} style={{borderColor: editable && required && !value && showFeedback && popup.visible ? 'red' : ''}}/>
            {type === 'password' && 
            <span className="svg-container password__eye" tabIndex={-1} onClick={(e) => {e.preventDefault(); setPasswordOrText(passwordOrText === 'text' ? 'password' : 'text')}}>{passwordOrText === 'text' ? <AiOutlineEye /> :<AiOutlineEyeInvisible />}</span>}
        </>
            }
        </div>
    )
}

export default TextField
