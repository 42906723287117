import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import TableTzBottom from '../../components/layout/table_tz/TableTzBottom'
import TableTzTop from '../../components/layout/table_tz/TableTzTop'
// import Table from '../../components/layout/table/Table'
import { useGlobalContext } from '../../store/context/context'
import './tz.css'

export const Tz = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setPopupLogs, renderFeedback, setShowFeedback, setServerError, setActiveTopbarLink } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const { id, client_id, placement_id } = useParams()
    
    const location = useLocation()
    const history = useHistory()

    const tzMode = useMemo(() => location.pathname.includes('view') ? 'view' : 'edit', [location.pathname])
    const { activeSidebarLink, breadcrumbs, topbarData } = useMemo(() => {
        let activeSidebarLink = ''
        let breadcrumbs = []
        let topbarData = {}
        const url = location.pathname
        if (location.pathname.startsWith('/users/clients')) {
            // tzType = 'clientsTz'
            activeSidebarLink = 'users/clients'
            breadcrumbs = [
                { path: '/users/clients', breadcrumb: 'Клиенты' },
                { path: `/users/clients/${client_id}/placements`, breadcrumb: `Площадки` }, // i.e. /clients/id/brands/id ==>> /clients/id/brands
                { path: `${location.pathname}`, breadcrumb: `Площадка` }, 
            ] 

            topbarData = {
                links: [
                    {value: 'Блоки', url: location.pathname},
                    {value: 'Отчет по блокам', url: `${url.replace('tz/view', 'reports/blocks')}`},
                    {value: 'Отчет по дням', url: `${url.replace('tz/view', 'reports/main_days')}`},
                ],
                sublinks: null
            }
        } else if (location.pathname.startsWith('/users/placements')) {
            // tzType = 'placementsTz' 
            activeSidebarLink = 'users/placements'
            breadcrumbs = [
                { path: '/users/placements', breadcrumb: 'Новые площадки' },
                // { path: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`, breadcrumb: `Test Площадки` }, // i.e. /clients/id/brands/id ==>> /clients/id/brands
                { path: `${location.pathname}`, breadcrumb: `Площадка` }, 
            ]

            topbarData = {
                links: [
                    // {value: 'ТЗ', url: location.pathname}, 
                    // {value: 'Отчет', url: `${url.replace('tz/view', 'reports/blocks')}`}, 
                ],
                sublinks: null
            }
        } else {
            // tzType = 'usersTz'
            activeSidebarLink = 'users'
            breadcrumbs = [
                { path: '/users', breadcrumb: 'Пользователи' },
                { path: `/users/${id}/clients`, breadcrumb: "Клиенты пользователя" },
                { path: `/users/${id}/clients/${client_id}/placements`, breadcrumb: `Площадки` },  
                { path: `${location.pathname}`, breadcrumb: `Площадка` },  
            ]

            topbarData = {
                links: [
                    {value: 'ТЗ', url: location.pathname}, 
                    {value: 'Отчет', url: `${url.replace('tz/view', 'reports/blocks')}`}, 
                ],
                sublinks: null
            }
        } 
    
        return { activeSidebarLink, breadcrumbs, topbarData}
    }, [location.pathname, client_id, id])

    const [tableHead, setTableHead] = useState({})
    const [tableBody, setTableBody] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getTableHeadAndBody = useCallback(async () => {
        console.log('.......... getting table head and body ..........');
        try {
            setLoading(true)
            const request_table_head = axios.get(`/tzs/${placement_id}/${tzMode}/head/`)
            const request_table_body = axios.get(`/tzs/${placement_id}/${tzMode}/body/`)
            const [response_table_head, response_table_body] = await axios.all([request_table_head, request_table_body])
            setTableHead(response_table_head.data)
            setTableBody(response_table_body.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data?.message)
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
            if (error.response.status === 403) {
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }, [history, setLoading, renderFeedback, setServerError, placement_id, tzMode])
    
    useEffect(() => {
        getTableHeadAndBody()
    }, [location.pathname, getTableHeadAndBody])

    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink]) 
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])   

    useEffect(() => {
        setTopbarData(topbarData)
    }, [setTopbarData, topbarData])

    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])
   
    const buttons = useMemo(() => {
        let dynamicBtns = []
        if (tableHead.send) {  // ALERT  // LOADING
            dynamicBtns.push({
                text: `Отправить НОК`,
                icon: 'send',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/send/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                        history.push(path)
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь отправить данное ТЗ, продолжить?',
                },
            })
        }
        if (tableHead.accept) {  // LOADING
            dynamicBtns.push({
                text: `Подтвердить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/accept/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.decline) {  // LOADING
            dynamicBtns.push({
                text: `Отклонить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/decline/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.check) { // LOADING
            dynamicBtns.push({
                text: `Готово`,
                icon: 'confirm',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/check/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                        history.push(path)
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.archive) {  // ALERT  // LOADING
            dynamicBtns.push({
                text: `В архив`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/archive/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь заархивировать данное ТЗ, продолжить?',
                },
            })
        }
        if (tableHead.start) {  // LOADING
            dynamicBtns.push({
                text: `Возобновить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/start/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.pause) {  // LOADING
            dynamicBtns.push({
                text: `Приостановить`,
                icon: 'vector',
                // url: `${location.pathname}/data/new/`,
                // popup_title: `Добавить доп. расход`,
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/pause/`) 
                        // console.log(response.data);
                        const { message } = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.edit) {
            dynamicBtns.push({  // edit
                text: `Редактировать`,
                icon: 'edit',
                async onClickFunction() {
                    try {
                        
                        await axios.get(`/tzs/${placement_id}/edit/head/`)
                        // history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                        history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                    } catch (error) {
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    }
                },
            })
        }
        if (location.pathname.endsWith('/edit')) {  // EDIT
            const kpiEditStaticBtns = [
                {  // check
                    text: `Готово`,
                    icon: 'confirm',
                    async onClickFunction() {  // SEND TO VIEW
                        try {
                            // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`);
                            history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`)
                        } catch (error) {
                            renderFeedback('danger', error.response?.data?.message || 'Server Error')
                        }
                    },
                },
                /* {  // history
                     text: `История`,
                     icon: 'vector',
                     async onClickFunction() {
                         try {
                             const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`)
                             const { data, date_from, date_to } = response.data
                             // console.log(data);
                             setPopupLogs({
                                 visible: true,
                                 data,
                                 title: 'История изменений',
                                 url: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`,
                                 date_from,
                                 date_to
                             })
                         } catch (error) {
                             // console.log(error.message);
                             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                         }
                     },
                 },*/
                // {
                //     text: `Разархивировать`,
                //     icon: 'activate',
                //     async onClickFunction() {
                //         if (loading) return
                //         try {
                //             setLoading(true)
                //             const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/activate/`) 
                //             // console.log(response.data);
                //             const { message } = response.data
                //             renderFeedback('success', message || 'Success')
                //             const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                //             history.push(path)
                //         } catch (error) {
                //             // console.log(error.message);
                //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         } finally {
                //             setLoading(false)
                //         }
                //     },
                //     alert: {
                //         title: 'Внимание',
                //         body: 'Вы собираетесь разархивировать данное ТЗ, продолжить?',
                //     },
                // },
            ]
            return [...kpiEditStaticBtns, ...dynamicBtns]
        } 
        else {  // VIEW
            const kpiViewStaticBtns = [  // ON VIEW PAGE FOR BOTH KPI/BILLING
                {  // edit
                    text: `Редактировать`,
                    icon: 'edit',
                    async onClickFunction() {
                        try {
                            await axios.get(`/tzs/${placement_id}/edit/head/`)
                            // history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                            history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                        } catch (error) {
                            renderFeedback('danger', error.response?.data?.message || 'Server Error')
                        }
                    },
                },
                
                // {  // copy ALERT  // LOADING
                //     text: `Cкопировать`,
                //     icon: 'copy',
                //     async onClickFunction() {
                //         if (loading) return
                //         try {
                //             setLoading(true)
                //             // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/copy/`);
                //             const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/copy/`)
                //             // console.log(response.data);
                //             const { message, tz_id } = response.data
                //             const path = `/tzs/kpi/${tz_id}/edit`
                //             renderFeedback('success', message || 'Success')
                //             history.push(path)
                //         } catch (error) {
                //             // console.log(error.message);
                //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         } finally {
                //             setLoading(false)
                //         }
                //     },
                //     alert: {
                //         title: 'Внимание',
                //         body: 'Вы собираетесь скопировать данное ТЗ, продолжить?',
                //     },
                // },
                // {  // split  ALERT
                //     text: `Раздробить`,
                //     icon: 'parallel',
                //     async onClickFunction() {
                //         if (loading) return
                //         try {
                //             setLoading(true)
                //             // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/split/`);
                //             const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/split/`)
                //             // console.log(response.data);
                //             const { message } = response.data
                //             renderFeedback('success', message || 'Success')
                //             // const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                //             // history.push(path)
                //             getTableHeadAndBody()  // NEED TO BE TESTED
                //         } catch (error) {
                //             // console.log(error.message);
                //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         } finally {
                //             setLoading(false)
                //         }
                //     },
                //     alert: {
                //         title: 'Внимание',
                //         body: 'Вы собираетесь раздробить данное ТЗ, продолжить?',
                //     },
                // },
                /* {  // history
                    text: `История`,
                    icon: 'vector',
                    async onClickFunction() {
                        try {
                            const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`)
                            const { data, date_from, date_to } = response.data
                            console.log(data);
                            setPopupLogs({
                                visible: true,
                                data,
                                title: 'История изменений',
                                url: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`,
                                date_from,
                                date_to
                            })
                        } catch (error) {
                            // console.log(error.message);
                            renderFeedback('danger', error.response?.data?.message || 'Server Error')
                        }
                    },
                },*/
                // {
                //     text: `Разархивировать`,
                //     icon: 'activate',
                //     async onClickFunction() {
                //         if (loading) return
                //         try {
                //             setLoading(true)
                //             const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/activate/`)
                //             // console.log(response.data);
                //             const { message } = response.data
                //             renderFeedback('success', message || 'Success')
                //             // const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                //             // history.push(path)
                //             getTableHeadAndBody()  // NEED TO BE TESTED
                //         } catch (error) {
                //             // console.log(error.message);
                //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         } finally {
                //             setLoading(false)
                //         }
                //     },
                //     alert: {
                //         title: 'Внимание',
                //         body: 'Вы собираетесь разархивировать данное ТЗ, продолжить?',
                //     },
                // }
            ]
            return [...kpiViewStaticBtns, ...dynamicBtns]
        }
        // if (location.pathname.endsWith('/edit')) {  // EDIT
        //     const billingEditStaticBtns = [
                // {  // check
                //     text: `Готово`,
                //     icon: 'confirm',
                //     async onClickFunction() {  // SEND TO VIEW
                //         try {
                //             // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`);
                //             history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`)
                //         } catch (error) {
                //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         }
                //     },
                // },
        //         {  // history
        //             text: `История`,
        //             icon: 'vector',
        //             async onClickFunction() {
        //                 try {
        //                     const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`)
        //                     const { data, date_from, date_to } = response.data
        //                     setPopupLogs({
        //                         visible: true,
        //                         data,
        //                         title: 'История изменений',
        //                         url: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`,
        //                         date_from,
        //                         date_to
        //                     })
        //                 } catch (error) {
        //                     // console.log(error.message);
        //                     renderFeedback('danger', error.response?.data?.message || 'Server Error')
        //                 }
        //             },
        //         },
        //     ]
        //     return [...billingEditStaticBtns, ...dynamicBtns]
        // } else { // VIEW
        //     const billingViewStaticBtns = [
        //         {  // edit
        //             text: `Редактировать`,
        //             icon: 'edit',
        //             async onClickFunction() {
        //                 // history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
        //                 try {
        //                     await axios.get(`/tzs/${placement_id}/edit/head/`)

        //                     // history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
        //                     history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
        //                 } catch (error) {
        //                     renderFeedback('danger', error.response?.data?.message || 'Server Error')
        //                 }
        //             },
        //         },
        //         {  // history
        //             text: `История`,
        //             icon: 'vector',
        //             async onClickFunction() {
        //                 try {
        //                     const response = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`)
        //                     const { data, date_from, date_to } = response.data
        //                     console.log(data);
        //                     setPopupLogs({
        //                         visible: true,
        //                         data,
        //                         title: 'История изменений',
        //                         url: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/logs/`,
        //                         date_from,
        //                         date_to
        //                     })
        //                 } catch (error) {
        //                     // console.log(error.message);
        //                     renderFeedback('danger', error.response?.data?.message || 'Server Error')
        //                 }
        //             },
        //         },
        //     ]
        //     return [...billingViewStaticBtns, ...dynamicBtns]
        // }
    }, [location.pathname, renderFeedback, history, tableHead, getTableHeadAndBody, loading, placement_id, setPopupLogs])

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
        <div className="tz page">
        </div>

        {!loading && tableBody.hasOwnProperty('headers') && <>
            <TableTzTop table={tableHead} setTable={setTableHead} buttons={buttons} deleteOptionEnabledInSelect={true}/>
            <TableTzBottom table={tableBody} setTable={setTableBody} head_request_id={tableHead.rows[0].request_id.value} tzType={'TEST_TEST_tzType'} refreshTableBottomData={getTableHeadAndBody}/>
        </> }
        </>
    )
}