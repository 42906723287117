import React from 'react'
import './server_error.css'
import { RiRefreshLine } from 'react-icons/ri'
import { GoHome } from 'react-icons/go'
import { useGlobalContext } from '../../store/context/context'

export const ServerError = () => {
    const { setServerError } = useGlobalContext()
    return (
        <div className="server-error-page">
            <div className="server-error">
                {/*<h1 className="status-code">500</h1>*/}
                <h1 className="error-word">Ведутся технические работы</h1>
                <p className="feedback-message">Приносим извинения за временные неудобства!</p>
                <div className="btns-container">
                    <button className="refresh-btn btn" onClick={() => {document.location.reload()}}>
                        <span className="svg-container"><RiRefreshLine /></span>
                        <span>Обновить страницу</span>
                    </button>
                    <a href="/" className="home-btn btn" >
                        <span className="svg-container"><GoHome /></span>
                        <span>На главную</span>
                    </a>
                </div>
                {/* <p>Описание ошибки</p>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi voluptate explicabo quisquam neque ab, numquam tempore magnam doloremque possimus fuga.</p> */}
            </div>
        </div>
    )
}
