import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);


export const KrugChart = ({graph}) => {
    const data_values = []
    const data_names = []

    for(let i = 0; i < graph.rows.length-1; i++){
        if(graph.rows[i].report_revenue.value > 1){
            data_values.push({value:Math.round(graph.rows[i].report_revenue.value) ,})
            data_names.push(graph.rows[i].report_system.value,)
        }
    }

    const [dataf, setDataf] = useState(
        {
            labels: data_names,
            datasets: [
                {
                    label: '# of Votes',
                    data: data_values,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    )

    return(
        <>
            <div style={{width:'40%', height:'100%', float:"left", marginBottom:50}}>
                <Pie data={dataf}/>
            </div>
        </>
    );
}
