import React, { forwardRef } from 'react'
import './send-button.css'
import { AiOutlineSend } from 'react-icons/ai'
import { HintHeader } from '../../layout/hint/Hint'


const Button = forwardRef((props, ref) => {
    const { onClick } = props
    return <button ref={ref} className='send-button' onClick={onClick}>
        <AiOutlineSend />
    </button>
});

export const SendButton = ({ onClick }) => {
    return (
        <HintHeader hint='Отправить'>
            <Button onClick={onClick}/>
        </HintHeader>
    )
}

