import React from 'react'
import './radio.css'

import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

const RadioField = ({label, name, value = '', onChange, options, editable=true}) => {
    const handleOnChange = (event) => {
        onChange && onChange(event)
    }
    return (
        <div className={`radio form-field ${editable ? 'editable' : 'uneditable'}`}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup  row aria-label={name} name={name} value={String(value)} onChange={handleOnChange}>
                    {options.map(option => {
                        const { value, label, disabled } = option
                        return <FormControlLabel key={value} value={String(value)} disabled={!editable} control={<Radio disableRipple color="primary" size="small"/>} label={label}/>
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default RadioField