import React, { useCallback, useEffect, useState } from 'react'
import './new-sites.css'

import { useGlobalContext } from '../../store/context/context'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'

export const NewSites = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const history = useHistory()
    const location = useLocation()
    
    // const { response: table, error, loading } = useAxios('clients/')

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('users/placements/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])
   
    useEffect(() => {
        setActiveSidebarLink('users/placements')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users/placements', breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        // {
        //     text: 'Добавить клиента',
        //     icon: 'plus',
        //     url: 'users/clients/new/',
        //     popup_title: 'Клиент',
        //     popup_callback() {
        //         getTable()
        //     }
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        console.log(`/users/placements/${request_id}/tz/view`);
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `/users/placements/${request_id}/tz/view`,
            
        })
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
        <div className="clients page">
        </div>

        <TableV 
            table={table} 
            buttons={buttons.reverse()} 
            handleRowClick={handleRowClick} 
            refreshData={getTable} 
            href={{
                from: '/users/clients',
                to: 'placements'
            }}    
        />
        </>
    )
}