import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './popup.css'

import CloseButton from '../../buttons/close/CloseButton'
// import Radio from '../../forms/radio/Radio'
// import SelectField from '../../forms/select/Select_1'
import TextField from '../../forms/text/Text'
import TextFieldTextArea from '../../forms/text/TextTextArea'
import RadioField from '../../forms/radio/Radio'
import DateField from '../../forms/date/Date'
import NumberField from '../../forms/number/Number'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import axios from 'axios'
import { useGlobalContext } from '../../../store/context/context'
import MultiSelectField from '../../forms/multi_select/MultiSelect'

import { SelectField } from '../../forms/select/Select'
import { CheckboxTextareaField } from '../../forms/checkbox_textarea/CheckboxTextarea'
import { SelectTextField } from '../../forms/select_text/SelectText'
import { MdDelete } from 'react-icons/md'
import { AiFillDelete } from 'react-icons/ai'
import { SelectField3Num } from '../../forms/select/Select3Num'
import { LoaderComponent } from '../loader/Loader'
import DateNumberField from '../../forms/date/DateNumber'
import DateRangeField from '../../forms/date/DateRange'
import { SelectPositionedField } from '../../forms/select/SelectPositioned'

export const PopupTasks = () => {
    const { popupTasks: { data, url, callback, title, button: { visible = true, text = 'Отправить'} = {} } = {}, setPopupTasks, renderFeedback, setShowFeedback } = useGlobalContext()
    

    const [didMount, setDidMount] = useState(false); 

    useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
    }, [])



    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const [loading, setLoading] = useState(false)  // isLoading FROM STORE CAN'T BE USED AS IT UPDATES THE TABLE WHEN POPUP IS CLOSED WHICH IS A PROBLEM ON TZ_NEW AS WE LOOSE ALL THE FILLED DATA WHEN CREATING NEW TZ THUS USE LOCAL loading STATE
    const [formFields] = useState({
        text_field: TextFieldTextArea,
        select_field: SelectPositionedField,
        // date_field: DateField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        multi_select_field: MultiSelectField,
        checkbox_textarea_field: CheckboxTextareaField,
        select_text_field: SelectTextField,
        textarea_field: TextAreaFieldMU,
        checkbox_field: CheckboxField,
        select_field3: SelectField3Num,
        date_range_field: DateRangeField,
    })

    const [formData, setFormData] = useState(data)
    
    const handleFieldChange = (event, keyToChange=null) => {
        // console.log({[event.target.name]: event.target.value});

        if (!!event.target.value_id) {
            if (event.target.value_id === 'period') {
                return setFormData({
                    ...formData,
                    [event.target.name]: {
                        ...formData[event.target.name], 
                        value: event.target.value,
                        value_id: event.target.value_id,
                    },
                    'period': {  // VISIBLE // EDITABLE
                        ...formData[event.target.value_id], 
                        visible: true,
                        editable: true
                    },
                    'date': {
                        ...formData['date'], 
                        visible: false,
                        editable: false
                    },
                    'hour': {
                        ...formData['hour'], 
                        visible: false,
                        editable: false
                    },
                })
            }

            return setFormData({
                ...formData,
                [event.target.name]: {
                    ...formData[event.target.name], 
                    value: event.target.value,
                    value_id: event.target.value_id,
                },
                'period': {
                    ...formData['period'], 
                    visible: false,
                    editable: false
                },
                'date': {  // VISIBLE // EDITABLE
                    ...formData['date'], 
                    visible: true,
                    editable: true
                },
                'hour': {  // VISIBLE // EDITABLE
                    ...formData['hour'], 
                    visible: true,
                    editable: true
                },
            })
        }
        
        setFormData({
            ...formData,
            [event.target.name]: {
                ...formData[event.target.name], 
                value: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            }
        })
    }

    const handleSubmit = useCallback(async event => {
        if (loading) return
        event && event.preventDefault()
        console.log('submitting...');
        try {
            const areAllFieldsFilled = Object.keys(formData).every(accessor => !formData[accessor].required || !formData[accessor].editable || !!String(formData[accessor].value))
            if (!areAllFieldsFilled) {
                renderFeedback('danger', 'Пожалуйста, заполните все обязательные поля!')
                return
            }
            
            setLoading(true)

            const dataToSend = JSON.parse(JSON.stringify(formData))
            Object.keys(dataToSend).forEach(accessor => {
                if (!dataToSend[accessor].visible) return dataToSend[accessor].value = null
            })

            console.log(dataToSend);
            const response = await axios.post(url, dataToSend)
            renderFeedback('success', response.data.message || 'Success')
            
            setPopupTasks({ visible: false, data: {}, url: null })
            callback && callback()
        } catch (error) {
            // console.log(error.message, error.response.data.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [formData, callback, loading, renderFeedback, setPopupTasks, url])

    useEffect(() => {
        function handleEscKeyClick(event) {
            if (loading) return
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setPopupTasks({ visible: false, data: {}, url: null })
            }

            if (key === 'Enter') {
                console.log(event.target.classList.contains('text__input'))
                if (event.target.classList.contains('text__input')) return
                handleSubmit()
            }
        }
        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [setPopupTasks, handleSubmit, loading])
 
    const closePopup = () => setPopupTasks({visible: false, data: {}})


    if(!didMount) {
        return null;
    }

    if (loading) return(
        <div className="popup-container background" onDoubleClick={closePopup}>
        <div className="popup" onDoubleClick={event => event.stopPropagation()}>
            <LoaderComponent text="Отправляется..."/>
        </div>
        </div>
    ) 

    return (
        <div className="popup-container background" onDoubleClick={closePopup}>
            <div className="popup" onDoubleClick={event => event.stopPropagation()}>
                <h2 className="popup__title">{title}</h2>
                <div className="popup__close-btn-container" onClick={closePopup}>
                    <CloseButton />
                </div>
                <div className="popup__content">
                <form autoComplete="off" className="popup__form">
                    {Object.keys(formData).map((accessor, index) => {
                        if (formData[accessor] instanceof String || typeof formData[accessor] === 'string') {
                            return <p key={accessor} className="popup__form__subtitle">{formData[accessor]}</p>
                        }
                        if (accessor === 'targetings') return null
                        if (formData[accessor].visible === false) return null

                        const { hint, label, type, value, options, editable, required, input_value, sign } = formData[accessor]
                        // console.log(formData[accessor]);
                        const FormField = formFields[type]
                        return <FormField key={index} label={label} name={accessor} value={value} input_value={input_value} sign={sign} options={options} editable={editable} required={required} hint={hint} onChange={handleFieldChange} />
                    })}
                    {visible && <button type="submit" className="popup__submit-btn button" onClick={handleSubmit}>{text}</button>}
                </form>
                </div>
            </div>
        </div>
    )
}

    // accessor: {
    //     label: 'aaaaaa',
    //     type: 'text_field',
    //     hint: 'hhhhhh',
    //     value: 'ggg',
    //     editable: true
    // }
    // accessor: {
    //     label: 'aaaaaa',
    //     type: 'date_field',
    //     hint: 'hhhhhh',
    //     value: 'ggg',
    //     editable: true
    // }
    // accessor: {
    //     label: 'aaaaaa',
    //     type: 'select_field',
    //     hint: 'hhhhhh',
    //     value: 'ggg',
    //     options: [
    //         {label: 'a1', backend_id: 'name1'}, 
    //         {label: 'a2', backend_id: 'name2'}
    //     ],
    //     editable: true
    // }
    // accessor: {
    //     label: 'aaaaaa',
    //     type: 'checkbox_field',
    //     hint: 'hhhhhh',
    //     checkbox: [
    //         {value: true, label: 'Gilad Gray', name: 'gilad'},
    //         {value: false, label: 'Jason Killian', name: 'jason'},
    //         {value: false, label: 'Antoine Llorca', name: 'antoine'},
    //         // {value: 'disabled', label: '(Disabled option)', disabled: true},
    //     ],
    //     editable: true
    // }
    // accessor: {
    //     label: 'aaaaaa',
    //     type: 'radio_field',
    //     hint: 'hhhhhh',
    //     value: 'female',
    //     options: [
    //         {value: 'female', label: 'Female'},
    //         {value: 'male', label: 'Male'},
    //         {value: 'other', label: 'Other'},
    //         {value: 'disabled', label: '(Disabled option)', disabled: true},
    //     ],
    //     editable: true
    // }


     // accessor: {
    //     type: 'checkbox_field2',
    //     hint: 'hhhhhh',
    //     value: true/false
    //     editable: true
    // }