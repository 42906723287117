import React from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { BsQuestionOctagon, BsQuestionSquare } from 'react-icons/bs'


export const Hint = ({children, hint, placement='bottom', className}) => {
    return (
        <div className="sidebar_closed_hint">
            <Tippy
                content={<div>{hint || 'Тут должна быть подсказка'}</div>} 
                placement={placement}
                interactive={false}
            >
                {children}
            </Tippy>
        </div>
    )
}

export const HintTable = ({hint}) => {
    return (
        <div className="table__th__hint">
            <Tippy 
                content={<div>{hint || 'Тут должна быть подсказка'}</div>} 
                placement="bottom"
                interactive={false}
            >
                <button className="table__th__hint__icon"><BsQuestionSquare /></button>
            </Tippy>
        </div>
    )
}

export const HintHeader = ({children, hint, placement='bottom', className}) => {
    return (
        <Tippy
            content={<div>{hint || 'Тут должна быть подсказка'}</div>} 
            placement={placement}
            interactive={false}
        >
            {children}
        </Tippy>
    )
}