import React from 'react'
import './close-button.css'
import {ReactComponent as Cancel} from '../../../assets/sidebar-icons/cancel.svg'


const CloseButton = () => {
    return (
        <button className="close-btn">
            <Cancel className="cancel"/>
        </button>
    )
}

export default CloseButton
