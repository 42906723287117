import React, { useState } from 'react'
import './select_text.css'
import {ReactComponent as Search} from '../../../assets/sidebar-icons/search.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useGlobalContext } from '../../../store/context/context'
import { SelectField } from '../select/Select'
import TextField from '../text/Text'

export const SelectTextField = ({value='qwert', input_value='input', options=[1,2,3,4,5], onChange, type="text", label, name, hint, parentForm, editable=true, required, rowClickable=false, handleValueRemoveButtonClick}) => {

    
    // console.log(value);
    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    const handleFormFieldClick = event => {
        // event.preventDefault()
        rowClickable && event.stopPropagation()
        // console.log('inside', event.target);
    }
 
    const handleSelectFieldChange = event => {
        console.log('select field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'value')
    }

    const handleTextFieldChange = event => {
        console.log('text field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'input_value')

    }


    return (
        <div className={`select_text flex form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            {/* <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label> */}
            <SelectField key={'index'} label={label} name={name} value={value} options={options} editable={editable} required={required} hint={hint} onChange={handleSelectFieldChange} />
            <TextField key={'index'} label={label} name={name} value={input_value} options={options} editable={editable} required={required} hint={hint} onChange={handleTextFieldChange} />
        </div>
    )
}