import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../store/context/context'
import CloseButton from '../../buttons/close/CloseButton'
import DateField from '../../forms/date/Date'
import DateNumberField from '../../forms/date/DateNumber'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'

export const PopupLogs = () => {
    const { popupLogs: { type, url, data, date_from, date_to, title} = {}, setPopupLogs, renderFeedback, setShowFeedback } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const [dateFields, setDateFields] = useState({
        date_from: {
            label: 'c',
            value: date_from || '',
        },
        date_to: {
            label: 'по',
            value: date_to || '',
        }
    })

    const [comment, setComment] = useState('')

    const onDateFieldsChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Popup Logs Called........');
        try {
            setLoadingOnDateSubmit(true)

            const response = await axios.get(url + `?&date_from=${dateFields.date_from?.value}&date_to=${dateFields.date_to?.value}`)
            console.log(response.data);
            const { data, date_from, date_to } = response.data
            setPopupLogs({
                type,
                visible: true,
                data,
                title,
                url,
                date_from,
                date_to
            })
            // setActiveTopbarLink(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)  // /tzs/kpi?status=Пополнено&date_from=${response.data.date_from}&date_to=${response.data.date_to}
            // history.replace(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)
            
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    const closePopup = () => setPopupLogs({visible: false, data: []})

    const saveComment = async event => {
        event.preventDefault()

        if (!comment) return renderFeedback('danger', 'Нельзя добавлять пустой коммент!')

        try {
            const responsePost = await axios.post(url, { text: comment })
            setComment('')

            const responseGet = await axios.get(url + `?&date_from=${dateFields.date_from?.value}&date_to=${dateFields.date_to?.value}`) 
            let { data, date_from, date_to } = responseGet.data
            setPopupLogs({  // REFRESH LOGS | UPDATE
                type, // logs or comments,
                visible: true,
                data,
                title: 'История комментариев',
                url,
                date_from,
                date_to
            }) 

            renderFeedback('success', responsePost.data.message || 'Комментарий успешно добавлен')
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    return (
        <div className="popup-container background" onDoubleClick={closePopup}>
            <div className="popup" style={{width: '1200px'}} onDoubleClick={event => event.stopPropagation()}>
                <div className="popup__top">
                    <h2 className="popup__top__title">{title}</h2>
                    <div className="popup__date-fields">
                        {Object.keys(dateFields).map((date_field_accessor, index) => {
                            const { label, value } = dateFields[date_field_accessor]
                            return <div key={index} className="popup__date-field">
                                <p className='popup__date-field__label'>{label}:</p>
                                <DateNumberField label="" name={date_field_accessor} value={value} onChange={onDateFieldsChange} editable/>
                            </div>
                        })}
                        <button className="button button-2 button-ml" onClick={handleDateSubmitButtonClick}>
                            <span>Отправить</span>
                        </button>
                    </div>
                </div>
                <div className="popup__close-btn-container" onClick={closePopup}>
                    <CloseButton />
                </div>

                {(type === 'comments' || type === 'tz_comment') && <form className='popup__comment-form'>
                    <TextAreaFieldMU value={comment} onChange={e => setComment(e.target.value)} label="label" name="name" editable required maxRows={4} minRows={4}/>
                    <button type='submit' className='button button-2' onClick={saveComment}>Сохранить</button>
                </form>}

                <div className="popup__logs">
                    <div className="popup__log-container">
                        {data.map((log, index) => <Log key={index} log={log} type={type}/>)}
                   </div>
               </div>
            </div>
        </div>
    )
}


const Log = ({ log: { author='', timestamp, text }, type }) => {
    if (type === 'comments') return (
        <div className="popup__log">
            <div className="popup__log__top">
                <p className="popup__log__text">{text}</p>
                <p className="popup__log__timestamp">{timestamp}</p>
            </div>
        </div>
    )

    return (  // BY DEFAULT OR IF TYPE=tz_comment
        <div className="popup__log">
            <div className="popup__log__top">
                <p className="popup__log__author">{author}</p>
                <p className="popup__log__timestamp">{timestamp}</p>
            </div>
            <p className="popup__log__text">{text}</p>
        </div>
    )
}