export const toggleFormFieldBottom = (formRef, showFormFieldBottom, value, setShowFormFielBottom) => {
    const label = formRef.current.querySelector('label')
    const input = formRef.current.querySelector('input')

    if (!showFormFieldBottom) { // CALENDAR IS ABOUT TO BE OPENED
        label.classList.remove('focused', 'filled') // THUS APPLY FOSUED TO LABEL
        label.classList.add('focused')
        input.focus()
    } else { // CALENDAR IS ABOUT TO BE CLOSED AND
        input.blur()
        if (value) { // NO DATE WAS CHOSEN, THUS RETURN THE LABEL BACK TO ITS INITIAL PLACE
            label.classList.add('focused', 'filled')
        } else { // DATE IS CHOSED, THUS APPLY NECESSARY STYLES
            label.classList.remove('focused', 'filled')
        }
    }
    setShowFormFielBottom(!showFormFieldBottom)  // OPEN/CLOSE CALENDAR
}