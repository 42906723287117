import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { AnimatedBackgroundLoader, LoaderComponent } from '../../components/layout/loader/Loader';
import Popup from '../../components/layout/popup/Popup';
import Table from '../../components/layout/table/Table';
import { TableV } from '../../components/layout/table/TableV';
import { useGlobalContext } from '../../store/context/context';
import './fee.css'
// import { useAxios } from '../../utils/js/useAxios';



export const ClientFee = () => {
    const { setTopbarData, setActiveTopbarLink, setBreadcrumbs, setActiveSidebarLink, setPopup, setShowFeedback, renderFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const { client_id, placement_id } = useParams()
    const location = useLocation();
    const history = useHistory()
    

    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)
    
    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`placements/${placement_id}/`)
            setClient(response_client.data) 
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })

            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [setServerError, placement_id])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`placements/${placement_id}/fee/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            // setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            setErrorTable({ message: `Error. Code ${error.response.status}.\nText: ${error.response.data}` })
        } finally {
            setLoadingTable(false)
        }
    }, [setServerError, placement_id])
    useEffect(() => {
        // getClient()
        getTable()
    }, [getClient, getTable])
    
    useEffect(() => {
        setActiveSidebarLink('users/clients')
    }, [setActiveSidebarLink])
    
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])    

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'ТЗ', url: `/users/clients/${client_id}/placements/${placement_id}/tz/view`}, 
                {value: 'Комиссии', url: `/users/clients/${client_id}/placements/${placement_id}/fee`}, 
            ],
            sublinks: null
        })
    }, [setTopbarData, client_id, placement_id])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users/clients', breadcrumb: 'Клиенты' },
            { path: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`, breadcrumb: `Test Площадки` }, // i.e. /clients/id/brands/id ==>> /clients/id/brands
            { path: `${location.pathname}`, breadcrumb: `Комиссии` },  // links[getURL()] WAS HERE PREVIOUSLY)
        ])
    }, [table.title, location.pathname, setBreadcrumbs])
   

    const buttons =  [
            {
            text: `Добавить комиссию`,
            icon: 'plus',
            url: `${location.pathname}/new/`,
            popup_title: "Комиссия",
            popup_callback() {
                getTable()
            }
        },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value

        if (table.title === 'Договоры') {  // IF CONTRACTS TABLE ROW CLICKED, THEN OPEN A POPUP
            try {
                const url = `users/clients/${client_id}/contracts/${request_id}/edit/`
                const response = await axios.get(url)
                setPopup({
                    visible: true,
                    data: response.data,
                    url,
                    title: 'Редактировать договор с клиентом',
                    callback() {
                        getTable()
                    }
                })
            } catch (error) {
                setPopup({
                    visible: false,
                    data: {},
                    url: null,
                    title: ''
                })
            }
            // history.push(`/clients/${id}/contracts/${request_id}/additional`)
        } else if (table.title === 'Площадки') {
            console.log(`${location.pathname}/${request_id}/tz/view`);
            // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/view`);
            history.push({
                pathname: `${location.pathname}/${request_id}/tz/view`,
            })
        } else { // ELSE GO INSIDE ROLE
            // console.log('CLIENT: rowclick', `${location.pathname}/${request_id}/`);
            history.push({
                pathname: `${location.pathname}/${request_id}`,
            })
        }
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        try {
            const url = `users/clients/${client_id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать клиента',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    return (<>
        <div className='fee page'>
            {loadingClient 
                // ? <LoaderComponent text='Loading client info...'/> 
                ? <AnimatedBackgroundLoader text='Loading placement info...' height={102} mb='1rem'/> 
                : errorClient 
                    ? <div>{errorClient.message}</div> 
                    : <ClientInfo inline client={client} editClient={editClient}/>}
                        {/* <ClientInfo client={client}/> */}
        </div>

        {loadingTable 
            ? <AnimatedBackgroundLoader text='Loading table...' height={130} mb={40}/> 
            : errorTable 
                ? <pre>{errorTable.message}</pre> 
                : <TableV 
                    table={table} 
                    buttons={buttons.reverse()} 
                    handleRowClick={handleRowClick} 
                    refreshData={getTable}
                    href={(() => {
                        let href
                        switch (table.title) {
                            case 'Договоры':
                                href = null
                                break
                            case 'АВР':
                                href = {
                                    from: location.pathname,
                                    to: 'view'
                                } 
                                break
                            default:
                                href = {
                                    from: location.pathname,
                                    to: ''
                                } 
                        }
                        return href
                    })()
                    }
                />}
        </>
    )
}
