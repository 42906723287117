import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useHistory} from 'react-router'
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'
import { useGlobalContext } from '../../store/context/context'
import './home.css'
import {useCancelToken} from "../../utils/js/useCancelToken";
import {KrugChart} from "../../components/charts/KrugChart";
import {StolbBar} from "../../components/charts/StolbBar";


const Home = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, renderFeedback, setShowFeedback, setServerError } = useGlobalContext()
    const { newCancelToken, isCancel } = useCancelToken();

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()
    // const { response: table, error, loading } = useAxios('clients/')

    const [table, setTable] = useState({})
    const [graph, setGraph] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
        // currency: {
        //     label: 'валюта',
        //     value: 'KZT',
        //     type: 'select_field',
        //     options: ['KZT', 'RUB', 'USD']
        // },
    })

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get('tzs/reports/main/', {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            setGraph(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}&currency=${response.data.currency || 'KZT'}`)

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsClients');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, newCancelToken, isCancel])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get('tzs/reports/main/', {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            setGraph(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }
        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsClients');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError, newCancelToken, isCancel])

   useEffect(() => {
       // console.log(location, history.action)
      // if (history.action === 'REPLACE') return
      // if (!location.search) return getInitialDataAndReplace()
     getInitialData()
    }, [getInitialData])


    // const getTable = useCallback(async () => {
    //     try {
    //         setLoading(true)
    //         const response_table = await axios.get('tzs/reports/main/')
    //         setTable(response_table.data)
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }
    //         setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
    //         // setError(error.response?.data|| 'Server error')
    //     } finally {
    //         setLoading(false)
    //     }
    // }, [setServerError])
    // useEffect(() => {
    //     getTable()
    // }, [getTable])

    useEffect(() => {
        setActiveSidebarLink('')
    }, [setActiveSidebarLink])


    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/', breadcrumb: 'Главная' },
          ])
    }, [setBreadcrumbs])

    const buttons = [
        // {
        //     text: 'Добавить клиента',
        //     icon: 'plus',
        //     url: 'users/clients/new/',
        //     popup_title: 'Клиент',
        //     popup_callback() {
        //         getTable()
        //     }
        // },
    ]

    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)
            const response = await axios.get(`tzs/reports/main/?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)
            setTable(response.data)
            setGraph(response.data)
            history.replace(location.pathname + `?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)

        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    const handleRowClick = () => {

    }
    if(!didMount) return null;

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>

    return (
        <>



            {loadingOnDateSubmit || loading
                ? <LoaderComponent text={loadingOnDateSubmit ? 'Загрузка...' : 'Загрузка...'}/>
                : <>
                    <TableV
                        table={table}
                        date_fields={dateFields}
                        buttons={buttons.reverse()}
                        handleRowClick={handleRowClick}
                        onDateFieldsChange={handleFieldChange}
                        handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                        refreshData={getInitialData}
                    />

                    {/*<div className="home page">*/}
                    {/*    <StolbBar graph={graph}/>*/}
                    {/*    <KrugChart graph={graph}/>*/}
                    {/*</div>*/}

                </>
            }


        </>
    )
}

export default Home



// const table = {
//   analysis: [
//     ["KZT", 7336098.7700000005, 8581952.739999998, 1245853.9700000002],
//     ["USD", 0, 0, 0],
//     ["RUB", 0, 0, 0],
//     ["HUF", 0, 0, 0]
//   ],
//   headers: [
//     {value: "Клиент", type: "string"},
//     {value: "БИН", type: "string"},
//     {value: "Договор", type: "string"},
//     {value: "Дата договора", type: "date"},
//     {value: "Валюта договора", type: "string"},
//     {value: "Наше ТОО", type: "string"},
//     {value: "Индексация", type: "string"},
//     {value: "KAM", type: "string"},
//     {value: "PM", type: "string"},
//     {value: "Бренд", type: "string"},
//     {value: "Флайт", type: "string"},
//     {value: "KPI/Billing", type: "string"},
//     {value: "Контекстник", type: "string"},
//     {value: "Система", type: "string"},
//     {value: "Инструмент", type: "string"},
//     {value: "Дата начала", type: "date"},
//     {value: "Дата завершения", type: "date"},
//     {value: "Модель оплаты", type: "string"},
//     {value: "Тип кампании", type: "string"},
//     {value: "Валюта аккаунта", type: "string"},
//     {value: "Расход без НДС и АК (валюта аккаунта)", type: "number"},
//     {value: "Курс валют", type: "number"},
//     {value: "Расход без НДС и АК (валюта договора)", type: "number"},
//     {value: "Цена / АК без НДС [План]", type: "number"},
//     {value: "Цена / АК без НДС [Факт]", type: "number"},
//     {value: "План KPI / Бюджет без НДС и АК", type: "number"},
//     {value: "Факт KPI / Бюджет без НДС и АК", type: "number"},
//     {value: "Бюджет без НДС [План]", type: "number"},
//     {value: "Бюджет без НДС [Факт]", type: "number"},
//     {value: "Бюджет с НДС [Факт]", type: "number"},
//     {value: "ВП", type: "number"},
//   ],
//   rows: [
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//   ],
//   year: 2021,
//   month: 9
// }
