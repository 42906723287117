import React, { useMemo } from 'react'

const SelectOptions = React.forwardRef(({ sign, options, searchText='', selectedOption, handleOptionClick, className, removeSelectedOption, deleteOptionEnabledInSelect=false }, ref) => {
    const lowerCaseString = input => input.toString().toLowerCase()
    const isString = variable => typeof variable === 'string' || variable instanceof String || typeof variable === 'number' || variable instanceof Number

    const filteredOptions = useMemo(() => options.filter(option => lowerCaseString(isString(option) ? option : option.value).includes(lowerCaseString(searchText))), [options, searchText])

    return (<>
        <ul className={`select__options ${className}`} ref={ref}>
            {/* <li className="select__option selected">
                EMPTY VALUE TO RESET THE SELECT VALUE TO NOTHING  
            </li> */}
            {filteredOptions.length ? 
            <>
            {filteredOptions.map((option, index) => {
                return <li 
                key={index} 
                className={lowerCaseString(selectedOption) === lowerCaseString(isString(option) ? option : option.value) ? "select__option selected" : "select__option"} 
                onClick={() => handleOptionClick(option)} 
                >
                            {sign ? (option * 100).toFixed(2) + "%" 
                                : isString(option) ? option.toString() : option.value.toString()
                            }
                        </li>
            })}
            {deleteOptionEnabledInSelect && <li className="select__option delete-option" onClick={removeSelectedOption}>Удалить</li>}
            </>
            : 
            <li className="select__option selected">
                Никаких вариантов нет
            </li>
            }     
        </ul>
    </>

    )
})

export default SelectOptions
