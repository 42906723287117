import React, { useEffect, useState } from 'react';
import { FiSettings } from 'react-icons/fi'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './bar-chart.css'
import { useRef } from 'react';
import { useGlobalContext } from '../../../store/context/context';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const COLORS = {
  0: {
    borderColor: 'rgb(34, 194, 146)',
    backgroundColor: 'rgba(87, 250, 201, .5)',
  },
  1: {
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  2: {
    borderColor: 'rgb(146, 103, 226)',
    backgroundColor: 'rgba(117, 53, 235, .5)',
  },
  3: {
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
}

export const BarChart = ({ chartData, title }) => {
  const { SPECIAL_VALUES } = useGlobalContext()
  const { labels, datasets } = chartData
  const getVisibleLabels = labels => labels.filter(label => label.visible).map(label => label.text)  // FUNCTION THAT FILTERS AND RETURNS ARRAY OF VISIBLE DATA
  
  const [initialData, setInitialData] = useState(chartData.datasets || {
    // workload_user: [{ id: 'number', text: 'string', visible: 'boolean' }], 
    workload_weekly: [{ id: 'number', text: 'string', visible: 'boolean' }], 
    workload_month: [{ id: 'number', text: 'string', visible: 'boolean' }], 
    workload_project: [{ id: 'number', text: 'string', visible: 'boolean' }], 
    workload_void: [{ id: 'number', text: 'string', visible: 'boolean' }]
  })  // NEEDED FOR DND LIST
  const [initialLabels, setInitialLabels] = useState(chartData.labels || 
    [{ id: 'number', text: 'string', visible: 'boolean' }], 
  )   // NEEDED FOR DND LIST

  const [data, setData] = useState({  // NEEDED FOR GRAPH
    labels: getVisibleLabels(chartData.labels),
    datasets: Object.keys(chartData.datasets).map((dataset_accessor, idx) => ({
      // label: 'Еженедельные',
      label: chartData.datasets[dataset_accessor][0].label,
      data: getVisibleLabels(chartData.datasets[dataset_accessor]),
      // data: labels.map((_, i) => i + 1),
      borderColor: COLORS[idx % Object.keys(COLORS).length].borderColor,
      backgroundColor: COLORS[idx % Object.keys(COLORS).length].backgroundColor,
    })),
    // datasets: [
    //   {
    //     // label: 'Еженедельные',
    //     label: chartData.workload_weekly[0].label,
    //     data: getVisibleLabels(chartData.workload_weekly),
    //     // data: labels.map((_, i) => i + 1),
    //     borderColor: 'rgb(34, 194, 146)',
    //     backgroundColor: 'rgba(87, 250, 201, .5)',
    //   },
    //   {
    //     // label: 'Ежемесячные',
    //     label: chartData.workload_month.label,
    //     data: getVisibleLabels(chartData.workload_month),
    //     borderColor: 'rgb(53, 162, 235)',
    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   },
    //   {
    //     // label: 'Переменные',
    //     label: chartData.workload_project.label,
    //     data: getVisibleLabels(chartData.workload_project),
    //     borderColor: 'rgb(146, 103, 226)',
    //     backgroundColor: 'rgba(117, 53, 235, .5)',
    //   },
    //   {
    //     // label: 'Нет данных',
    //     label: chartData.workload_void.label,
    //     data: getVisibleLabels(chartData.workload_void),
    //     borderColor: 'rgb(255, 99, 132)',
    //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //   },
    // ],
  })
  const settingsRef = useRef(null)

  useEffect(() => {  // TOGGLES FILTER & SEARCH DIVs 
    const handleInputBlur = event => {
        // if (event.target !== inputRef.current && !event.target.classList.contains('filter__icon')) {
        //     // CLICKED OUTSIDE SEARCH(input) FIELD AND FILTER ICON
        //     // AS CLICKING ON FILTER ICON TOGGLES POPUP ON ITS OWN
        //     setShowSearchField(undefined)
        // }

        if (settingsRef.current && !settingsRef.current.querySelector('.dnd-group')?.contains(event.target) && event.target !== settingsRef.current.querySelector('.svg-container')) {
            setShowColumnsList(false)
        }
    }

    document.addEventListener('mousedown', handleInputBlur)
    return () => document.removeEventListener('mousedown', handleInputBlur)
}, [])

  // ----------------- DRAG-N-DROP START ---------------------
  const [showColumnsList, setShowColumnsList] = useState(false)
  const [currentDragAccessor, setCurrentDragAccessor] = useState(null) 

  const handleDragStart = (event, dragStartAccessor) => {
      console.log('Start Drag: ', dragStartAccessor);
      setCurrentDragAccessor(dragStartAccessor)
      setTimeout(() => { // HELPS IN STYLING
          event.target.classList.add('start')
      }, 0)
  }
  
  const handleDragOver = (event, dragOverAccessor) => {
      event.preventDefault()
      event.target.classList.add('over')
      // CURRENT_DRAG_ACCESSOR IS EQUAL TO DRAG_OVER_ACCCESSOR BECAUSE:
      // 1) WE JUST GRABBED ONE ITEM AND THE MOUSE IS STILL ON IT
      // 2) WE ALREADY MOVED ONE SPACE UP/DOWN AND THUS THE BELOW IF IS FULLFILLED
      // IT MEANS THAT WE ALREADY SWAPPED TWO ITEMS AND NOW THE DRAG-OVER-ACCESSOR IS THE SAME AS CURRENT-DRAG-ACCESSOR
      // CAUSE CURRENT-DRAG-ACCESSOR DOES NOT CHANGE AS LONG AS WE KEEP GRABBING, AND UNTIL WE RELEASE IT AND PICK ANOTHER ITEM
      // AND DRAG-OVER-ACCESSOR HAS NOW BECOME AS CURRENT-DRAG-ACCESSOR CAUSE WE ARE ALREADY CURRENTLY DRAGGING OVER THE ITEM WE HAVE PICKED

      let swap= (arr,i,j) => {[arr[i], arr[j]] = [arr[j], arr[i]]; return arr}  // inplace solution II

      if (dragOverAccessor.id !== currentDragAccessor.id) {  
          // CURRENT_DRAG_ACCESSOR IS NOT EQUAL TO DRAG_OVER_ACCCESSOR
          let updatedLabels = JSON.parse(JSON.stringify(initialLabels))
          const grabbedValueIndex = updatedLabels.findIndex(el => el.id === currentDragAccessor.id)
          const overValueIndex = updatedLabels.findIndex(el => el.id === dragOverAccessor.id)
          updatedLabels = swap(updatedLabels, grabbedValueIndex, overValueIndex)
          setInitialLabels(updatedLabels)
          
          const updatedData = JSON.parse(JSON.stringify(initialData))
          for (let accessor in updatedData) {
            // let newValues = JSON.parse(JSON.stringify(updatedData[accessor]))
            const grabbedValueIndex = updatedData[accessor].findIndex(el => el.id === currentDragAccessor.id)
            const overValueIndex = updatedData[accessor].findIndex(el => el.id === dragOverAccessor.id)
            
            updatedData[accessor] = swap(updatedData[accessor], grabbedValueIndex, overValueIndex)
          }


          setInitialData(updatedData)
          setData({
            labels: getVisibleLabels(updatedLabels),
            datasets: Object.keys(updatedData).map((dataset_accessor, idx) => ({
              // label: 'Еженедельные',
              label: updatedData[dataset_accessor][0].label,
              data: getVisibleLabels(updatedData[dataset_accessor]),
              // data: labels.map((_, i) => i + 1),
              borderColor: COLORS[idx % Object.keys(COLORS).length].borderColor,
              backgroundColor: COLORS[idx % Object.keys(COLORS).length].backgroundColor,
            })),
            // labels: getVisibleLabels(updatedData.workload_user),
            // datasets: [
            //   {
            //     label: 'Еженедельные',
            //     data: getVisibleLabels(updatedData.workload_weekly),
            //     // data: labels.map((_, i) => i + 1),
            //     borderColor: 'rgb(34, 194, 146)',
            //     backgroundColor: 'rgba(87, 250, 201, .5)',
            //   },
            //   {
            //     label: 'Ежемесячные',
            //     data: getVisibleLabels(updatedData.workload_month),
            //     borderColor: 'rgb(53, 162, 235)',
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            //   },
            //   {
            //     label: 'Переменные',
            //     data: getVisibleLabels(updatedData.workload_project),
            //     borderColor: 'rgb(146, 103, 226)',
            //     backgroundColor: 'rgba(117, 53, 235, .5)',
            //   },
            //   {
            //     label: 'Нет данных',
            //     data: getVisibleLabels(updatedData.workload_void),
            //     borderColor: 'rgb(255, 99, 132)',
            //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //   },
            // ],
        })
      }
  }
  const handleDragLeave = (event) => {
      // console.log(`Leave Drag: `, event.target);
      event.target.classList.remove('over')
  }
  const handleDragEnd = (event) => {
      // console.log(`End Drag: `, event.target);
      event.target.classList.remove('over')
      event.target.classList.remove('start')
  }
  
  const handleDrop = (event) => {
      // console.log('Drop Drag: ', index);
      event.preventDefault()

      event.target.classList.remove('over')

      // postTableSettings()
  }

  const toggleColumns = ({ text, visible, id }) => {
      const updatedData = JSON.parse(JSON.stringify(initialData))
      for (let accessor in updatedData) {
        updatedData[accessor] = updatedData[accessor].map(single_bar => {
          if (single_bar.id === id) {
            return { ...single_bar, visible: !single_bar.visible}
          }
          return single_bar
        })
      }

      let updatedLabels = JSON.parse(JSON.stringify(initialLabels))
      updatedLabels = updatedLabels.map(single_bar => {
        if (single_bar.id === id) {
          return { ...single_bar, visible: !single_bar.visible}
        }
        return single_bar
      })
      setInitialLabels(updatedLabels)
      
      setInitialData(updatedData)
      setData({
        labels: getVisibleLabels(updatedLabels),
        datasets: Object.keys(updatedData).map((dataset_accessor, idx) => ({
          // label: 'Еженедельные',
          label: updatedData[dataset_accessor][0].label,
          data: getVisibleLabels(updatedData[dataset_accessor]),
          borderColor: COLORS[idx % Object.keys(COLORS).length].borderColor,
          backgroundColor: COLORS[idx % Object.keys(COLORS).length].backgroundColor,
        })),
        // labels: getVisibleLabels(updatedData.workload_user),
        // datasets: [
        //   {
        //     label: 'Еженедельные',
        //     data: getVisibleLabels(updatedData.workload_weekly),
        //     // data: labels.map((_, i) => i + 1),
        //     borderColor: 'rgb(34, 194, 146)',
        //     backgroundColor: 'rgba(87, 250, 201, .5)',
        //   },
        //   {
        //     label: 'Ежемесячные',
        //     data: getVisibleLabels(updatedData.workload_month),
        //     borderColor: 'rgb(53, 162, 235)',
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //   },
        //   {
        //     label: 'Переменные',
        //     data: getVisibleLabels(updatedData.workload_project),
        //     borderColor: 'rgb(146, 103, 226)',
        //     backgroundColor: 'rgba(117, 53, 235, .5)',
        //   },
        //   {
        //     label: 'Нет данных',
        //     data: getVisibleLabels(updatedData.workload_void),
        //     borderColor: 'rgb(255, 99, 132)',
        //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //   },
        // ],
      })
  }

  const resetTableSettings = async () => {
    setInitialData(chartData.datasets)
    setInitialLabels(chartData.labels)

    setData({
      labels: getVisibleLabels(chartData.labels),
      datasets: Object.keys(chartData.datasets).map((dataset_accessor, idx) => ({
        // label: 'Еженедельные',
        label: chartData.datasets[dataset_accessor][0].label,
        data: getVisibleLabels(chartData.datasets[dataset_accessor]),
        // data: labels.map((_, i) => i + 1),
        borderColor: COLORS[idx % Object.keys(COLORS).length].borderColor,
        backgroundColor: COLORS[idx % Object.keys(COLORS).length].backgroundColor,
      })),
    // labels: getVisibleLabels(chartData.workload_user),
    // datasets: [
    //   {
    //     label: 'Еженедельные',
    //     data: getVisibleLabels(chartData.workload_weekly),
    //     // data: labels.map((_, i) => i + 1),
    //     borderColor: 'rgb(34, 194, 146)',
    //     backgroundColor: 'rgba(87, 250, 201, .5)',
    //   },
    //   {
    //     label: 'Ежемесячные',
    //     data: getVisibleLabels(chartData.workload_month),
    //     borderColor: 'rgb(53, 162, 235)',
    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   },
    //   {
    //     label: 'Переменные',
    //     data: getVisibleLabels(chartData.workload_project),
    //     borderColor: 'rgb(146, 103, 226)',
    //     backgroundColor: 'rgba(117, 53, 235, .5)',
    //   },
    //   {
    //     label: 'Нет данных',
    //     data: getVisibleLabels(chartData.workload_void),
    //     borderColor: 'rgb(255, 99, 132)',
    //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //   },
    // ],
  })
  }
  // ----------------- DRAG-N-DROP STOP ---------------------

  //
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        // position: 'right',
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: title === SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART ? false : true
  };
  //

  return (
    <div className='bar-chart'>
    <div className='bar-chart__settings' ref={settingsRef}>
      <button className='button button-2 svg-container' onClick={() => setShowColumnsList(!showColumnsList)}><FiSettings /> Настройки</button>
      {showColumnsList && <ul className='dnd-group' style={{ width: title !== SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART && '65vw' }}>
          <li className='dnd-item reset-settings-btn' onClick={resetTableSettings}>Сбросить настройки</li>
          {initialLabels.map(label => {
              return ( 
                  <li 
                    key={label.id} 
                    className='dnd-item'
                    style={{opacity: !label.visible && '.5'}}  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                    onClick={() => toggleColumns(label)}
                    draggable
                    onDragStart={event => handleDragStart(event, label)} 
                    onDragLeave={event => handleDragLeave(event)}
                    onDragEnd={event => handleDragEnd(event)}
                    onDragOver={event => handleDragOver(event, label )}
                    onDrop={event => handleDrop(event)}
                  >
                    {label.text}
                  </li>)
          })}
      </ul>}
    </div>
    <div>

    <Bar 
      options={options} 
      data={data} 
      height={title === SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART ? 350 : ''}
      width={title === SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART ? 800 : ''}
      />
      </div>
    </div>);
}

