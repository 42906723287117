import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import { LoaderComponent } from '../../components/layout/loader/Loader';
import Popup from '../../components/layout/popup/Popup';
import Table from '../../components/layout/table/Table';
import { TableV } from '../../components/layout/table/TableV';
import { useGlobalContext } from '../../store/context/context';
import './staff_permissions.css'
// import { useAxios } from '../../utils/js/useAxios';



const StaffPermissions = () => {
    const { setTopbarData, setActiveTopbarLink, setBreadcrumbs, setActiveSidebarLink, setPopup, setShowFeedback, setServerError, renderFeedback } = useGlobalContext()
    
    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const { id } = useParams()
    const location = useLocation();

    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)
    
    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`users/staff/${id}/`)
            setClient(response_client.data) 
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })

            // setErrorClient(error.response?.data || 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [id, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })

            // setErrorTable(error.response?.data || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])

    
    useEffect(() => {
        setActiveSidebarLink('users/staff')
    }, [setActiveSidebarLink, location.pathname])
    
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])    

    useEffect(() => {
        setTopbarData({
            links: [
                // {value: 'Бонусы', url: `/users/staff/${id}/bonuses`}, 
                {value: 'Доступы', url: `/users/staff/${id}/permissions`}, 
                {value: 'Проектные задачи', url: `/users/staff/${id}/projects`}, 
            ],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/users/staff', breadcrumb: 'Сотрудники' },
            { path: `${location.pathname}`, breadcrumb: `${table.title || ''}` },  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, table.title])
   

    const buttons = [
        // {
        //     text: `Добавить должность`,
        //     icon: 'plus',
        //     url: `${location.pathname}/positions/new/`,
        //     popup_title: 'Новая должность',
        // },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        const url = `users/staff/${id}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать данные сотрудника',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------


    return (<>
        <div className='staff-permissions page'>
            {/* <ClientInfo inline client={client} editClient={editClient}/> */}
            {/* <ClientInfo client={client} editClient={editClient}/> */}
            {loadingClient ? <LoaderComponent /> : errorClient ? <div>{errorClient.message}</div> : <ClientInfo client={client} editClient={editClient}/>}

        </div>

        {loadingTable 
        ? <LoaderComponent /> 
        : errorTable 
            ? <div>{errorTable.message}</div> 
            : <TableV 
                table={table} 
                buttons={buttons.reverse()} 
                handleRowClick={handleRowClick} 
                refreshData={getTable}
            />}

        </>
    )
}

export default StaffPermissions