import React, { useCallback, useEffect, useState } from 'react'
import './clients.css'
import Table from '../../components/layout/table/Table'

import { GoPrimitiveDot } from 'react-icons/go'

import {ReactComponent as Filter} from '../../assets/sidebar-icons/filter.svg'
import {ReactComponent as Plus} from '../../assets/sidebar-icons/plus.svg'
import {ReactComponent as Pencil} from '../../assets/sidebar-icons/pencil.svg'

import { useGlobalContext } from '../../store/context/context'
import { useAxios } from '../../utils/js/useAxios'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableV } from '../../components/layout/table/TableV'

const Clients = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])
    
    const history = useHistory()
    const location = useLocation()
    
    // const { response: table, error, loading } = useAxios('clients/')

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    
    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('users/clients/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])
   
    useEffect(() => {
        setActiveSidebarLink('users/clients')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            { path: '/clients', breadcrumb: table?.title || '' },
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
          ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Добавить клиента',
            icon: 'plus',
            url: 'users/clients/new/',
            popup_title: 'Клиент',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
console.log(`users/clients/${request_id}/placements`);
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `/users/clients/${request_id}/placements`,
        })
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
        <div className="clients page">
        </div>

        <TableV 
            table={table} 
            buttons={buttons.reverse()} 
            handleRowClick={handleRowClick} 
            refreshData={getTable} 
            href={{
                from: '/users/clients',
                to: 'placements'
            }}
            
            rowClickableByClient={true}
        />
        </>
    )
}

export default Clients


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }