import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import './table.css'
import { useGlobalContext } from '../../../store/context/context'

import {ReactComponent as Filter} from '../../../assets/sidebar-icons/filter.svg'
import {ReactComponent as Search} from '../../../assets/sidebar-icons/search.svg'
import {ReactComponent as Plus} from '../../../assets/sidebar-icons/plus.svg'
import {ReactComponent as Pencil} from '../../../assets/sidebar-icons/pencil.svg'
import {ReactComponent as Book} from '../../../assets/sidebar-icons/book.svg'
import {ReactComponent as Pin} from '../../../assets/sidebar-icons/push-pin.svg'
import {ReactComponent as PinFill} from '../../../assets/sidebar-icons/push-pin-fill.svg'
import {ReactComponent as Group} from '../../../utils/icons/group.svg'
import {ReactComponent as Download} from '../../../utils/icons/download.svg'
import {ReactComponent as Tools} from '../../../utils/icons/tools.svg'
import {ReactComponent as Confirm} from '../../../utils/icons/confirm.svg'
import {ReactComponent as List1} from '../../../utils/icons/list_1.svg'
import {ReactComponent as PlayButton1} from '../../../utils/icons/play-button_1.svg'
import {ReactComponent as Wait} from '../../../utils/icons/wait.svg'
import {ReactComponent as Pause1} from '../../../utils/icons/pause_1.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as PlayButton2} from '../../../utils/icons/play-button_2.svg'
import {ReactComponent as TZ_add} from '../../../utils/icons/tz_add.svg'
import {ReactComponent as Target1} from '../../../utils/icons/target_1.svg'
import {ReactComponent as Fill} from '../../../utils/icons/fill.svg'
import {ReactComponent as Copy} from '../../../utils/icons/copy.svg'
import {ReactComponent as QuestionYellow} from '../../../utils/icons/question_yellow.svg'
import {ReactComponent as Coin1} from '../../../utils/icons/coin_1.svg'

import DateField from '../../forms/date/Date'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import { SelectField } from '../../forms/select/Select'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'

import {ReactComponent as Arrow1} from '../../../utils/icons/arrow-1.svg'
import {ReactComponent as Arrow} from '../../../utils/icons/arrow.svg'
import { Checkbox } from '@material-ui/core'
import { CheckboxField2 } from '../../forms/checkbox/Checkbox2'
import Calendar from 'react-calendar'
import DateMUIWeek from '../../forms/date/DateMUIWeek'
import DateMUIMonth from '../../forms/date/DateMUIMonth'
import { HintTable } from '../hint/Hint'
import SwitchLabels from '../../forms/switch/Switch'

let legendDateWidths = {
    week: 433,
    month: 636,
}

export const TableForTasksHead = ({ table, buttons, date_fields={}, onDateFieldsChange, handleRowClick, refreshData, id, handleDateChange, handleWeeklyCalendarClick, isMonth, handleSwitchChange }) => {
    const { mainCenterTopBottomMargin, setPopupTasks, renderFeedback, buttonIcons, tableIcons } = useGlobalContext()
    
    const [filter, setFilter] = useState(() => {  // useMemo or smth like that
        const filterObject = {}
        Object.keys(table.headers).forEach(accessor => filterObject[accessor] = '')
        return filterObject
    })

    const location = useLocation()
    
    const [columnAccessors, setColumnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    // const [headers] = useState(table.headers)
   
    const [showSearchField, setShowSearchField] = useState(undefined)
    const inputRef = useRef(null)
    const tableContentRef = useRef(null)
    const tableCaptionContainerRef = useRef(null)
    const tableRef = useRef(null)
    const [isWeeklyCalendarVisible, setIsWeeklyCalendarVisible] = useState(false)

    // const [formFields] = useState({
    //     text_field: TextAreaFieldMU,
    //     select_field: SelectField,
    //     date_field: DateField,
    //     radio_field: RadioField,
    //     number_field: NumberField,
    //     checkbox_field: CheckboxField,
    //     checkbox_field2: CheckboxField2,
    //     // textarea_field: TextAreaField,
    // })


    const resizeTable = useCallback(() => {
        // resizeCells()
        const topbar = document.querySelector('.topbar')
        const windowHeight = window.innerHeight
        const topbarHeight = topbar.getBoundingClientRect().height
        // const tableHeight
        const topBottomMargins = mainCenterTopBottomMargin * 2
        const tableCaptionContainerHeight = tableCaptionContainerRef.current.getBoundingClientRect().height  // table-caption-container
        const newHeight = windowHeight - topbarHeight - topBottomMargins - tableCaptionContainerHeight - 1  // -1 because otherwise appears horizontal scroll bar 
        // console.log(tableContentRef.current.getBoundingClientRect().height);
        if (tableContentRef.current.getBoundingClientRect().height > newHeight) {
            tableContentRef.current.style.height = `${newHeight}px`
        }

    }, [mainCenterTopBottomMargin])
    
    
    // useEffect(() => {
    //     resizeTable() // ALSO RESIZES CELLS
    //     window.addEventListener('resize', resizeTable)
    //     return () => window.removeEventListener('resize', resizeTable)
    // }, [resizeTable])
    

    const handleInputChange = (e, accessor) => {  // SEARCH HANDLER
        setFilter({...filter, [accessor]: e.target.value})
    }

    
    useEffect(() => {  // SETS FOCUS AUTOMATICALLY TO NEEDED INPUT
        (showSearchField !== undefined) && inputRef.current.focus()
    }, [showSearchField])


    useEffect(() => {  // TOGGLES FILTER & SEARCH DIVs 
        const handleInputBlur = event => {
            if (event.target !== inputRef.current && !event.target.classList.contains('filter__icon')) {
                // CLICKED OUTSIDE SEARCH(input) FIELD AND FILTER ICON
                // AS CLICKING ON FILTER ICON TOGGLES POPUP ON ITS OWN
                setShowSearchField(undefined)
            }
        }

        document.addEventListener('mousedown', handleInputBlur)
        return () => document.removeEventListener('mousedown', handleInputBlur)
    }, [])

    // ----------------- DRAG-N-DROP START ---------------------
    
    
    
    // ----------------- CELL CHANGE STOP ---------------------
   

    // ----------------- BUTTON CLICK START ---------------------
    const handleButtonClick = async () => {
        try {
            const url = `${location.pathname}/calendar/`
            const response = await axios.get(url)

            const data = {
                absence_type: {
                    visible: true,
                    editable: true,
                    hint: "",
                    label: "Тип",
                    required: true,
                    type: "select_field",
                    value: '',
                    value_id: '',
                    options: [
                        {value: 'Отпуск', value_id: 'period'}, 
                        {value: 'Больничный', value_id: 'period'}, 
                        {value: 'ОБС', value_id: 'period'}, 
                        {value: 'ОБС по часам', value_id: 'date_and_hour'}, 
                    ],
                },
                period: {
                    visible: false,
                    editable: false,
                    required: true,
                    label: 'В период',
                    type: 'date_range_field',
                    value: [],
                    hint: 'hint',
                },
                date: {
                    visible: false,
                    editable: false,
                    required: true,
                    label: 'Дата',
                    type: 'date_field',
                    value: '',
                    hint: 'hint',
                },
                hour: {
                    visible: false,
                    editable: false,
                    required: true,
                    label: 'Количество часов',
                    type: 'number_field',
                    value: '',
                    hint: 'hint',
                },
            }

            setPopupTasks({
                visible: true,
                data: response.data,
                // data,
                url,
                title: 'Редактирование рабочего календаря',
                callback: refreshData
            })
        } catch (error) {
            console.log(error.message);
            setPopupTasks({
                visible: false,
                data: {},
                url: null,
                title: '',
                callback: () => {}
            })
           
            // renderFeedback('danger', error.response.data.message || 'Error!')
        }
    }
    // ----------------- BUTTON CLICK START ---------------------

    const handleWeeklyCalendarChange = (newStartDate) => {
        // console.log(newStartDate);
        handleDateChange(newStartDate)
        setIsWeeklyCalendarVisible(false)
    }


    // ----------------- RENDER TABLE HEADER START ---------------------
    return (
        <div className="table-for-tasks-head-block">
        {/* <div className="table-caption-container" ref={tableCaptionContainerRef}>
            <button className="svg-container" onClick={() => setShowColumnsList(!showColumnsList)}><Filter className="yellow"/></button>
            
            <span className="table-caption">{table.title}</span>
           
            {buttons && <div className="btns-container">
            {buttons.map((button, index) => {
                return <button key={index} className="button button-2" onClick={() => handleButtonClick(button)}>
                    <span className="svg-container">{buttonIcons[button.icon]}</span> 
                    <span>{button.text}</span>
                </button>
            })}
            </div>}
        </div> */}
        <div className="legend">
            <button onClick={handleButtonClick} className="legend__work-calendar">Изменить рабочий календарь</button>
            <div className=''><SwitchLabels label='Показать время заполнения' onChange={handleSwitchChange}/></div>
            {/* <div className="popup-container background" >
                <div className="popup" onDoubleClick={event => event.stopPropagation()}>
                    fdfd
                </div>
             </div>
            <Calendar
                onChange={handleCalendarChange}
                // value={dateValue}
                locale="ru-Ru"
                // inputRef={calendarRef}
                className="date__react-calendar form-field__bottom"
                selectRange
            /> */}

            <div className="legend__date" style={{width: isMonth ? legendDateWidths['month'] : legendDateWidths['week']}}>
                <button className="legend__date__arrow" onClick={() => handleDateChange(-1)}><Arrow1 className="white"/></button>
                <div className="legend__date__title">
                    <p onClick={() => setIsWeeklyCalendarVisible(!isWeeklyCalendarVisible)}>{table.date.month} {table.date.year}</p>
                    {isWeeklyCalendarVisible && <div className="legend__date__week-calendar">
                        {isMonth 
                            ? <DateMUIMonth value={table.date.start} onChange={handleWeeklyCalendarChange}/>
                            : <DateMUIWeek value={table.date.start} onChange={handleWeeklyCalendarChange}/>
                        }
                    </div>}
                </div>
                <button className="legend__date__arrow" onClick={() => handleDateChange(1)}><Arrow1 style={{transform: 'rotateZ(180deg)'}} className="white"/></button>
            </div>
        </div>

        <div className="table-container" ref={tableContentRef}>
        <table className="table" id={id} ref={tableRef}>
            {/* <thead className="table__thead">
                <tr>
                    {columnAccessors.map(accessor => {
                        if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        const { label, width } = headers[accessor]
                        return (
                            <th key={accessor} className={`table__th ${accessor}`} is_today={(table.date.isToday && accessor === table.date.today).toString()} style={{width: width === 'auto' ? '100%' : '1%'}}>
                                <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                    {/* <div val="" className="filter">
                                        <span 
                                            className={filter[accessor] ? "filter__icon active" : "filter__icon"} 
                                            tabIndex="1" 
                                            onClick={e => {e.stopPropagation(); setShowSearchField(showSearchField === accessor ? undefined : accessor);}}
                                        >
                                            <Search />
                                        </span> 
                                        {showSearchField === accessor && 
                                        <div className="filter__popup">
                                            <input 
                                                type="text"
                                                ref={inputRef}
                                                placeholder="Фильтр"
                                                onChange={e => handleInputChange(e, accessor)}
                                                onClick={e => e.stopPropagation()}
                                                // onBlur={() => setShowSearchField(undefined)}
                                                value={filter[accessor]}
                                            />
                                        </div>
                                        }
                                    </div> */}
                                    {/* <span className="table__th__value" >{label}</span>
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead> */}

            <thead className="table__thead">
                <tr>
                    {columnAccessors.map(accessor => {
                        if (accessor === 'children') {
                            const children = table.headers[accessor]
                            return Object.keys(children).map(accessor => {
                                if (!children[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                
                                const { label, hint, width } = children[accessor]

                                if (label === 'icon') {
                                    let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                    return (
                                        // <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                        <th key={accessor} className={`table__th ${accessor}`} style={{width: '.1%'}} is_today={(table.date.isCurrent && table.date.current.includes(accessor)).toString()} >
                                             <div className={`table__th__center ${accessor}`} style={{width: width || '24px', justifyContent: 'center'}}>
                                                <span className="svg-container"><Icon style={{width: '24px'}} className="white"/></span>
                                                {hint && <HintTable hint={hint}/>}
                                            </div>
                                        </th>
                                    )
                                }

                                return (
                                    // <th key={accessor} className="table__th" style={{width: '1%', minWidth: accessor !== 'inst_status' && width}}>
                                    <th key={accessor} className={`table__th ${accessor}`} style={{width: width === 'auto' ? '100%' : '1%'}} is_today={(table.date.isCurrent && table.date.current.includes(accessor)).toString()} >
                                        <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                            <span className="table__th__value" >{label}</span>
                                            {hint && <HintTable hint={hint}/>}
                                        </div>
                                    </th>
                                )
                            })
                        }
                        
                        if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        
                        const { label, hint, width, normative, frequency } = table.headers[accessor]

                        if (label === 'icon') {
                            let Icon = accessor === 'add' ? tableIcons['tools'] : tableIcons[accessor]
                            
                            return (
                                <th key={accessor} className={`table__th ${accessor}`} style={{width: '.1%'}} is_today={(table.date.isCurrent && table.date.current.includes(accessor)).toString()}>
                                     <div className={`table__th__center ${accessor}`} style={{width: width || '24px', justifyContent: 'center'}}>
                                        <span className="svg-container"><Icon style={{width: '24px'}} className="white"/></span>
                                        {hint && <HintTable hint={hint}/>}
                                    </div>
                                </th>
                            )
                        }
                        return (
                            <th key={accessor} className={`table__th ${accessor}`} style={{width: width === 'auto' ? '100%' : '1%'}} is_today={(table.date.isCurrent && table.date.current.includes(accessor)).toString()}>
                                <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                <div className="table__th__value" >
                                        {label}
                                        {(normative || frequency) && 
                                        <div className="table__th__value__criterias">
                                            {normative && <p>Норматив: <span>{normative} минут</span></p>}
                                            {frequency && <p>Частота: <span>{frequency}</span></p>}
                                        </div>
                                        }
                                    </div>
                                    {hint && <HintTable hint={hint}></HintTable>}
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
        </table>
        </div>
        </div>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}
