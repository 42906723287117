import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import './reports.css'

import { useGlobalContext } from '../../store/context/context'
import { useHistory, useLocation } from 'react-router'
import axios from 'axios'
import { LoaderComponent } from '../../components/layout/loader/Loader'
import { TableWithChildren } from '../../components/layout/table/TableWithChildren'
import { useCancelToken } from '../../utils/js/useCancelToken'
import { useParams } from 'react-router-dom'
import {TableV} from "../../components/layout/table/TableV";

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

export const ReportsDaysPlacement = () => {
    const { setTopbarData, setBreadcrumbs, setActiveSidebarLink, renderFeedback, setShowFeedback, setServerError, setActiveTopbarLink } = useGlobalContext()
    const { newCancelToken, isCancel } = useCancelToken();

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
    }, [])

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const { id, client_id, placement_id } = useParams()


    const history = useHistory()
    const location = useLocation()

    const tzMode = useMemo(() => location.pathname.includes('view') ? 'view' : 'edit', [location.pathname])
    const { activeSidebarLink, breadcrumbs, topbarData } = useMemo(() => {
        let activeSidebarLink = ''
        let breadcrumbs = []
        let topbarData = {}
        const url = location.pathname

        if (location.pathname.startsWith('/users/clients')) {
            // tzType = 'clientsTz'
            activeSidebarLink = 'users/clients'
            breadcrumbs = [
                { path: '/users/clients', breadcrumb: 'Клиенты' },
                { path: `/users/clients/${client_id}/placements`, breadcrumb: `Площадки` }, // i.e. /clients/id/brands/id ==>> /clients/id/brands
                { path: `${location.pathname}`, breadcrumb: `Площадка` },
            ]

            topbarData = {
                links: [
                    {value: 'Блоки', url: `${url.replace('reports/main_days', 'tz/view')}` },
                    // {value: '', url: `/users/placements/${placement_id}/fee`},
                    {value: 'Отчет  по блокам', url: `${url.replace('reports/main_days', 'reports/blocks')}` },
                    {value: 'Отчет по дням', url },
                ],
                sublinks: null
            }
        } else if (location.pathname.startsWith('/users/placements')) {
            // tzType = 'placementsTz'
            activeSidebarLink = 'users/placements'
            breadcrumbs = [
                { path: '/users/placements', breadcrumb: 'Новые площадки' },
                // { path: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`, breadcrumb: `Test Площадки` }, // i.e. /clients/id/brands/id ==>> /clients/id/brands
                { path: `${location.pathname}`, breadcrumb: `Площадка` },
            ]

            topbarData = {
                links: [
                    // {value: 'ТЗ', url: `${url.replace('reports/blocks', 'tz/view')}` },
                    // {value: '', url: `/users/placements/${placement_id}/fee`},
                    // {value: 'Отчет', url },
                ],
                sublinks: null
            }
        } else {
            // tzType = 'usersTz'
            activeSidebarLink = 'users'
            breadcrumbs = [
                { path: '/users', breadcrumb: 'Пользователи' },
                { path: `/users/${id}/clients`, breadcrumb: "Клиенты пользователя" },
                { path: `/users/${id}/clients/${client_id}/placements`, breadcrumb: `Площадки` },
                { path: `${location.pathname}`, breadcrumb: `Площадка` },
              ]

            topbarData = {
                links: [
                    {value: 'Блоки', url: `${url.replace('reports/main_days', 'tz/view')}` },
                    // {value: '', url: `/users/placements/${placement_id}/fee`},
                    {value: 'Отчет по блокам', url: `${url.replace('reports/main_days', 'reports/blocks')}` },
                    {value: 'Отчет по дням', url },

                ],
                sublinks: null
            }
        }

        return { activeSidebarLink, breadcrumbs, topbarData }
    }, [location.pathname, client_id, id])

    // console.log(location);

    // const query = useQuery()
    // console.log(query);

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
        // currency: {
        //     label: 'валюта',
        //     value: 'KZT',
        //     type: 'select_field',
        //     options: ['KZT', 'RUB', 'USD']
        // },
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})
    // const [table, setTable] = useState(tableWithChildren)

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(`tzs/${placement_id}/reports/main_days/`, {
                cancelToken: newCancelToken()
            })
            setTable(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?date_from=${response.data.date_from}&date_to=${response.data.date_to}&currency=${response.data.currency || 'KZT'}`)

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsBlocks');

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, isCancel, newCancelToken, placement_id])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(`tzs/${placement_id}/reports/main_days/` + location.search, {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        name: 'date_from',
                        label: 'от',
                        value: response.data.date_from || '',
                        type: 'date_field'
                    },
                    date_to: {
                        name: 'date_to',
                        label: 'до',
                        value: response.data.date_to || '',
                        type: 'date_field'
                    },
                    currency: {
                        label: 'валюта',
                        value: response.data.currency || 'KZT',
                        type: 'select_field',
                        options: ['KZT', 'RUB', 'USD', 'EUR']
                    },
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsBlocks');

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, setServerError, isCancel, newCancelToken, placement_id])

    // useEffect(() => {
    //     setLoading(false)
    //     setTable(tableWithChildren)
    // }, [])
    useEffect(() => {
        // console.log(location, history.action)
        if (history.action === 'REPLACE') return
        if (!location.search) return getInitialDataAndReplace()
        getInitialData()
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(`tzs/${placement_id}/reports/main_days` + `/?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)
            setTable(response.data)
            history.replace(location.pathname + `?date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}&currency=${dateFields.currency.value}`)

        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink])
    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData(topbarData)
    }, [setTopbarData, topbarData])


    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])

    const buttons = [
        // {
        //     text: 'Скачать',
        //     icon: 'download',
        //     onClick() {
        //         try {
        //             const headersValue = table.order.map(accessor => table.headers[accessor].label)
        //             const rowsValue = table.rows.map(row => table.order.map(accessor => {
        //                 if (row[accessor].type === 'number_field') {
        //                     return String(row[accessor].value).replace('.', ',')
        //                 } else if (row[accessor].type === 'text_field') {
        //                     return (row[accessor].value).trim().replaceAll('\n', '')
        //                 }
        //                     return row[accessor].value
        //                 }
        //             ))

        //             const buildCSV = (headers, data) => {
        //                 if (!headers && !data) return;

        //                 let csvContent = "";

        //                 if (headers) {
        //                   csvContent += headers.join(";") + "\r\n";
        //                 }

        //                 if (data) {
        //                   data.forEach(row => {
        //                     csvContent += row.join(";") + "\r\n";
        //                   });
        //                 }
        //                 return csvContent;
        //             }

        //             function downloadFile() {
        //                 function download(data, fileName) {
        //                     let a = document.createElement("a");
        //                     document.body.appendChild(a);
        //                     a.style = "display: none";
        //                     let blob = new Blob([data], {type: "text/csv"})
        //                     let url = window.URL.createObjectURL(blob);
        //                     a.href = url;
        //                     a.download = fileName;
        //                     a.click();
        //                     window.URL.revokeObjectURL(url);
        //                 };

        //                 let data = buildCSV(headersValue, rowsValue),
        //                 fileName = "Report.csv";

        //                 download(data, fileName);
        //             }

        //             downloadFile()
        //         } catch (error) {
        //             console.error(error);
        //             renderFeedback('danger', error.message || 'Unknown error!')
        //         }
        //     }
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
    }
    // ----------------- ROW CLICK START ---------------------

    if(!didMount) return null;

    if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="reports page">
            </div>

            {loadingOnDateSubmit || loading
                ? <LoaderComponent text={loadingOnDateSubmit ? 'Загрузка...' : 'Загрузка...'}/>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                />
            }
        </>
    )
}
