import React, { useState } from 'react'
import './checkbox_textarea.css'
import {ReactComponent as Search} from '../../../assets/sidebar-icons/search.svg'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useGlobalContext } from '../../../store/context/context'
import { SelectField } from '../select/Select'
import TextField from '../text/Text'
import CheckboxField from '../checkbox/Checkbox'
import TextAreaFieldMU from '../textarea_mu/TextAreaMU'

export const CheckboxTextareaField = ({value='qwert', input_value='input', options=[], onChange, type="text", label, name, hint, parentForm, editable=true, required, rowClickable=false, handleValueRemoveButtonClick}) => {
const op = [
    {value: true, label: 'Gilad Gray', name: 'gilad'},
            {value: false, label: 'Jason Killian', name: 'jason'},
            {value: false, label: 'Antoine Llorca', name: 'antoine'},
            // {value: 'disabled', label: '(Disabled option)', disabled: true},
]
    
    // console.log(value);
    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    const handleFormFieldClick = event => {
        // event.preventDefault()
        rowClickable && event.stopPropagation()
        // console.log('inside', event.target);
    }
 
    const handleCheckboxFieldChange = event => {
        console.log('select field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'options')
    }

    const handleTextAreaFieldChange = event => {
        console.log('textarea field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'input_value')
    }


    return (
        <div className={`checkbox_textarea form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            {/* <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label> */}
            <CheckboxField key={'options'} label={label} name={name} value={value} options={options} editable={editable} required={required} hint={hint} onChange={handleCheckboxFieldChange} />
            <TextAreaFieldMU key={'input_value'} label={label} name={name} value={input_value} options={options} editable={editable} required={required} hint={hint} onChange={handleTextAreaFieldChange} />
        </div>
    )
}