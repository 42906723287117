import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { routeReducer } from './routeReducer'
import axios from 'axios'


// import {ReactComponent as Plus} from '../../../utils/icons/plus.svg'
import {ReactComponent as Plus} from '../../utils/icons/plus.svg'
import {ReactComponent as Pencil} from '../../utils/icons/pencil.svg'
import {ReactComponent as Book} from '../../utils/icons/book.svg'
import {ReactComponent as Parallel1} from '../../utils/icons/parallel_1.svg'
import {ReactComponent as Confirm} from '../../utils/icons/confirm.svg'
import {ReactComponent as Vector} from '../../utils/icons/vector.svg'
import {ReactComponent as Copy} from '../../utils/icons/copy.svg'
import {ReactComponent as Analysis} from '../../utils/icons/Analysis.svg'
import {ReactComponent as Trash1} from '../../utils/icons/trash_1.svg'
import {ReactComponent as Tools} from '../../utils/icons/tools.svg'
import {ReactComponent as Group} from '../../utils/icons/group.svg'
import {ReactComponent as Download} from '../../utils/icons/download.svg'

import {ReactComponent as List1} from '../../utils/icons/list_1.svg'
import {ReactComponent as PlayButton1} from '../../utils/icons/play-button_1.svg'
import {ReactComponent as Wait} from '../../utils/icons/wait.svg'
import {ReactComponent as Pause1} from '../../utils/icons/pause_1.svg'
import {ReactComponent as PlayButton2} from '../../utils/icons/play-button_2.svg'
import {ReactComponent as TZ_add} from '../../utils/icons/tz_add.svg'
import {ReactComponent as Target1} from '../../utils/icons/target_1.svg'
import {ReactComponent as Fill} from '../../utils/icons/fill.svg'
import {ReactComponent as Coin1} from '../../utils/icons/coin_1.svg'
import {ReactComponent as Tick1} from '../../utils/icons/tick_1.svg'


// react-icons
import { MdSend } from 'react-icons/md'
import { TiArrowBackOutline, TiArrowForwardOutline } from 'react-icons/ti'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { FaRegCommentDots } from 'react-icons/fa'
import { RiInboxUnarchiveLine, RiShareForward2Line } from 'react-icons/ri'
import { useMemo } from 'react'

const AppContext = React.createContext()


axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
if (localStorage && localStorage.token) {
    const access_token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Token ${access_token}`
}
axios.defaults.headers.post['Content-Type'] = 'application/json'

const { location } = window

// YOU CAN USE history.go(n) FOR BREADCRUMBS  // history.replace CAN SOLVE THE PROBLEM

const ContextProvider = ({children}) => {
    const updateLastVisitTimeLogic = () => {
        const workingHourStartsAt = 9
        const lastVisitTime = new Date(localStorage.getItem('lastVisitTime'))
        const newStartDate = (new Date()).setHours(workingHourStartsAt, 0, 0, 0)
        const didLastVisitTimePassNineOclock = lastVisitTime.getHours() >= workingHourStartsAt

        if (newStartDate > lastVisitTime && didLastVisitTimePassNineOclock && localStorage.getItem('tbodyDisplayObject') !== null ) {
            const tbodyDisplayObject = JSON.parse(localStorage.getItem('tbodyDisplayObject'))
            Object.keys(tbodyDisplayObject).map(key => tbodyDisplayObject[key] = 'table-row-group')
            localStorage.setItem('tbodyDisplayObject', JSON.stringify(tbodyDisplayObject))
        }
        localStorage.setItem('lastVisitTime', Date())
    }
    updateLastVisitTimeLogic()
//     const lastVisit = getCookie('lastVisitTime');
// const now = Date.now();
// if (lastVisit) {
//    const hoursSinceLastTime = Math.ceil((parseInt(lastVisit) - now) / 3600);
//    alert(`It's been ${hoursSinceLastTime} hour(s) since you last visited us.`);
// }
// setCookie('lastVisitTime', now);

    const SPECIAL_VALUES= {
        DELETE_SELECTED_OPTION: -999,
        NULL_ALIAS_FOR_NUMBER_FIELD: -999999,
        WORKLOAD_TABLE_TITLE_FOR_CHART: 'Загруженность',
    }

    const [buttonIcons] = useState({
        'plus': <Plus />,
        'edit': <Pencil />,
        'book': <Book />,
        'group': <Group />,
        'download': <Download />,
        'parallel': <Parallel1/>,
        'confirm': <Confirm />,
        'vector': <Vector />,
        'copy': <Copy />,
        'analysis': <Analysis />,
        'delete': <Trash1 />,
        'send': <MdSend />,
        'back': <TiArrowBackOutline />,
        'forward': <RiShareForward2Line />,
        'preview': <AiOutlineFileSearch />,
        'activate': <RiInboxUnarchiveLine />,
    })

    const [tableIcons] = useState({
        'open': List1,
        'remove': Trash1,
        'Активен': PlayButton1,
        'Ожидает': Wait,
        'Приостановлен': Pause1,
        'Завершен': PlayButton2,
        'tools': Tools,
        'add': TZ_add,
        'target1': Target1,
        'edit': Pencil,
        'fill': Fill,
        'task_comment': FaRegCommentDots,
        'tz_comment': FaRegCommentDots,
    })

    // const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const [loggedIn, setLoggedIn] = useState(!!localStorage.token)

    const [sidebarOpen, setSidebarOpen] = useState(localStorage.sidebarOpen === 'true')

    const [activeTopbarLink, setActiveTopbarLink] = useState('')
    const [activeTopbarSubLink, setActiveTopbarSubLink] = useState('')

    const [table, setTable] = useState({
        title: 'Title',
        clickable: false,
        buttons: [],
        set_header_url: 'settings',
        headers: {
            column: {
                label: 'Pin code',
                type: 'number',  // SORT
                visible: true,
                width: 200,
                // pinned: true,
            },
            // column2: {
            //     label: 'Phone number',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            children: {
                column3: {
                    label: 'Instrument',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: false,
                },
                column4: {
                    label: 'Alphabet',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: false,
                },
                column5: {
                    label: 'Social network app',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: true,
                },
                // column6: {
                //     label: 'Random',
                //     type: 'string',  // SORT
                //     visible: true,
                //     width: 200,
                //     // pinned: false,
                // },
            },
            // column7: {
            //     label: 'Pin code',
            //     type: 'number',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            // column8: {
            //     label: 'Phone number',
            //     type: 'string',  // SORT
            //     visible: false,
            //     width: 200,
            //     // pinned: true,
            // },
            // column9: {
            //     label: 'Instrument',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
            // column10: {
            //     label: 'Alphabet',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
            // column11: {
            //     label: 'Social network app',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            // column12: {
            //     label: 'Random',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
        },
        rows: [
            {
                column:{
                    value: '1234345.3453',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '123-456-789',
                    type: 'text_field',
                    editable: false,
                    // color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'TikTok',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'abcabc',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'instagram',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'youtube',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'TikTok',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'abcabc',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'instagram',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'youtube',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1234',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '123-456-789',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'TikTok',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'abcabc',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'instagram',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: 'youtube',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
            {
                column:{
                    value: '1112',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '444-111-000',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'FB/IG',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'xyzxyz',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'vk',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: '-',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1112',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '444-111-000',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'FB/IG',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'xyzxyz',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'vk',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: '-',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
            {
                column:{
                    value: '1998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '250-061-998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '250-061-998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'GDN',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'qwerty',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'snapchat',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: 'error',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
        ],
    })


    const mainCenterTopBottomMargin = 16
    // const [routeState, routeDispatch] = useReducer(routeReducer, initialRouteState)

    const [topbarData, setTopbarData] = useState({
        links: [],
        sublinks: []
    })

    const [breadcrumbs, setBreadcrumbs] = useState([])

    const [popup, setPopup] = useState({
        type: 'popup',  // popup OR popup2
        visible: false,
        data: {},
        url: null,
        title: '',
        button: {
            visible: true,
            text: 'Отправить'
        }
    })
    const [popupTasks, setPopupTasks] = useState({
        visible: false,
        data: {},
        url: null,
        title: '',
        button: {
            visible: true,
            text: 'Отправить'
        }
    })
    const [popupLogs, setPopupLogs] = useState({
        type: 'logs', // logs or comments
        visible: false,
        data: [],
        url: null,
        title: '',
        date_from: '',
        date_to: '',
    })
    const [popupFill, setPopupFill] = useState({
        type: '',  // kpi OR billing
        visible: false,
        data: {},
        url: null,
        title: '',
        button: {
            visible: true,
            text: 'Отправить'
        }
    })


    const [LLPs, setLLPs] = useState([])
    const [avrStatuses, setAvrStatuses] = useState([])


    //ACTIVATES FEEDBACK BOX
    const [showFeedback, setShowFeedback] = useState(false)
    const [showFeedback2, setShowFeedback2] = useState(false)
    //FEEDBACK TYPE AND MESSAGE
    const [feedbackInfo, setFeedbackInfo] = useState({type: '', message: 'Feedback message'})
    const [feedbackInfo2, setFeedbackInfo2] = useState({type: '', message: 'Feedback message'})

    const renderFeedback = useCallback((type, message) => {
        setShowFeedback(true)
        setFeedbackInfo({ type, message})
    }, [])
    const [isLoading, setIsLoading] = useState(false)



    // let client = null
    // const fetchMe = async () => {
    //     try {
    //         const response = await axios.get('users/me/')
    //         client = response.data
    //         // setMe(response.data)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    // fetchMe()
    // const me = useMemo(async () => {
    //     try {
    //         const response = await axios.get('users/me/')
    //         return response.data
    //         // setMe(response.data)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [])
    const [me,setMe] = useState(null)

    const [serverError, setServerError] = useState(null)

    const [alert, setAlert] = useState({
        visible: false,
        onSuccessFunction: () => {},
        title: '',
        body: ''
    })

    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [])

    const [activeSidebarLink, setActiveSidebarLink] = useState(sessionStorage.activeSidebarLink === getUrl('root') // CHECK IF THEY MATCH BECAUSE ONE CAN CHANGE THE URL AT THE TOP OF BROWSER
        ? sessionStorage.activeSidebarLink // THEN AS THE WEB PAGE IS NOT CLOSED YET SESSION STORAGE DOES NOT END AND THUS IF THEY MATCH JUST SHOW ANY OF THEM
        : getUrl('root'))  // THERE IS NOTHING IN SESSION STORAGE i.e. ONE JUST COPIED URL FROM ONE PAGE AND PASTED INTO NEW ONE AND THUS SET ROOT URL OF PASTED URL AS ACTIVE SIDEBAR LINK

    // const [activeSidebarLink, setActiveSidebarLink] = useState(sessionStorage.activeSidebarLink || '')
    return (
        <AppContext.Provider value={{
            mainCenterTopBottomMargin,
            topbarData, setTopbarData,
            showFeedback, setShowFeedback,
            feedbackInfo, setFeedbackInfo,
            showFeedback2, setShowFeedback2,
            feedbackInfo2, setFeedbackInfo2,
            table,
            loggedIn, setLoggedIn,
            activeTopbarLink, setActiveTopbarLink,
            breadcrumbs, setBreadcrumbs,
            activeSidebarLink, setActiveSidebarLink,
            popup, setPopup,
            LLPs, setLLPs,
            isLoading, setIsLoading,
            renderFeedback,
            popupLogs, setPopupLogs,
            popupFill, setPopupFill,
            me, setMe,
            serverError, setServerError,
            avrStatuses, setAvrStatuses,
            activeTopbarSubLink, setActiveTopbarSubLink,
            alert, setAlert,
            sidebarOpen, setSidebarOpen,
            popupTasks, setPopupTasks,
            buttonIcons,
            tableIcons,
            SPECIAL_VALUES,
        }}>
            {children}
        </AppContext.Provider>
    )
}

const useGlobalContext = () => useContext(AppContext)

export { ContextProvider, useGlobalContext }



/*
WITH CHILDREN
 const [table, setTable] = useState({
        title: 'Title',
        clickable: false,
        buttons: [],
        set_header_url: 'settings',
        headers: {
            column: {
                label: 'Pin code',
                type: 'number',  // SORT
                visible: true,
                width: 200,
                // pinned: true,
            },
            // column2: {
            //     label: 'Phone number',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            children: {
                column3: {
                    label: 'Instrument',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: false,
                },
                column4: {
                    label: 'Alphabet',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: false,
                },
                column5: {
                    label: 'Social network app',
                    type: 'string',  // SORT
                    visible: true,
                    width: 200,
                    // pinned: true,
                },
                // column6: {
                //     label: 'Random',
                //     type: 'string',  // SORT
                //     visible: true,
                //     width: 200,
                //     // pinned: false,
                // },
            },
            // column7: {
            //     label: 'Pin code',
            //     type: 'number',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            // column8: {
            //     label: 'Phone number',
            //     type: 'string',  // SORT
            //     visible: false,
            //     width: 200,
            //     // pinned: true,
            // },
            // column9: {
            //     label: 'Instrument',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
            // column10: {
            //     label: 'Alphabet',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
            // column11: {
            //     label: 'Social network app',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: true,
            // },
            // column12: {
            //     label: 'Random',
            //     type: 'string',  // SORT
            //     visible: true,
            //     width: 200,
            //     // pinned: false,
            // },
        },
        rows: [
            {
                column:{
                    value: '1234345.3453',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '123-456-789',
                    type: 'text_field',
                    editable: false,
                    // color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'TikTok',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'abcabc',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'instagram',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'youtube',
                            type: 'text_field',
                            editable: false,
                            // color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'TikTok',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'abcabc',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'instagram',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'youtube',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1234',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '123-456-789',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'TikTok',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'abcabc',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'instagram',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: 'youtube',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
            {
                column:{
                    value: '1112',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '444-111-000',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'FB/IG',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'xyzxyz',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'vk',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: '-',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1112',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '444-111-000',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'FB/IG',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'xyzxyz',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'vk',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: '-',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
            {
                column:{
                    value: '1998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column2: {
                    value: '250-061-998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                children: [
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                    {
                        column3:{
                            value: 'GDN',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column4: {
                            value: 'qwerty',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column5:{
                            value: 'snapchat',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                        column6: {
                            value: 'error',
                            type: 'text_field',
                            editable: false,
                            color: '',
                            set_url: '',
                            get_url: ''
                        },
                    },
                ],
                column7:{
                    value: '1998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column8: {
                    value: '250-061-998',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column9:{
                    value: 'GDN',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column10: {
                    value: 'qwerty',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column11:{
                    value: 'snapchat',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
                column12: {
                    value: 'error',
                    type: 'text_field',
                    editable: false,
                    color: '',
                    set_url: '',
                    get_url: ''
                },
            },
        ],
    })
*/



// const [table, setTable] = useState({
//   analysis: [
//     ["KZT", 7336098.7700000005, 8581952.739999998, 1245853.9700000002],
//     ["USD", 0, 0, 0],
//     ["RUB", 0, 0, 0],
//     ["HUF", 0, 0, 0]
//   ],
//   headers: [
//     {value: "Клиент", type: "string"},
//     {value: "БИН", type: "string"},
//     {value: "Договор", type: "string"},
//     {value: "Дата договора", type: "date"},
//     {value: "Валюта договора", type: "string"},
//     {value: "Наше ТОО", type: "string"},
//     {value: "Индексация", type: "string"},
//     {value: "KAM", type: "string"},
//     {value: "PM", type: "string"},
//     {value: "Бренд", type: "string"},
//     {value: "Флайт", type: "string"},
//     {value: "KPI/Billing", type: "string"},
//     {value: "Контекстник", type: "string"},
//     {value: "Система", type: "string"},
//     {value: "Инструмент", type: "string"},
//     {value: "Дата начала", type: "date"},
//     {value: "Дата завершения", type: "date"},
//     {value: "Модель оплаты", type: "string"},
//     {value: "Тип кампании", type: "string"},
//     {value: "Валюта аккаунта", type: "string"},
//     {value: "Расход без НДС и АК (валюта аккаунта)", type: "number"},
//     {value: "Курс валют", type: "number"},
//     {value: "Расход без НДС и АК (валюта договора)", type: "number"},
//     {value: "Цена / АК без НДС [План]", type: "number"},
//     {value: "Цена / АК без НДС [Факт]", type: "number"},
//     {value: "План KPI / Бюджет без НДС и АК", type: "number"},
//     {value: "Факт KPI / Бюджет без НДС и АК", type: "number"},
//     {value: "Бюджет без НДС [План]", type: "number"},
//     {value: "Бюджет без НДС [Факт]", type: "number"},
//     {value: "Бюджет с НДС [Факт]", type: "number"},
//     {value: "ВП", type: "number"},
//   ],
//   rows: [
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//     ["Domina Fortuna (Домина Фортуна) ТОО", "180440037226", "М-66-2019", "15.07.2019", "KZT", "ТОО \"TDS Media\"", "Нет", "Alina Zhelamskaya", "Artur Slachshyov", "Фонбет", "Фонбет Июнь", "KPI", "Nurmukhamed Kuanysh", "Yandex Direct", "Поиск", "01.06.2021", "30.06.2021", "% от бюджета", "Перфоманс", "KZT", 4497661.62, 1, 4497661.62, "10.0%", "2%", 4179870.13, 4497661.620000001, 4597857.14, 4597857.14, 5149600, 100195.52],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Открытие УКФ2", "KPI", "Erkebulan Akhmet", "Facebook", "IG: Lenta + Stories", "20.08.2021", "01.09.2021", "% от бюджета", "Медийка", "USD", 60.15, 435, 26165.25, "20.0%", "0%", 130208, 26165.25, 156249.6, 10, 11.2, -26155.25],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Yandex Direct", "РСЯ", "15.03.2021", "30.05.2021", "CPC", "Медийка", "KZT", 176294.87, 1, 176294.87, "67.0", "78.73", 9162.7, 7798, 613900.6, 613900.6, 687568.67, 437605.73],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "Facebook", "FB/IG: Lenta + Stories", "15.03.2021", "30.05.2021", "CPC", "Медийка", "USD", 5333.02, 431.7082, 2302308.63, "67.0", "82.99", 50255, 40574, 3367085, 3367085, 3771135.2, 1064776.37],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "Erkebulan Akhmet", "MyTarget", "MyTarget Video", "15.03.2021", "30.05.2021", "CPC", "Медийка", "RUB", 52074.84, 5.9274, 308668.4, "67.0", "0.12", 30153, 25619, 2020251, 3000, 3360, -305668.4],
//     ["Magnum Cash&Carry ТОО", "081040004326", "М-13-2021", "01.04.2021", "KZT", "ТОО \"TDS Media\"", "Нет", "Anna Kozhedub", "Ainur Abduvali", "Magnum Cash&Carry", "Шоппинг в Дубай", "KPI", "-", "Доп.услуги", "Баннера и креативы (Maxim)", "-", "-", "-", "-", "RUB", 5000, 5, 25000, "-", "-", "-", "-", "-", 100, 112, -24900],
//   ],
//   year: 2021,
//   month: 9
// }
// )
